import React, { useState, useEffect } from 'react';
import { getSuccessTrackerInfo } from '@helpers/api/tracker';
import TrackSuccess from './Cards/TrackSuccess';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

const PaymentTracking = () => {
  const navigate = useNavigate();
  const [successTrackerInfo, setSuccessTrackerInfo] = useState();
  const fetchSuccessTrackerInfo = async () => {
    try {
      const successTrackerInfoResponse = await getSuccessTrackerInfo();
      const response = successTrackerInfoResponse.data;
      setSuccessTrackerInfo(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSuccessTrackerInfo().catch(console.error);
  }, []);

  useEffect(() => {
    if (successTrackerInfo?.status === 'not_started') {
      navigate('/dashboard/tracker/get-started');
    }
    if (successTrackerInfo?.status === 'needs_approval') {
      navigate('/dashboard/tracker/monthly-goals', {
        state: {
          month: DateTime.now().startOf('month').toJSDate()
        }
      });
    }
  }, [successTrackerInfo]);

  return (
    <div className="tracker-page flex flex-col desktop:px-14 gap-4 mobile:w-screen desktop:w-full tablet:w-full">
      <TrackSuccess />
    </div>
  );
};

export default PaymentTracking;
