import React from 'react';

const NavigationLinkTablet = ({ url, icon, activeMenu }) => {
  return (
    <li className={`sidenav__list-item ${activeMenu === url ? 'active__menu' : ''}`}>
      <i className={icon} />{' '}
    </li>
  );
};

export default NavigationLinkTablet;
