import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../routes/PrivateRoute';
import Dashboard from './Cards/Dashboard';
import NotFound from '@pages/NotFound.jsx';
import Expenses from './Cards/Expenses';
import TrusteePayment from './Cards/TrusteePayment';
import MonthlyGoalSettingFlow from './Shared/MonthlyGoalSettingFlow';
import GetStartedComponent from './GetStartedComponent';
import IncomeGoals from './Cards/IncomeGoals';
import ExpenseItemsByCategory from './Cards/ExpenseItemsByCategory';

const TrackSuccessRoutes = () => {
  return (
    <Routes>
      <Route path="/get-started" element={<GetStartedComponent />} />
      <Route
        path="/track-success"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/expenses"
        element={
          <PrivateRoute>
            <Expenses />
          </PrivateRoute>
        }
      />
      <Route
        path="/expense-item-by-category/:category"
        element={
          <PrivateRoute>
            <ExpenseItemsByCategory />
          </PrivateRoute>
        }
      />
      <Route
        path="/trustee-payment"
        element={
          <PrivateRoute>
            <TrusteePayment />
          </PrivateRoute>
        }
      />
      <Route
        path="/income-goals"
        element={
          <PrivateRoute>
            <IncomeGoals />
          </PrivateRoute>
        }
      />
      <Route
        path="/monthly-goals"
        element={
          <PrivateRoute>
            <MonthlyGoalSettingFlow />
          </PrivateRoute>
        }></Route>
      <Route path="/404" element={<NotFound />} />
    </Routes>
  );
};

export default TrackSuccessRoutes;
