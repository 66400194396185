import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { billPayments as billPaymentsApi } from '@helpers/api';

const AddPaymentModal = ({ setShowModal, biller, searchPayments }) => {
  const [valueAmount, setValueAmount] = useState();
  const [confirm, setConfirm] = useState(false);

  const swalPopUp = withReactContent(Swal);

  const initialModal = () => {
    setConfirm(false);
    setValueAmount('');
    setShowModal(false);
  };

  const handleSubmit = (type) => {
    if (type) {
      billPaymentsApi
        .createBillPayment({
          bill_payment: {
            amount: valueAmount,
            biller_id: biller.id
          }
        })
        .then(async () => {
          initialModal();
          await swalPopUp.fire({
            icon: 'success',
            title: 'Payment created successfully.'
          });
          searchPayments();
        })
        .catch(async () => {
          initialModal();
          await swalPopUp.fire({ icon: 'error', title: 'Bill Payment Not Created' });
        });
    } else {
      initialModal();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center text-black p-5">
      {!confirm && (
        <>
          <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
            <div className="relative z-0 group mt-4">
              <CurrencyFormat
                id="amount"
                value={valueAmount || ''}
                thousandSeparator
                allowNegative={false}
                prefix="$"
                autoComplete="false"
                onValueChange={(values) => {
                  const { value } = values;
                  setValueAmount(value);
                }}
                placeholder="Amount"
                className="border text-black px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray"
              />
              <label
                htmlFor="name"
                className="absolute duration-300 top-3 -z-1 origin-0 text-black left-0">
                Amount
              </label>
            </div>
          </div>
          <div className="p-5 flex justify-center w-full items-center gap-4">
            <button
              onClick={() => setConfirm(true)}
              className={`${
                !valueAmount || valueAmount === 0 ? 'secondary-button' : 'primary-button'
              } font-bold`}
              disabled={!valueAmount || valueAmount === 0}>
              Add
            </button>
            <button className="bg-redDarker text-white font-bold" onClick={() => handleSubmit(false)}>Cancel</button>
          </div>
        </>
      )}
      {confirm && (
        <div className="flex flex-col justify-center gap-4">
          <div className="flex flex-col justify-center gap-4 mb-4">
            <div className="flex justify-start gap-3">
              <p className="w-3/4">
                <strong>Name: </strong>
              </p>
              <span>{biller.biller_name}</span>
            </div>
            <div className="flex justify-start gap-3">
              <p className="w-3/4">
                <strong>Address: </strong>
              </p>
              <span>{biller.biller_address_1}</span>
            </div>
            <div className="flex justify-start gap-3">
              <p className="w-3/4">
                <strong>Account Number: </strong>
              </p>
              <span>{biller.biller_account_number}</span>
            </div>
            <div className="flex justify-start gap-3">
              <p className="w-3/4">
                <strong>Phone: </strong>
              </p>
              <span>{biller.biller_phone}</span>
            </div>
            <div className="flex justify-start gap-3">
              <p className="w-3/4">
                <strong>Amount: </strong>
              </p>
              <span>{valueAmount}</span>
            </div>
            <div className="flex justify-start gap-3">
              <p className="w-3/4">
                <strong>Fee: </strong>
              </p>
              <span>$1.00</span>
            </div>
            <div className="flex justify-start gap-3">
              <p className="w-3/4">
                <strong>Total: </strong>
              </p>
              <CurrencyFormat
                value={Number(valueAmount) + 1}
                className="font-bold"
                allowNegative={false}
                thousandSeparator
                prefix="$"
                displayType="text"
              />
            </div>
          </div>
          <div className="flex justify-center w-full gap-5">
            <button className="bg-greyblue text-white font-bold" onClick={() => setConfirm(false)}>
              Back
            </button>
            <button className="bg-teal text-white font-bold" onClick={() => handleSubmit(true)}>
              Confirm Payment
            </button>
            <button
              className="bg-redDarker text-white font-bold"
              onClick={() => handleSubmit(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPaymentModal;
