import React from 'react';
import { Paper, Typography } from '@mui/material';

const Card = ({ children, title }) => {
  return (
    <Paper elevation={1} sx={{ p: 3 }}>
      <Typography
        variant="h6"
        color="text.primary"
        sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: '1rem', width: '100%' }}>
        {title}
      </Typography>
      <div className="tracker">{children}</div>
    </Paper>
  );
};

export default Card;
