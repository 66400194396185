import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const Body = () => {
  const Br = () => '\n';
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      flexGrow: 1,
      padding: '5px 10px',
      marginTop: '10px'
    },
    information: {
      width: '70%',
      textAlign: 'left'
    },
    information2: {
      width: '30%',
      textAlign: 'right'
    },
    whiteBox: {
      width: '100%',
      backgroundColor: '#FFF',
      height: '40px',
      padding: '10px'
    },
    fontPay: {
      fontFamily: 'Montserrat',
      fontWeight: 'light',
      fontSize: '12px'
    },
    fontPayText: {
      marginTop: '15px',
      fontFamily: 'Montserrat',
      fontWeight: 'light',
      fontSize: '12px'
    },
    fontAmmount: {
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      fontSize: '16px',
      textAlign: 'center'
    }
  });
  return (
    <View style={styles.container}>
      <View style={styles.information}>
        <Text style={styles.fontPay}>
          PAY TO THE
          <Br />
          ORDER OF
        </Text>
        <Text style={styles.fontPay}>
          ----------------------------------------------------------------------
        </Text>
        <Text style={styles.fontPayText}>
          ----------------------------------------------------------- Dollars
        </Text>
      </View>
      <View style={styles.information2}>
        <View style={styles.whiteBox}>
          <Text style={styles.fontAmmount} />
        </View>
      </View>
    </View>
  );
};
export default Body;
