import React from 'react';
import { AUTHORIZED, POSTED, ACH_TRANSACTION } from '@utilities/constants';
import Transaction from './Transaction';
import DateRangeFilterPopover from '../DateRangeFilterPopover/DateRangeFilterPopover';

const TransactionsTable = (props) => {
  const {
    authorizedTransactions,
    settledTransactions,
    pendingAchTransactions,
    incrementPage,
    lastPage,
    selection,
    setSelection,
    clearFilter,
    applyFilter
  } = props;

  return (
    <>
      {pendingAchTransactions.length > 0 && 
        <div id="pending_ach" className="flex flex-col justify-center items-start gap-4">
          <h2 className="text-bannerTitle font-bold">Pending Transfers</h2>
            {pendingAchTransactions.map((pendingAchTransaction, index) => (
              <Transaction
                id={pendingAchTransaction.id}
                key={`pendingAch${index}`}
                amount={pendingAchTransaction.amount}
                timestamp={pendingAchTransaction.timestamp}
                details={'Inbound Transfer'}
                type={ACH_TRANSACTION}
              />
            ))}
        </div>}
      <div id="authorized" className="flex flex-col justify-center items-start gap-4">
        <h2 className="text-bannerTitle font-bold">Pending Transactions</h2>
        {authorizedTransactions.length > 0 &&
          authorizedTransactions.map((authorizedTransaction, index) => (
            <Transaction
              id={authorizedTransaction.id}
              key={`authorized${index}`}
              amount={authorizedTransaction.amount}
              timestamp={authorizedTransaction.timestamp}
              details={authorizedTransaction.merchant_name}
              type={AUTHORIZED}
            />
          ))}
        {authorizedTransactions.length === 0 && (
          <span className="text-center w-full border-b border-greige py-5 text-descriptionSlide font-normal">
            You have no pending transactions
          </span>
        )}
      </div>
      <div id="posted" className="flex flex-col justify-center items-start gap-4">
        <h2 className="text-bannerTitle font-bold">Posted Transactions</h2>
        <div className="w-52 date-range-wrapper">
          <DateRangeFilterPopover
            selection={selection}
            setSelection={setSelection}
            clearFilter={clearFilter}
            applyFilter={applyFilter}
          />
        </div>
        {settledTransactions.length > 0 &&
          settledTransactions.map((settledTransaction, index) => (
            <Transaction
              id={index}
              key={`posted${index}`}
              amount={settledTransaction.amount}
              timestamp={settledTransaction.timestamp}
              details={settledTransaction.details}
              type={POSTED}
            />
          ))}
        {settledTransactions.length === 0 && (
          <span className="text-center w-full border-b border-greige py-5 text-descriptionSlide font-normal">
            You have no posted transactions
          </span>
        )}
      </div>
      <div className="flex justify-center items-center gap-8">
        {!lastPage && (
          <button
            type="button"
            className="text-greyblue text-descriptionSlide font-bold"
            onClick={() => incrementPage()}>
            Load More...
          </button>
        )}
      </div>
    </>
  );
};

export default TransactionsTable;
