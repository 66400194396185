import React from 'react';
import StepperGetStarted from '../Shared/StepperGetStarted';

const GetStarted = ({
  expenseGoals,
  incomeGoals,
  setExpenseGoals,
  setIncomeGoals,
  trusteePayment,
  setTrusteePayment,
  setGetStarted
}) => {
  return (
    <div className="flex justify-center items-center gap-4">
      <div className="flex flex-col gap-4 bg-white p-8 w-4/5">
        <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center">
          Success Tracker
        </h1>
        <StepperGetStarted
          expenseGoals={expenseGoals}
          incomeGoals={incomeGoals}
          setExpenseGoals={setExpenseGoals}
          setIncomeGoals={setIncomeGoals}
          trusteePayment={trusteePayment}
          setTrusteePayment={setTrusteePayment}
          setGetStarted={setGetStarted}
        />
      </div>
    </div>
  );
};

export default GetStarted;
