import React from 'react';
import { Link } from 'react-router-dom';
import SignOutButton from '@components/SignOutButton';

const ButtonsFormMiddleSteps = ({ linkGoBack, isSubmitting, textForward = 'Next >' }) => {
  return (
    <>
      <div className="flex mobile:flex-col justify-center items-center mobile:gap-2 tablet:gap-3 desktop:gap-3">
        <Link to={linkGoBack} className="mobile:hidden tablet:block desktop:block">
          <button
            type="button"
            className="w-full tablet:w-[315px] desktop:w-[244px] bg-seafoam hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7">
            {`<`} Back
          </button>
        </Link>
        <input
          type="submit"
          value={textForward}
          className="w-full tablet:w-[315px] desktop:w-[244px] bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7"
          disabled={isSubmitting}
        />
        <Link to={linkGoBack} className="mobile:block tablet:hidden desktop:hidden">
          <div className="flex justify-center items-center gap-2 text-teal font-bold">
            <i className="fa-solid fa-arrow-left-long" />
            <span>Go Back</span>
          </div>
        </Link>
      </div>
      <SignOutButton />
    </>
  );
};

export default ButtonsFormMiddleSteps;
