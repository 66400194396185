import { useState, useEffect } from 'react';
import { occupations as occupationsApi } from '@helpers/api';

const useOccupations = () => {
  const [loading, setLoading] = useState(false);
  const [occupations, setOccupations] = useState([]);

  useEffect(() => {
    setLoading(true);

    occupationsApi
      .getShow()
      .then((response) => {
        setOccupations(response.data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { loading, occupations, setOccupations };
};

export default useOccupations;
