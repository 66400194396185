import axios from 'axios';

/**
 * _axios wraps the base axios method allowing you to incrementally pass values
 * to build out an HTTP call to an API endpoint.
 *
 * Conceptually, this method should be considered private to the `helpers/api`
 * module. In the ideal scenario, you would have the last call the one made
 * available through helpers.
 */

const _axios =
  (method) =>
  (url) =>
  ({ data, params = {}, options = {} } = {}) => {
    return axios({
      method,
      url,
      data,
      params,
      headers: {
        Authorization: window.localStorage.getItem('Authorization')
      },
      ...options
    });
  };

/**
 * These are the exported HTTP method helpers. Each is just a different call to
 * _axios. At this point these methods accept a `url`, and should still be
 * considered an implementation detail of the `helpers/api` module.
 */
export const get = _axios('get');
export const post = _axios('post');
export const put = _axios('put');
export const remove = _axios('delete');
export const byId = (url) => (id, opts) => get(`${url}/${id}`)({ options: opts });
export const removeById = url => (id, opts) => remove(`${url}/${id}`)({ options: opts });
export const getIndexPerPage = (url) => (id, perPage, startDate, endDate, opts) =>
  get(`${url}?page=${id}&per_page=${perPage}&date_start=${startDate}&date_end=${endDate}`)({
    options: opts
  });
