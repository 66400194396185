import React from 'react';
import Reminder from '../Reminders/Reminder';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    red: {
      main: '#a00a0a'
    }
  }
});

const BillerActions = ({ onClickAddPayment, deleteBiller, biller, getBillers, userFeatures }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-col gap-4 w-full">
        <button
          type="button"
          onClick={() => onClickAddPayment(biller)}
          className="tablet:w-[315px] desktop:w-[244px] mobile:w-[185px] bg-greyblue hover:bg-greyblueHover rounded h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-4">
          Make a Payment
        </button>
        <button
          type="button"
          onClick={() => deleteBiller(biller.id)}
          className="tablet:w-[315px] desktop:w-[244px] mobile:w-[185px] bg-redDarker hover:bg-red rounded h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-4">
          Delete Biller
        </button>
      </div>
      {userFeatures.billerReminder && (
        <div className="flex justify-center items-start">
          <Reminder getBillers={getBillers} reminder={biller.reminder} biller={biller} />
        </div>
      )}
    </ThemeProvider>
  );
};

export default BillerActions;
