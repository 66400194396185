import React from 'react';
import { cards as cardsApi, accounts as accountsApi } from '@helpers/api';
import replacementReasons from '@constants/card';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormTitle from '@components/FormTitle';
import { YourSuccessLogoImg, FundingBackgroundImg } from '@constants/images';

const styles = {
  backgroundImage: `url(${FundingBackgroundImg})`
};

const VerifyTransactionForm = ({ eventAuthDenial, setVerificationResponse, setLoading }) => {
  const swalPopUp = withReactContent(Swal);
  const frozen = eventAuthDenial?.fraud_result === 'FREEZE';

  const fireSwalErrorPopup = () => {
    swalPopUp.fire({
      position: 'center',
      icon: 'error',
      title: 'An error occurred, please try again.',
      showConfirmButton: true
    });
  };

  const unfreezeCard = async () => {
    await cardsApi.unfreeze();
  };

  const disableFraudRule = async () => {
    await accountsApi.disableFraudRule();
  };

  const disableRuleAndUnfreeze = async () => {
    await disableFraudRule().then(async () => {
      if (frozen) {
        await unfreezeCard();
      }
    });
  };

  const confirmTransaction = () => {
    setLoading(true);
    disableRuleAndUnfreeze()
      .then(() => {
        setVerificationResponse('confirmed');
      })
      .catch(() => {
        fireSwalErrorPopup();
      })
      .finally(() => setLoading(false));
  };

  const denyTransaction = () => {
    setLoading(true);
    cardsApi
      .requestReplacement({ reason: replacementReasons.stolen })
      .then(() => {
        setVerificationResponse('denied');
      })
      .catch(() => {
        fireSwalErrorPopup();
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      className="flex flex-col justify-between items-center w-full min-h-screen bg-backgrounWhite pt-10 pb-7 gap-7 px-4"
      style={styles}>
      <div className="max-w-xl w-full shadow-achShadow rounded-3xl px-8 pb-8 pt-10 bg-white">
        <img src={YourSuccessLogoImg} alt="YourSuccess logo" className="w-[227px] h-[77px] mx-auto mb-12" />
        <FormTitle>Did you authorize this transaction?</FormTitle>
        <div className="flex flex-col gap-8">
          <ul className="mx-auto text-link">
            <li>
              <span className="font-bold">Merchant Name:</span> {eventAuthDenial.merchant_name}
            </li>
            <li>
              <span className="font-bold">Amount:</span> {eventAuthDenial.amount}
            </li>
            <li>
              <span className="font-bold">Timestamp:</span> {eventAuthDenial.timestamp}
            </li>
          </ul>
          <div className="flex justify-center items-center gap-4">
            <button
              type="button"
              className="w-20 bg-greyblue hover:bg-greyblueHover rounded-xl h-8 text-descriptionSlide text-white font-bold cursor-pointer"
              id="confirmTransaction"
              onClick={() => confirmTransaction()}>
              Yes
            </button>
            <button
              type="button"
              className="w-20 bg-seafoam hover:bg-teal rounded-xl h-8 text-descriptionSlide text-white font-bold cursor-pointer"
              id="denyTransaction"
              onClick={() => denyTransaction()}>
              No
            </button>
          </div>

          {frozen && (
            <p className="p-5 bg-seafoam text-white rounded-xl text-center">
              <i className="fas fa-triangle-exclamation text-white" /> Your card is frozen right
              now...
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyTransactionForm;
