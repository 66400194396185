import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { PaymentAssistantLearnMoreImg } from '../../constants/images';

export default function PaymentAssistantLearnMore() {
  const navigate = useNavigate();
  return (
    <div className="tracker-page flex flex-col items-center px-4 desktop:px-14 gap-4 w-full">
      <div className="flex flex-col bg-teal rounded-md p-8 gap-8 w-full">
        <h1 className="title-settings text-dashBoardTopName font-bold text-white mb-8">
          Let the YourSuccess Bank Account Help
          <br /> Keep Track Your Important Bills
        </h1>
        <div className="flex flex-col desktop:flex-row items-center gap-8 w-full">
          <div className="flex flex-col gap-4 text-white w-full desktop:w-2/3">
            <p>
              TFS and your Chapter 13 trustee keep track of your plan payments. But what about other
              critical bills?
            </p>
            <p>
              It is your responsibility to make those ongoing payments like mortgage, car, or child
              support?. Consistent and on-time payments, and records to show that, are requirements
              of receiving a discharge. Failure to do both could lead to issues.
            </p>
            <p>
              When you need it, the YourSuccess Payment Assist feature allows you to create a
              payment report that can span years. This report can be shared with your trustee, the
              courts or an aggressive creditor.
            </p>
            <p>
              The YourSuccess Bank Account is designed with the needs of Chapter 13 debtors in mind.
              Ease your journey by taking advantage of payment reminders and reporting developed for
              Chapter 13.
            </p>
          </div>
          <div className="flex justify-center w-full desktop:w-1/3">
            <img
              src={PaymentAssistantLearnMoreImg}
              alt="Payment Assistant Learn More"
              className="rounded-md"
            />
          </div>
        </div>
        <div className="flex flex-col items-center mt-8 gap-8 w-full">
          <div className="flex flex-col desktop:flex-row items-center gap-8 w-full">
            <div className="flex flex-col items-center text-white">
              <i className="fa-light fa-alarm-clock text-3xl mb-2" />
              <p className="text-sm">
                Set up reminders for your critical monthly bills like your house or car.
              </p>
            </div>
            <div className="flex flex-col items-center text-white">
              <i className="fa-light fa-chart-mixed text-3xl mb-2" />
              <p className="text-sm">
                Use thePayment Assist feature to demonstrate to the trustee and the court that you
                are current with these bills.
              </p>
            </div>
            <div className="flex flex-col items-center text-white">
              <i className="fa-light fa-file-lines text-3xl mb-2" />
              <p className="text-sm">
                An easy-to-create report that will demonstrate your consistency in paying bills and
                set you up for success after bankruptcy.
              </p>
            </div>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/dashboard/paybills')}
            className="w-96">
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
}
