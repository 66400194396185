import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const Authorization = ({ payrollOptions, account }) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      flexGrow: 1,
      padding: '5px 10px'
    },
    information: {
      width: '100%',
      textAlign: 'left'
    },
    title: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '18px',
      textAlign: 'center'
    },
    description: {
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      fontSize: '12px',
      marginTop: '8px'
    },
    textRed: {
      color: '#FF8372',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '12px'
    },
    signature: {
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      fontSize: '10px',
      marginTop: '25px',
      textAlign: 'center'
    },
    signatureSign: {
      fontFamily: 'Dancing Script',
      fontWeight: 'bold',
      fontSize: '20px'
    }
  });
  return (
    <View style={styles.container}>
      <View style={styles.information}>
        <Text style={styles.title}>Authorization</Text>
        <Text style={styles.description}>
          Employer is hereby authorized to add a second account and deposit
          <Text style={styles.textRed}>
            {' '}
            {payrollOptions.type == 'amount'
              ? `$${payrollOptions.amount}`
              : `${payrollOptions.percentage}%`}
          </Text>{' '}
          per pay period into the above account and the rest into my existing account. This
          authorization will remain in effect until I modify or cancel it in writing.
        </Text>
        <Text style={styles.signature}>
          Employee Signature:{' '}
          <Text style={styles.signatureSign}>
            {account.first_name} {account.last_name}
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default Authorization;
