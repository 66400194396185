export const AUTHORIZED = Symbol('authorized');
export const POSTED = Symbol('posted');
export const ACH_TRANSACTION = Symbol('ach_transaction');
export const ROUTING_NUMBER = 273070278;
export const POSTAL_BOX_REGEX = /\b(P|p)(ost|ostal)?([ .]*(O|o)(ffice)?)?([ .]*(B|b)ox)?\b/;
export const ZIP_CODE_REGEX = /(^\d{5}$|^\d{5}-\d{4}$)/;

export const INCOME_TYPE = [
  {
    label: 'Salary',
    value: 'salary'
  },
  {
    label: 'Rent',
    value: 'rent'
  },
  {
    label: 'Interest and Dividends',
    value: 'interest_and_dividends'
  },
  {
    label: 'Family Support Payment',
    value: 'family_support_payment'
  },
  {
    label: 'Unemployment Compensation',
    value: 'unemployment_compensation'
  },
  {
    label: 'Social Security',
    value: 'social_security'
  },
  {
    label: 'Government Assistance',
    value: 'government_assistance'
  },
  {
    label: 'Pension or Retirement Income',
    value: 'pension_or_retirement_income'
  },
  {
    label: 'Co-Debtor Income',
    value: 'co_debtor_income'
  },
  {
    label: "Spouse's Income",
    value: "spouses_income"
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export const EXPENSE_TYPE = [
  {
    label: "Housing",
    value: "housing"
  },
  {
    label: "Car Payment",
    value: "car_payment"
  },
  {
    label: "Utilities",
    value: "utilities"
  },
  {
    label: "Food and Housekeeping Supplies",
    value: "food_and_housekeeping_supplies"
  },
  {
    label: "Childcare and Children's Education",
    value: "childcare_and_childrens_education"
  },
  {
    label: "Clothing, Laundry, and Dry Cleaning",
    value: "clothing_laundry_and_dry_cleaning"
  },
  {
    label: "Personal Care Products and Services",
    value: "personal_care_products_and_services"
  },
  {
    label: "Medical and Dental Expenses",
    value: "medical_and_dental_expenses"
  },
  {
    label: "Transportation",
    value: "transportation"
  },
  {
    label: "Entertainment",
    value: "entertainment"
  },
  {
    label: "Charity",
    value: "charity"
  },
  {
    label: "Insurance",
    value: "insurance"
  },
  {
    label: "Taxes",
    value: "taxes"
  },
  {
    label: "Child Support",
    value: "child_support"
  },
  {
    label: "Other",
    value: "other"
  },
  {
    label: "Trustee Payment",
    value: "trustee_payment"
  }
];

export const INCOME_GOALS_INITIAL_VALUES = [
  {
    amount: '',
    category: INCOME_TYPE[0].value,
    effective_date: ''
  },
  {
    amount: '',
    category: INCOME_TYPE[1].value,
    effective_date: ''
  },
  {
    amount: '',
    category: INCOME_TYPE[2].value,
    effective_date: ''
  },
];

export const EXPENSE_GOALS_INITIAL_VALUES = [
  {
    amount: '',
    category: EXPENSE_TYPE[0].value,
    effective_date: ''
  },
  {
    amount: '',
    category: EXPENSE_TYPE[1].value,
    effective_date: ''
  },
  {
    amount: '',
    category: EXPENSE_TYPE[2].value,
    effective_date: ''
  },
];