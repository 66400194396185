import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import BudgetingBuddyComponent from '@components/shared/BudgetingBuddy';

const SetNextMonthsGoalsButton = ({ month = DateTime.now() }) => {
  const currentDate = DateTime.now();
  const nextMonthDate = currentDate.plus({ months: 1 }).startOf('month');
  const nextMonthName = nextMonthDate.toLocaleString({ month: 'long' });
  const daysUntilNextMonth = nextMonthDate.diff(currentDate, 'days').days;
  const currentMonthsName = month.toLocaleString({ month: 'long' });

  return (
    <div className="flex flex-col md:flex-row w-full justify-between items-center mb-16 space-y-4 md:space-y-0">
      {daysUntilNextMonth > 15 ? (
        <span className="text-center md:text-left">{currentMonthsName}'s Expense Tracker</span>
      ) : (
        <Link
          to="/dashboard/tracker/monthly-goals"
          state={{ month: nextMonthDate.toJSDate() }}
          className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 text-center">
          Set {nextMonthName}'s Goals
        </Link>
      )}
      <BudgetingBuddyComponent
          copy="Need a quick tour? Click here to watch the video!"
          videoId="rgeisi0JddI"
          imageWidth={100}
      />
    </div>
  );
};

export default SetNextMonthsGoalsButton;
