import React, { useEffect } from 'react';
import { Player } from '@lordicon/react';

const IconWrapper = ({ icon, size = 96, reference }) => {
  useEffect(() => {
    reference?.current?.playFromBeginning();
  }, [reference]);

  return (
    <Player
      ref={reference}
      size={size}
      icon={icon}
      onComplete={() => reference?.current?.playFromBeginning()}
    />
  );
};

export default IconWrapper;
