import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import Box from '@mui/material/Box';

const Loader = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <BeatLoader color="#3B6483" size={40} />
    </Box>
    // <div className="flex justify-center items-center h-screen">

    // </div>
  );
};

export default Loader;
