import React from 'react';
import { useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { NumericFormat as NumberFormat } from 'react-number-format';
import balanceLow from '@constants/balance';

const Balance = ({ balance, totalSaved = 0, totatSpend = 0 }) => {
  const navigate = useNavigate();

  const textBalance = () => {
    if (balance < 0) return 'is Negative';
    if (balance < balanceLow) return 'is Low';
    return '';
  };

  const handleClick = () => {
    navigate('/dashboard/funding');
  };

  return (
    <div className="balance-card flex flex-col justify-start items-center gap-3">
      <div className="balance bg-greyblue border border-solid border-greyblue flex flex-col p-5 gap-3">
        <div className="relative flex justify-start items-center text-achSubTitle text-white font-bold gap-4">
          <i className="far fa-credit-card-front fa-lg" />
          <span>Your Total Balance {textBalance()}</span>
        </div>
        <div className="relative flex flex-col justify-center items-start desktop:ml-6 gap-3">
          <div
            className={`bg-white ${
              balance < 0 ? 'text-black' : 'text-seafoam'
            } text-dashBoardBalanceAmmount font-normal desktop:w-[300px] tablet:w-[268px] mobile:w-full h-[64px] flex justify-between items-center p-4`}>
            <span>
              <NumberFormat
                value={balance}
                decimalScale="2"
                displayType="text"
                thousandSeparator
                prefix="$"
                fixedDecimalScale
              />
            </span>
            {balance < 0 ? <i className="fa-solid fa-triangle-exclamation text-red" /> : <div />}
          </div>
          {balance < 0 && (
            <div className="text-dashBoardBalanceWarning text-white font-semibold">
              * Negative balances can incur on fees in your account.
            </div>
          )}
          <button
            type="button"
            onClick={() => handleClick()}
            className={`${
              balance < 0 ? 'bg-seafoam' : 'bg-teal'
            } rounded-xl px-3 py-2 text-white font-bold text-link`}>
            Fund your account
          </button>
        </div>
      </div>
      <div className="totals w-full py-5 px-3 hidden">
        <div className="flex desktop:flex-row tablet:flex-col mobile:flex-col desktop:items-center tablet:items-start mobile:items-start justify-start gap-3 m-auto mb-3 px-7">
          <div className="flex justify-start items-center gap-3">
            <i className="fa-light fa-arrow-up-right text-seafoam text-achSubTitle" />{' '}
            <span className="text-darkGray text-achSubTitle">Total Saved</span>
          </div>
          <CurrencyFormat
            value={totalSaved}
            displayType="text"
            thousandSeparator
            prefix="$"
            className="text-dashBoardTopName font-medium"
          />
        </div>
        <hr className="desktop:hidden tablet:block mobile:block" />
        <div className="flex desktop:flex-row tablet:flex-col mobile:flex-col desktop:items-center tablet:items-start mobile:items-start justify-start gap-3 m-auto mb-3 px-7 mt-3">
          <div className="flex justify-start items-center gap-3">
            <i className="fa-light fa-arrow-down-left text-red text-achSubTitle" />{' '}
            <span className="text-darkGray text-achSubTitle">Total Spend</span>
          </div>
          <CurrencyFormat
            value={totatSpend}
            displayType="text"
            thousandSeparator
            prefix="$"
            className="text-dashBoardTopName font-medium"
          />
        </div>
      </div>
    </div>
  );
};

export default Balance;
