import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { SessionContext } from '@contexts';

const SessionRoute = ({ children }) => {
  const { user, sessionLocation } = useContext(SessionContext);

  if (user) {
    return <Navigate to={sessionLocation} />;
  }
  return children;
};

export default SessionRoute;
