/* eslint-disable camelcase */
import React from 'react';
import ReactPaginate from 'react-paginate';
import DateRangeInput from '@components/DateRangeInput';

const Paginate = ({
  controls,
  page,
  setPage,
  setPerPage,
  children,
  perPage,
  setDateRange,
  dateRange,
  minDate
}) => {
  const { count, per_page, current_page } = controls;

  const handleNextPrevButtons = (control) => {
    if (control.isNext) {
      if (page < Math.ceil(count / per_page)) {
        setPage((prev) => prev + 1);
      }
      return false;
    }

    if (control.isPrevious) {
      if (page > 1) {
        setPage((prev) => prev - 1);
      }
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    setPerPage(event.target.value);
    setPage(1);
  };

  return (
    <div className="bg-white rounded-xl py-5 w-full desktop:px-4 tablet:px-4 mobile:px-2">
      <div className="flex mobile:flex-col mobile:gap-2 justify-between mobile:justify-center items-center mb-4">
        <div className="flex flex-col gap-2">
          <label className="text-link font-normal">Result per page</label>
          <select
            value={perPage}
            onChange={(e) => handleChange(e)}
            className="border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block mt-0 focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray selectOptions">
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={-1}>All</option>
          </select>
        </div>

        {dateRange.startDate && dateRange.endDate && (
          <div className="text-center">
            <span className="text-link font-bold">Filter between</span>{' '}
            {dateRange?.startDate?.toLocaleDateString('en-US', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })}{' '}
            <span className="text-link font-bold">and</span>{' '}
            {dateRange?.endDate?.toLocaleDateString('en-US', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })}
          </div>
        )}

        <div className="flex flex-col gap-2 tablet:mt-6 desktop:mt-6">
          <DateRangeInput setDateRange={setDateRange} dateRange={dateRange} minDate={minDate} />
        </div>
      </div>
      {children}
      {per_page > -1 && count > per_page && (
        <div className="flex justify-center items-center">
          <ReactPaginate
            previousLabel="< Previous"
            breakLabel="..."
            nextLabel="Next >"
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={current_page - 1}
            pageCount={Math.ceil(count / per_page)}
            onPageChange={(data) => setPage(data.selected + 1)}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
            onClick={(e) => handleNextPrevButtons(e)}
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </div>
  );
};

export default Paginate;
