import { get, post, put, remove } from './httpBase';

const urlIncomeGoals = '/api/income_goals';
const urlExpenseGoals = '/api/expense_goals';
const urlExpenseItems = '/api/expense_items';
const urlPaymentTracking = '/api/payment_tracking';
const urlSuccessTrackerInfo = '/api/success_tracker_infos';

export const getIndexIncomeGoals = get(urlIncomeGoals);
export const getIndexExpenseGoals = get(urlExpenseGoals);
export const getIndexExpenseItems = get(urlExpenseItems);
export const deleteIncomeGoal = (id) => remove(`${urlIncomeGoals}/${id}`);
export const postCreateUpdateIncomeGoals = post(`${urlIncomeGoals}/create_update_goals`);
export const getUncategorizedExpenseItems = get(`${urlExpenseItems}?uncategorized=true`);
export const postExpenseItem = post(urlExpenseItems);
export const putExpenseItem = (id) => put(`${urlExpenseItems}/${id}`);
export const deleteExpenseItem = (id) => remove(`${urlExpenseItems}/${id}`);
export const postCreateChildren = post(`${urlExpenseItems}/create_childen`);
export const postCreateUpdateExpenseGoals = post(`${urlExpenseGoals}/create_update_goals`);
export const deleteExpenseGoal = (id) => remove(`${urlExpenseGoals}/${id}`);
export const postExpenseGoal = post(urlExpenseGoals);
export const updateExpenseGoal = (id) => put(`${urlExpenseGoals}/${id}`);
export const paymentTracking = get(urlPaymentTracking);
export const paymentTrackingWithMonth = get(urlPaymentTracking);
export const getTotalExpenseGoal = get(urlPaymentTracking + '/total_expense_goal');
export const postSuccessTrackerInfo = post(`${urlSuccessTrackerInfo}`);
export const updateSuccessTrackerInfo = put(`${urlSuccessTrackerInfo}/update`);
export const getSuccessTrackerInfo = get(`${urlSuccessTrackerInfo}`);
