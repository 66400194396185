/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { DateTime } from 'luxon';
import { statements as statementsApi } from '@helpers/api';
import Paginate from '@components/Paginate';
import FormTitle from '@components/FormTitle';
import StatementTable from '../components/StatementTable';

const Statements = () => {
  const [statements, setStatements] = useState([]);
  const [paginateControls, setPaginateControls] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const minDate = DateTime.local().minus({ years: 7 });

  useEffect(() => {
    setLoading(true);
    statementsApi
      .getPerPage(page, perPage, dateRange.startDate, dateRange.endDate)
      .then((response) => {
        const statementsWithName = response.data.statements.map((statement) => ({
          ...statement,
          name: `Ending on ${statement.end_date}`,
          downloadId: `download_${statement.id}`
        }));
        setPaginateControls(response.data);
        setStatements(statementsWithName);
      })
      .catch((error) => {
        setPaginateControls({});
        setStatements({});
        setErrors(error.response.data.errors);
      })
      .finally(() => setLoading(false));
  }, [page, perPage, dateRange]);

  const downloadFile = (fileUrl) => {
    const a = document.createElement('A');
    a.href = fileUrl;
    a.download = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const openStatement = (statement, download = false) => {
    setLoading(true);
    statementsApi
      .getById(statement.id, { responseType: 'blob' })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);

        if (download) {
          downloadFile(fileUrl);
        } else {
          window.open(fileUrl);
        }
      })
      .catch((error) => {
        setErrors(error);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <BeatLoader color="#3B6483" size={40} />
      </div>
    );
  }

  return (
    <div className="mb-8 px-4 desktop:px-12 statements">
          <FormTitle>Statements</FormTitle>
          {!!errors && <h2>{errors}</h2>}
          {!!statements && (
            <Paginate
              controls={paginateControls}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              perPage={perPage}
              setDateRange={setDateRange}
              dateRange={dateRange}
              minDate={minDate}>
              <StatementTable statements={statements} openStatement={openStatement} />
            </Paginate>
          )}
        </div>
  );
};

export default Statements;
