/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CurrencyFormat from 'react-currency-format';
import { billPayments as billPaymentsApi, billers as billersApi } from '@helpers/api';
import AddPaymentModal from './AddPaymentModal';

import DataTable from 'react-data-table-component';
import { BillerInformation } from './Billers/BillerInformation';
import BillerActions from './Billers/BillerActions';
import ModalComponent from '../shared/ModalComponent';
import ReportDateRangeForm from '../BillerReportList/ReportDateRangeForm';

import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Collapse,
  Paper
} from '@mui/material';

import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

const customStyle = {
  headCells: {
    style: {
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'center'
    }
  }
};

const Row = ({ row, onClickAddPayment, deleteBiller, userFeatures, getBillers }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            id={`biller_collapse_${row.id}`}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <p className="text-descriptionSlide font-normal">
            {row.biller_name} - {row.id}
          </p>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <p className="text-descriptionSlide font-normal">{row.biller_account_number}</p>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          sx={{ backgroundColor: '#F5F7F9' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="flex justify-center w-full gap-4 p-8 mobile:py-8 mobile:px-0 mobile:gap-8 mobile:flex-col">
              <BillerInformation biller={row} />
              <BillerActions
                onClickAddPayment={onClickAddPayment}
                deleteBiller={deleteBiller}
                biller={row}
                getBillers={getBillers}
                userFeatures={userFeatures}
              />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const BillerList = ({ userFeatures }) => {
  const [billerList, setbillerList] = useState([]);
  const [billers, setBillers] = useState([]);
  const [pending, setPending] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [addPaymentBiller, setAddPaymentBiller] = useState();

  const swalPopUp = withReactContent(Swal);

  const searchPayments = () => {
    billPaymentsApi
      .getIndex()
      .then((response) => {
        setbillerList(response.data);
      })
      .catch(() => {
        setbillerList([]);
      });
  };

  const getBillers = () => {
    billersApi
      .getIndex()
      .then((response) => {
        if (response.data.length > 0) {
          setBillers(response.data);
        } else {
          setBillers([]);
        }
        setPending(false);
      })
      .catch(() => {
        setBillers([]);
        setPending(false);
      });
  };

  useEffect(() => {
    searchPayments();
    getBillers();
  }, []);

  const onClickAddPayment = (record) => {
    setShowModal(true);
    setAddPaymentBiller(record);
  };

  const columnsBillerPayments = useMemo(() => [
    {
      id: 'biller_name',
      name: 'Merchant Name',
      className: 'biller_name',
      sortable: true,
      align: 'center',
      selector: (row) => row.biller_name
    },
    {
      id: 'description',
      name: 'Description',
      className: 'description',
      sortable: true,
      align: 'center',
      selector: (row) => row.description
    },
    {
      id: 'amount',
      name: 'Amount',
      className: 'amount',
      sortable: true,
      align: 'center',
      cell: (record) => {
        return (
          <CurrencyFormat
            value={record.amount}
            decimalScale={2}
            displayType="text"
            fixedDecimalScale
            thousandSeparator
            prefix="$"
          />
        );
      }
    },
    {
      id: 'date',
      name: 'Date',
      className: 'date',
      align: 'center',
      cell: (record) => {
        return (
          <div className="flex justify-center items-center gap-2 text-greyblue">
            <i className="fas fa-calendar-alt" />{' '}
            <span>{new Date(record.updated_at).toLocaleDateString()}</span>
          </div>
        );
      }
    },
    {
      id: 'status',
      name: 'Status',
      className: 'status',
      sortable: true,
      align: 'center',
      cell: (record) => {
        return (
          (record.status === 'created' && (
            <div className="flex justify-center items-center gap-2 text-greyblue">
              <i className="fas fa-calendar-circle-plus" /> <span>Created</span>
            </div>
          )) ||
          (record.status === 'pending' && (
            <div className="flex justify-center items-center gap-2 text-redDarker">
              <i className="fas fa-timer" /> <span>Pending</span>
            </div>
          )) ||
          (record.status === 'mailed' && (
            <div className="flex justify-center items-center gap-2 text-[#483d8b]">
              <i className="fas fa-inbox-out" /> <span>Mailed</span>
            </div>
          )) ||
          (record.status === 'cleared' && (
            <div className="flex justify-center items-center gap-2 text-teal">
              <i className="fas fa-circle-check" /> <span>Cleared</span>
            </div>
          )) ||
          (record.status === 'failed' && (
            <div className="flex justify-center items-center gap-2 text-redDarker">
              <i className="fas fa-times-circle" /> <span>Failed</span>
            </div>
          )) ||
          (record.status === 'returned' && (
            <div className="flex justify-center items-center gap-2 text-redDarker">
              <i className="fas fa-times-circle" /> <span>Returned</span>
            </div>
          ))
        );
      }
    }
  ]);

  const deleteBiller = (id) => {
    swalPopUp
      .fire({
        title: 'Delete Biller',
        text: 'Are you sure to delete this biller?',
        icon: 'question',
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        allowEscapeKey: false,
        allowOutsideClick: false
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await billersApi.deleteById(id);
          swalPopUp
            .fire({
              title: 'Delete Biller',
              text: 'Biller was successfully removed',
              icon: 'success',
              showConfirmButton: true,
              showCancelButton: false
            })
            .then(() => {
              getBillers();
            });
        }
      });
  };

  return (
    <>
      {userFeatures.trackedBills && (
        <div className="bg-white rounded-xl mb-1 py-5 desktop:px-4 tablet:px-4 mobile:px-2">
          <h2 className="text-bannerTitle font-bold">Reporting</h2>
          <div className="flex flex-col justify-start items-start gap-8">
            <ReportDateRangeForm billers={billers} />
          </div>
        </div>
      )}
      <div className="flex flex-col justify-start items-start gap-8">
        <h2 className="text-bannerTitle font-bold">Your Bills</h2>
        <Link to="/dashboard/paybills/add-biller">
          <button
            type="button"
            className="tablet:w-[315px] desktop:w-[244px] bg-teal hover:bg-seafoam rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-4">
            <i className="fas fa-circle-plus" />
            <span>Add a new bill</span>
          </button>
        </Link>
        <div id="bill-table-billers" className="w-full bill-table-billers">
          {billers.length === 0 && <p className="font-bold text-center">No records found</p>}
          {billers.length > 0 && (
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="center">
                      <h4 className="text-dashBoardName font-bold">Merchant Name</h4>
                    </TableCell>
                    <TableCell align="center">
                      <h4 className="text-dashBoardName font-bold">Account Number</h4>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billers.map((biller, index) => (
                    <Row
                      key={index}
                      row={biller}
                      deleteBiller={deleteBiller}
                      onClickAddPayment={onClickAddPayment}
                      userFeatures={userFeatures}
                      getBillers={getBillers}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <h2 className="text-bannerTitle font-bold">Bill Payment History</h2>
        <div id="bill-table" className="w-full bill-table">
          {billerList.length > 0 && (
            <DataTable
              data={billerList}
              columns={columnsBillerPayments}
              className="w-full table-statement"
              progressPending={pending}
              customStyles={customStyle}
            />
          )}
          {billerList.length === 0 && <p className="font-bold text-center">No records found</p>}
        </div>
        <ModalComponent
          showModal={showModal}
          setShowModal={setShowModal}
          handleComplete={() => {}}
          title={'Add Payment'}
          showFooter={false}>
          <AddPaymentModal
            setShowModal={setShowModal}
            biller={addPaymentBiller}
            searchPayments={searchPayments}
          />
        </ModalComponent>
      </div>
    </>
  );
};

export default BillerList;
