import React from 'react';
import { CreditCard, PersonalInformationForm, AccountFeatures } from '@components/Settings';

const Settings = () => {
  return (
    <main className="acccount-settings">
      <h1 className="title-settings text-dashBoardTopName font-bold text-black">
        Account Settings
      </h1>
      <CreditCard />
      <PersonalInformationForm />
      <div className="balance-alerts" />
      <AccountFeatures />
    </main>
  );
};

export default Settings;
