import { Banner1Img, Banner2Img, Banner3Img } from '@constants/images';

const imagesLogin = [
  {
    id: 1,
    src: Banner1Img,
    alt: '',
    showDisclaimer: true,
    title: 'Earn cash back rewards* for everyday spending',
    description: `<p class="mb-4">With the YourSuccess Bank Account, Provided by Pathward, N.A. you can earn cash back rewards* when you use your debit card at many of the places where you probably already shop.</p>
      `
  },
  {
    id: 2,
    src: Banner2Img,
    alt: '',
    showDisclaimer: true,
    title:
      'Your new fresh start begins with your bank account. Register for the YourSuccess Bank Account, Provided by Pathward, N.A.',
    description: `<ul class="flex flex-col justify-start items-start gap-2">
                    <li class="list-disc">Get early access to funds deposited to your account</li>
                    <li class="list-disc">Earn cash-back rewards* when using your debit card to shop at many well-known merchants</li>
                    <li class="list-disc">Organize and pay your bills with the Billpay feature</li>
                  </ul>
                  `
  },
  {
    id: 3,
    src: Banner3Img,
    alt: '',
    showDisclaimer: false,
    title: 'Paying bills is not fun, but we can help make it easy',
    description:
      'With a YourSuccess Bank Account, Provided by Pathward, N.A., the Billpay feature allows you to organize the bills you owe and makes it easy to pay them.'
  }
];
export default imagesLogin;
