import { post as _post } from './httpBase';

export const freeze = () => {
  const post = _post('/api/account/freeze_card');
  return post();
};

export const unfreeze = () => {
  const post = _post('/api/account/unfreeze_card');
  return post();
};

export const requestReplacement = (data) => {
  const post = _post('/api/account/request_replacement_card');
  return post({ data });
};

export const getCardImageUrl = () => {
  const post = _post('/api/account/get_card_image');
  return post();
};
