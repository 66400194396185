import { get, post as _post, put as _put, removeById as _removeById } from './httpBase';

export const addRppsBiller = ({ biller }) => {
  const post = _post('/api/billers/add_rpps');
  return post({ data: { biller } });
};

export const addPaperBiller = ({ biller }) => {
  const post = _post('/api/billers/add_paper');
  return post({ data: { biller } });
};

export const addReminder = ({ biller_id, next_reminder }) => {
  const put = _post(`/api/reminders`);
  return put({ data: { biller_id: biller_id, next_reminder: next_reminder } }).catch(
    (result) => result
  );
};

export const removeReminder = (id) => {
  const remove = _removeById(`/api/reminders`);
  return remove(id).catch((result) => result);
};

export const deleteById = _removeById("/api/billers");

export const getIndex = get('/api/billers');
