import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const Loader = ({ loading, color, height, width, radius, margin }) => {
  return (
    <ScaleLoader
      color={color}
      loading={loading}
      height={height}
      width={width}
      radius={radius}
      margin={margin}
    />
  );
}

export default Loader;
