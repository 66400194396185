import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { accounts as accountApi, cards as cardsApi } from '@helpers/api';
import CurrencyFormat from 'react-currency-format';
import { swalRequestNewCard } from '@constants/sweetAlert';
import { AccountContext } from '../../contexts';
import SuccessModalRequesCard from './SuccessModalRequesCard';

const RequestNewCard = () => {
  const navigation = useNavigate();
  const [reason, setReason] = useState('default');
  const [approvedFee, setApprovedFee] = useState(false);
  const [feeAmount, setFeeAmount] = useState(0.0);
  const swalPopUp = withReactContent(Swal);
  const { account } = useContext(AccountContext);

  const hasRequestedNewCard = account.current_card?.has_requested_new_card;
  const hasVirtualCard = account.current_card?.virtual;

  const handleRequestCard = () => {
    if (hasVirtualCard) {
      swalPopUp
        .fire({
          position: 'center',
          icon: 'warning',
          title: 'You will not receive a physical card until you fund at least $5 to your account',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Accept'
        })
        .then((result) => {
          if (result.isConfirmed) {
            requestNewCard();
          }});
    } else {
      requestNewCard();
    }
  };

  const requestNewCard = () => {
    cardsApi
      .requestReplacement({ reason })
      .then(() => {
        swalPopUp
          .fire({
            html: <SuccessModalRequesCard reason={reason} />,
            ...swalRequestNewCard
          })
          .then(() => {
            navigation('/dashboard');
          });
      })
      .catch(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'error',
          title: 'There was an error requesting your card',
          showConfirmButton: true,
          confirmButtonText: 'Accept'
        });
      });
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
    setApprovedFee(false);

    accountApi
      .cardReplacementFee({ reason: event.target.value })
      .then(({ data: { waived, fee } }) => {
        if (waived) {
          setFeeAmount(0.0);
        } else {
          setFeeAmount(fee);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const handleFeeChange = (event) => {
    setApprovedFee(event.target.checked);
  };

  const disabledReason = () => {
    return reason === 'default' || reason === '';
  };

  const unapprovedFee = () => {
    return !approvedFee;
  };

  const disabledSubmit = () => {
    return disabledReason() || unapprovedFee();
  };

  const label = (
    <>
      <span>By requesting a new card you accept the fee of management for&nbsp;</span>
      <CurrencyFormat
        value={feeAmount}
        decimalScale={2}
        displayType="text"
        fixedDecimalScale
        thousandSeparator
        prefix="$"
      />
    </>
  );

  return (
    <main className="acccount-settings">
      {hasRequestedNewCard ? (
        <div className="bg-yellow border-yellow-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <i className="fa-solid fa-triangle-exclamation" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Card is cancelled and a replacement has been ordered
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center gap-4">
          <div className="flex flex-col gap-4">
            <h1 className="title-settings text-dashBoardTopName font-bold text-black">
              Request a new card
            </h1>

            <div className="flex flex-col justify-center items-start desktop:w-[560px] mobile:w-full tablet:w-full gap-3 desktop:p-8 tablet:p-8 mobile:p-4 bg-white rounded-xl">
              <h2 className="text-bannerTitle">Why I want a new card?</h2>
              <select
                value={reason}
                onChange={handleReasonChange}
                className="w-full py-3 px-5 bg-whiteLighter border-solid border border-whiteGray rounded-md text-descriptionSlide">
                <option value="default" disabled hidden>
                  Reason for requesting a new card
                </option>
                <option disabled />
                <option value="lost">Lost</option>
                <option value="stolen">Stolen</option>
                <option value="damaged">Damaged</option>
              </select>

              <div className="w-full max-w-lg">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <input
                      id="feeApproval"
                      aria-describedby="feeApproval"
                      type="checkbox"
                      className="w-4 h-4 rounded accent-whiteLighter"
                      onChange={handleFeeChange}
                      checked={approvedFee}
                    />
                    <label
                      htmlFor="feeApproval"
                      className="ml-7 my-4 text-sm font-link text-gray-900 dark:text-gray-300">
                      {label}
                    </label>
                  </div>
                </div>
              </div>

              <button
                type="button"
                disabled={disabledSubmit()}
                onClick={handleRequestCard}
                className="tablet:w-[315px] desktop:w-[500px] bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer">
                Request new card
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default RequestNewCard;
