import React from 'react';
import QRCode from 'react-qr-code';

const MFAModal = ({ user, enableMfa }) => {
  if (enableMfa) {
    return (
      <div className="flex flex-col justify-center items-center gap-4 text-black">
        <h2 className="text-errorMessageModal font-semibold">
          Enable Multi-Factor Authentication to add a second layer of security to your account.
        </h2>
        <p>
          Download an authenticator app from your devices app store to receive one time passcodes
          whenever you login.
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center gap-4 text-black">
      <h2 className="text-errorMessageModal font-semibold">
        Please follow the steps below to start using multi-factor authentication
      </h2>
      <ul className="flex flex-col justify-start items-start gap-2 list-decimal pl-10">
        <li className="text-left">
          Download an authenticator app from your devices app store, if you don&apos;t have one
          installed already
        </li>
        <li className="text-left">
          Open the authenticator app and use it to scan the QR code shown below
        </li>
        <li className="text-left">
          Use the authenticator app whenever you login to receive one time passcodes
        </li>
      </ul>
      <QRCode className="qr-code" value={user.otp_url} />
    </div>
  );
};

export default MFAModal;
