import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { ROUTING_NUMBER } from '@utilities/constants';

const Details = ({ account }) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      flexGrow: 1,
      padding: '5px 10px'
    },
    information: {
      width: '35%',
      marginRight: '10px'
    },
    information2: {
      width: '65%'
    },
    line: {
      width: '100%',
      textAlign: 'left',
      marginBottom: '8px',
      fontFamily: 'Montserrat',
      fontWeight: 'medium',
      fontSize: '14px'
    },
    text: {
      textAlign: 'right',
      marginBottom: '8px',
      fontFamily: 'Montserrat',
      fontWeight: 'medium',
      fontSize: '14px'
    },
    title: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '18px',
      textAlign: 'center'
    }
  });
  return (
    <>
      <Text style={styles.title}>Details</Text>
      <View style={styles.container}>
        <View style={styles.information}>
          <Text style={styles.text}>Bank Name:</Text>
          <Text style={styles.text}>Account Number:</Text>
          <Text style={styles.text}>Routing Number:</Text>
          <Text style={styles.text}>Type of Account:</Text>
        </View>
        <View style={styles.information2}>
          <Text style={styles.line}>Pathward, N.A.</Text>
          <Text style={styles.line}>{account.galileo_payment_reference_number}</Text>
          <Text style={styles.line}>{ROUTING_NUMBER}</Text>
          <Text style={styles.line}>Demand Deposit</Text>
        </View>
      </View>
    </>
  );
};

export default Details;
