import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';

import { getClass } from '@helpers/formik';

const ValidatedInput = ({
  id,
  name,
  placeholder,
  type,
  icon = null,
  errors,
  value = '',
  onChange = () => {
    // empty funciton
  },
  onKeyUp = () => {
    // empty function
  },
  disabled = false,
  withFormik = true,
  classes = '',
}) => {
  const valueUse = value === null ? '' : value;
  const [inputText, setInputText] = useState(value === null ? '' : value);

  const handleChange = (val) => {
    setInputText(val.target.value);
    onChange(val);
  };

  return (
    <div className="relative z-0 w-full mobile:mb-10 mb-7">
      <div className="relative z-0 group">
        {withFormik && (
          <ErrorMessage
            name={name}
            component="div"
            className="mobile:hidden desktop:block tablet:block absolute -top-4 right-0 text-red font-normal text-errorMessageInput"
          />
        )}
        {withFormik ? (
          <Field name={name}>
            {({ field }) => (
              <input
                {...field}
                type={type}
                id={name}
                placeholder={placeholder}
                className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray ${getClass(
                  errors,
                  name
                )} ${classes}`}
                value={inputText}
                onChange={(val) => handleChange(val)}
                onKeyUp={(val) => onKeyUp(val)}
                disabled={disabled}
              />
            )}
          </Field>
        ) : (
          <input
            id={id}
            type={type}
            name={name}
            value={valueUse}
            autoComplete="false"
            onChange={(e) => onChange(e.target.value)}
            onKeyUp={() => onKeyUp()}
            placeholder={placeholder}
            className="border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray"
            required
            disabled={disabled}
          />
        )}
        {icon && (
          <span className="z-10 h-full leading-8 font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 top-0 pr-3 py-3">
            <i className={`far ${icon}`} />
          </span>
        )}

        {type !== 'date' && (
          <label
            htmlFor={withFormik ? name : id}
            className="absolute duration-300 top-3 -z-1 origin-0 text-black left-0">
            {placeholder}
          </label>
        )}

        {withFormik && (
          <ErrorMessage
            name={name}
            component="div"
            className="mobile:block desktop:hidden tablet:hidden absolute top-14 left-0 text-red font-normal text-errorMessageInput"
          />
        )}
      </div>
    </div>
  );
};

export default ValidatedInput;
