import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import { Info } from '@mui/icons-material';
import CustomInput from '../Inputs/CustomInput';
import { postCreateUpdateExpenseGoals } from '../../../helpers/api/tracker';
import { DateTime } from 'luxon';
import BudgetingBuddyComponent from '../../shared/BudgetingBuddy';

const validationSchema = Yup.object({
  amount: Yup.string()
    .test(
      'maxDigitsAfterDecimal',
      'Must be a valid number with a maximum of 2 decimals.',
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .required('This field is required')
});

const TrusteePaymentComponent = ({
  handleNext,
  handleBack,
  trusteePayment,
  setTrusteePayment,
  date = new Date()
}) => {

  const [trusteePaymentState, setTrusteePaymentState] = useState(trusteePayment || {
    amount: '',
    category: 'trustee_payment',
    fixed: true
  });

  const handleSubmit = async (values) => {
    try {
      const response = await postCreateUpdateExpenseGoals({
        data: {
          month: date || DateTime.now().startOf('month').toJSDate(),
          expense_goal: {
            items: [values]
          }
        }
      });

      if (response.status === 200) {
        setTrusteePayment(response.data.trustee_payment);
        setTrusteePaymentState(response.data.trustee_payment);
        handleNext();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <BudgetingBuddyComponent />
      <h3 className="mb-6 text-xl font-semibold">
        Get started by entering your current monthly trustee payment
      </h3>
      <p className="text-sm text-gray-600 mb-4">
        <Info className="inline-block mr-2" />
        Tip: If you do not know the amount you can find this information <br />
        on your chapter 13 plan or by calling your attorney’s office.
      </p>

      <p className="mb-12 text-sm text-gray-600">
        <Info className="inline-block mr-2" />
        Tip: The plan payment is the most critical aspect of the chapter 13 <br />
        process so be sure you use www.tfsbillpay.com to create a payment <br />
        schedule.
      </p>
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        className="container">
        <Formik
          initialValues={trusteePaymentState}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form style={{ width: '100%', maxWidth: '500px' }}>
              <CustomInput
                name="amount"
                placeholder="Trustee Payment Amount"
                icon="fa-dollar-sign"
                title="Enter the amount you are scheduled to pay each month as part of your bankruptcy plan"
                currency
              />
              <Button disabled={isSubmitting} variant="contained" type="submit" sx={{ mr: 1 }}>
                Continue
              </Button>
              <Button onClick={handleBack}>Back</Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default TrusteePaymentComponent;
