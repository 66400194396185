import React, { useState } from 'react';

const CheckBox = ({ id, label, required = false, disabled = false, value = false }) => {
  const [checkBoxInput, setCheckBoxInput] = useState(value === null ? false : value);
  return (
    <div className="flex items-center">
      <input
        id={id}
        aria-describedby={id}
        type="checkbox"
        className="w-4 h-4 rounded accent-whiteLighter"
        required={required ? 'required' : ''}
        disabled={disabled}
        onClick={() => setCheckBoxInput(!checkBoxInput)}
        defaultChecked={checkBoxInput}
      />
      <label htmlFor={id} className="ml-7 my-4 text-sm font-link text-gray-900 dark:text-gray-300">
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
