import React, { useRef } from 'react';
import { WorkingAtDeskImg, GraphImg } from '@constants/images';
import IconWrapper from '@components/IconWrapper/IconWrapper';
import BookmarkIcon from './Icons/bookmark.json';
import ChartIcon from './Icons/chart.json';
import PenIcon from './Icons/pen.json';

const BillTracking = () => {
  const BookmarkIconRef = useRef(null);
  const ChartIconRef = useRef(null);
  const PenIconRef = useRef(null);

  return (
    <div
      className="bg-cover bg-center py-8 px-4 lg:px-16"
      style={{
        backgroundImage: `url(${GraphImg})`
      }}>
      <div className="max-w-6xl mx-auto bg-white bg-opacity-80 rounded-lg shadow-lg p-8">
        <div className="text-center">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">
            Track Your Important Bills
          </h1>
          <p className="text-gray-700 text-lg md:text-xl">
            TFS and your Chapter 13 trustee keep track of your plan payments. But what about other
            critical bills?
          </p>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between mt-8">
          <div className="lg:w-1/2 space-y-6 text-gray-700">
            <p>
              It is your responsibility to make those ongoing payments like mortgage, car, or child
              support. Consistent and on-time payments, and records to show that, are requirements
              of receiving a discharge. Failure to do both could lead to issues.
            </p>
            <p>
              When you need it, the YourSuccess Bank Account bill tracking feature will create a
              payment report that spans years. This report can be shared with your trustee, the
              courts or an aggressive creditor.
            </p>
            <p>
              The YourSuccess Bank Account is designed with the needs of Chapter 13 Debtors in mind.
              Ease your journey by taking advantage of payment reminders and reporting developed for
              Chapter 13.
            </p>
          </div>
          <div className="lg:w-1/2 mt-8 lg:mt-0 flex justify-end">
            <img
              src={WorkingAtDeskImg}
              alt="Photo 1"
              className="rounded-lg shadow-md w-full h-auto max-w-sm"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between mt-12 space-y-6 md:space-y-0 md:space-x-4">
          <div className="flex flex-col items-center text-center">
            <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center mb-4">
              <IconWrapper icon={BookmarkIcon} reference={BookmarkIconRef} />
            </div>
            <p className="text-gray-700 font-semibold">
              Set up reminders for your critical monthly bills like your house or car.
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center mb-4">
              <IconWrapper icon={ChartIcon} reference={ChartIconRef} />
            </div>
            <p className="text-gray-700 font-semibold">
              Use the tracking and reporting feature to demonstrate to the trustee and the court
              that you are current with these bills.
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center mb-4">
              <IconWrapper icon={PenIcon} reference={PenIconRef} />
            </div>
            <p className="text-gray-700 font-semibold">
              An easy-to-create report that will demonstrate your consistency in paying bills and
              set you up for success after bankruptcy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillTracking;
