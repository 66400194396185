import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

const ModalBillerList = ({ config, billers, selectedBillersRef }) => {
  const [selectedBillers, setSelectedBillers] = useState(selectedBillersRef.current);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setSelectedBillers((prev) => [...prev, Number(id)]);
    } else {
      setSelectedBillers((prev) => prev.filter((item) => item != id));
    }
  };

  const columnsBillers = [
    {
      id: 'id',
      name: '',
      cell: (record) => {
        return (
          <div className="flex items-center">
            <input
              id={record.id}
              aria-describedby={record.id}
              type="checkbox"
              value={record.id}
              onClick={handleCheckboxChange}
              defaultChecked={selectedBillers.includes(record.id)}
            />
          </div>
        );
      }
    },
    {
      id: 'biller_name',
      name: 'Merchant Name',
      selector: (row) => row.biller_name
    },
    {
      id: 'biller_account_number',
      name: 'Account Number',
      selector: (row) => row.biller_account_number
    }
  ];

  useEffect(() => {
    selectedBillersRef.current = selectedBillers;
  }, [selectedBillers]);

  return (
    <div className="flex flex-col justify-center items-center gap-2">
      <div className="w-full bill-table-billers">
        <DataTable
          className="w-full table-statement"
          tHeadClassName="table-header"
          data={billers}
          columns={columnsBillers}
        />
      </div>
    </div>
  );
};

export default ModalBillerList;
