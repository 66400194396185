import React from 'react';

const FormTitle = ({ children }) => {
  return (
    <h1 className="mobile:text-titleFormResponsive tablet:text-titleForm desktop:text-titleForm font-bold text-black text-center mb-7">
      {children}
    </h1>
  );
};

export default FormTitle;
