import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { EnrollmentContext } from '@contexts';

const AchSuccess = () => {
  const { enrollment } = useContext(EnrollmentContext);

  const basePath = () => {
    if (enrollment) {
      return '/enrollment-funding';
    }
    return '/dashboard/funding';
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`${basePath()}/ach/transactions-list`);
  };

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col items-center bg-white rounded-2xl px-8 py-24 gap-6">
        <i className="fas fa-circle-check mx-auto text-7xl text-seafoam" />
        <h1 className="text-center text-titleForm font-bold text-black w-2/3">
          Your transaction has been successfully created.
        </h1>
        <p className="text-descriptionSlide font-normal text-black">
          It will be reflected in your account within the next 5 business days after we receive the deposit.
        </p>
        <button
          type="button"
          onClick={() => handleClick()}
          className="tablet:w-[315px] desktop:w-[244px] bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7">
          View all transactions
        </button>
      </div>
    </div>
  );
};

export default AchSuccess;
