import React, { useState, useMemo, useEffect, useContext } from 'react';
import { TableRow, TableCell, Typography, Modal, Box, Paper } from '@mui/material';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { TrackSuccessContext } from '@contexts';
import { TableComponent, getComparator, stableSort } from '../Cards/GenerateTable';
import { DateTime } from 'luxon';
import { EXPENSE_TYPE } from '@utilities/constants';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../Inputs/CustomSelect';
import { postExpenseGoal } from '../../../helpers/api/tracker';
import CustomInput from '../Inputs/CustomInput';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export const validationSchema = Yup.object({
  amount: Yup.string()
    .test(
      'maxDigitsAfterDecimal',
      'The trust payment must be a valid number with a maximum of 2 decimal.',
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .required('This field is required'),
  category: Yup.string().required('This field is required'),
  description: Yup.string().nullable()
});

const TableExpenses = ({ rows, actions, headCells, title, deleteApi, back }) => {
  const { setExpenseGoals } = useContext(TrackSuccessContext);
  const navigate = useNavigate();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const minDate = DateTime.local().minus({ years: 7 });

  const [selected, setSelected] = useState([]);

  const [open, setOpen] = useState(false);

  const [formValues, setFormValues] = useState({
    amount: '',
    category: '',
    id: '',
    description: '',
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const tableComponentParams = {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    headCells,
    rows,
    setDateRange,
    minDate,
    dateRange,
    filter: false,
    title
  };

  const tableRowParams = {
    hover: true,
    role: 'checkbox',
    tabIndex: -1,
    sx: { cursor: 'pointer' }
  };

  const handleDelete = async (id) => {
    const resultSwal = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
    if (resultSwal.isConfirmed) {
      const result = await deleteApi(id)();
      if (result.status === 200) {
        Swal.fire({
          title: 'Deleted!',
          text: 'Your expense goal has been deleted.',
          icon: 'success'
        });
        setExpenseGoals(result.data.expense_goals);
      }
    }
    setSelected([]);
  };

  const handleSpendingBreakDown = (category) => {
    navigate(`/dashboard/tracker/expense-item-by-category/${category}`);
  };

  const hanldeBack = () => {
    navigate(-1);
  };

  const handleFormSubmit = async (values, actions) => {
    const result = await postExpenseGoal({
      data: {
        month: DateTime.now().startOf('month').toJSDate(),
        expense_goal: {
          amount: values.amount,
          category: values.category,
          description: values.description,
        }
      }
    }).catch((error) => {
      return;
    });
    setExpenseGoals(result.data.expense_goals);
    actions.resetForm();
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    setSelected([]);
    setFormValues({
      amount: '',
      category: '',
      id: '',
      description: '',
    });
    handleClose();
  };

  return (
    <>
      {rows && (
        <TableComponent {...tableComponentParams}>
          <>
            {visibleRows.map((row) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  {...tableRowParams}
                  key={row.id}
                  aria-checked={isItemSelected}
                  selected={isItemSelected}>
                  <TableCell />
                  <TableCell align="right">
                    {EXPENSE_TYPE.find((item) => item.value === row.category)?.label}
                  </TableCell>
                  <TableCell align="right">$ {row.amount}</TableCell>
                  {actions && (
                    <TableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: 4
                      }}>
                      {row.category !== 'trustee_payment' && (
                        <Delete onClick={() => handleDelete(row.id)} titleAccess="Delete" />
                      )}
                      <button
                        className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal"
                        type="button"
                        onClick={() => handleSpendingBreakDown(row.category)}>
                        Spending Breakdown
                      </button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {visibleRows.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography color="textSecondary">No data available</Typography>
                </TableCell>
              </TableRow>
            )}
          </>
        </TableComponent>
      )}
      <div className="flex justify-start items-center gap-4 mb-8">
        <button
          className="bg-teal text-white px-4 py-2 rounded hover:bg-seafoam"
          type="button"
          onClick={() => handleOpen()}>
          + Add additional expense goal
        </button>
        {back && (
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
            type="button"
            onClick={() => hanldeBack()}>
            Back
          </button>
        )}
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Paper className="p-8">
            <h2 className="font-bold mb-4">
              {selected.length > 0 ? 'Update Expense Transaction' : 'Additional Expense Transaction'}
            </h2>
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={handleFormSubmit}>
              <Form className="flex flex-col justify-center w-full gap-6 pt-4">
                <div className="flex justify-center items-center gap-4 flex-col">
                  <CustomInput name="amount" placeholder="Amount" icon="fa-dollar-sign" />
                  <CustomSelect
                    name="category"
                    placeholder="Expense Category"
                    options={EXPENSE_TYPE.filter(
                      (item) => item.value !== 'cushion' && item.value !== 'trustee_payment'
                    )}
                  />
                </div>
                <div className="flex justify-center items-center gap-4">
                  <button
                    className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal"
                    type="submit">
                    {selected.length > 0 ? 'Update' : 'Save'}
                  </button>
                  <button
                    className="bg-greige text-white px-4 py-2 rounded hover:bg-greige"
                    type="button"
                    onClick={() => resetForm()}>
                    Cancel
                  </button>
                </div>
              </Form>
            </Formik>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

export default TableExpenses;
