import React, { useCallback, useContext } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { users as usersApi } from '../../helpers/api';
import { SessionContext } from '../../contexts';

const BackupCodesButton = () => {
  const { user, setUser } = useContext(SessionContext);
  const swalPopUp = withReactContent(Swal);

  const backupCodesHtml = (codes) => {
    return (
      <div className="flex flex-col justify-center items-center gap-4">
        <p className="text-link text-black font-medium text-justify">
          Please store these backup codes in a secure location. They can be used as one-time
          passcodes if you every lose access to your authenticator app
        </p>
        <ul className="flex flex-col justify-start items-start gap-2 list-decimal">
          {codes.map((code) => {
            return <li key={code}>{code}</li>;
          })}
        </ul>
      </div>
    );
  };

  const showBackupCodes = useCallback(() => {
    return usersApi
      .generateBackupCodes()
      .then((data) => {
        setUser({
          ...user,
          has_backup_codes: true,
          mfa_active: true
        });
        swalPopUp.fire({
          position: 'center',
          icon: 'success',
          title: 'Backup Codes',
          html: backupCodesHtml(data.data),
          showConfirmButton: true
        });
      })
      .catch(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'error',
          title: 'There was an error generating your backup codes',
          showConfirmButton: true
        });
      });
  }, []);

  return (
    <div>
      {user.mfa_active && (
        <button
          type="button"
          onClick={() => showBackupCodes()}
          className="backup-codes px-4 py-5 bg-teal rounded-full h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-1">
          <i className="far fa-circle-question text-white" />
          <span className="text-white text-link">
            {user.has_backup_codes ? 'Regenerate Backup Codes' : 'Generate Backup Codes'}
          </span>
        </button>
      )}
    </div>
  );
};

export default BackupCodesButton;
