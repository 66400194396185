import React, { useContext, useState } from 'react';
import { TrackSuccessContext } from '@contexts';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DateTime } from 'luxon';

export const MonthSelector = ({ children }) => {
  const { months, setMonth, month } = useContext(TrackSuccessContext);

  const [selectedMonth, setSelectedMonth] = useState(
    month || DateTime.now().startOf('month').toISODate()
  );

  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
    setMonth(event.target.value);
  };

  return (
    <div className="flex mobile:flex-col justify-between items-center desktop:px-14 gap-4 mobile:w-screen desktop:w-full tablet:w-full my-8">
      {children}
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-label">Select Month to Track</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="month-select"
          value={selectedMonth}
          label="Set previous month's goals"
          onChange={handleChange}>
          {months?.map((month, index) => (
            <MenuItem key={index} value={month.month_date}>
              {month.month}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
