import React, { useState, useRef } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { billers as billersApi } from '@helpers/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Reminder = ({ getBillers, biller }) => {
  const [reminder, setReminder] = useState(
    DateTime.fromISO(biller.reminder?.next_reminder || DateTime.now(), { setZone: true })
  );
  const ref = useRef(null);

  const swalPopUp = withReactContent(Swal);

  const datePickerTheme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#0A1307',
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            backgroundColor: '#F5F7F9',
            left: '5px',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
            transform: 'translate(14px, -15px) scale(0.75);'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            fontFamily: 'Montserrat',
            fontWeight: 'normal',
            input: {
              borderRadius: '6px 0 0 6px',
              padding: '0.75rem 1.25rem'
            },
            fieldset: {
              borderWidth: '0px'
            }
          }
        }
      }
    }
  });

  const DatePickerComponent = () => {
    return (
      <ThemeProvider theme={datePickerTheme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            inputRef={ref}
            label="Select a date"
            className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray`}
            format="yyyy-MM-dd"
            openTo="day"
            minDate={DateTime.now()}
            value={reminder || DateTime.now()}
            onChange={(e) => setReminder(e)}
            disablePast
            desktopModeMediaQuery="@media (min-width: 720px)"
            slotProps={{
              textField: { id: 'reminderPicker', onChange: (e) => e.preventDefault() },
              popper: {
                style: {
                  zIndex: 1501
                }
              }
            }}
          />
        </LocalizationProvider>
      </ThemeProvider>
    );
  };

  const onClickReminder = (biller) => {
    swalPopUp
      .fire({
        title: 'Select the date you would like to be reminded',
        html: DatePickerComponent(),
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: true,
        confirmButtonText: 'Confirm',
        preConfirm: () => {
          if (ref.current.value === 'YYYY-MM-DD') {
            swalPopUp.showValidationMessage('Please select a date');
            return false;
          } else {
            swalPopUp.resetValidationMessage();
            return ref.current.value;
          }
        }
      })
      .then(async ({ isConfirmed, value }) => {
        if (isConfirmed) {
          const result = await billersApi.addReminder({
            biller_id: biller.id,
            next_reminder: value
          });
          if (result.code === 'ERR_BAD_REQUEST') {
            swalPopUp.fire({
              title: 'Something went wrong',
              text: result.response.data.error,
              icon: 'error'
            });
          } else {
            getBillers();
            swalPopUp.fire({
              title: 'Reminder Set',
              text: `You will receive a monthly email reminder starting ${value}`,
              icon: 'success'
            });
          }
        }
      });
  };

  const deleteReminder = ({ reminder: { id } }) => {
    swalPopUp
      .fire({
        title: 'Delete Reminder',
        text: 'Are you sure you would like to remove this reminder?',
        icon: 'question',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        showConfirmButton: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const resultApi = await billersApi.removeReminder(id);
          if (resultApi.code === 'ERR_BAD_REQUEST') {
            swalPopUp.fire({
              title: 'Something went wrong',
              text: resultApi.response.data?.error || 'The server responded with an error code',
              icon: 'error'
            });
          } else {
            getBillers();
          }
        }
      });
  };

  return (
    <div className="flex flex-col justify-center items-center gap-4 border border-solid border-greige p-5">
      <button
        type="button"
        onClick={() => onClickReminder(biller)}
        className="mobile:w-[190px] tablet:w-[315px] desktop:w-[244px] bg-teal hover:bg-seafoam rounded h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-4">
        {biller.reminder ? 'Edit Reminder' : 'Set Reminder'}
      </button>
      <div className="flex flex-row justify-evenly items-center gap-10 reminderField">
        <span id={`reminder_date_${biller.id}`}>
          {biller.reminder?.next_reminder
            ? DateTime.fromISO(biller.reminder.next_reminder, { setZone: true }).toFormat(
                'yyyy-LL-dd'
              )
            : 'Not Set'}
        </span>
        {biller.reminder?.next_reminder && (
          <>
            <i
              id={`delete_reminder_${biller.id}`}
              onClick={() => deleteReminder(biller)}
              className="fas fa-circle-xmark fa-2x text-redDarker cursor-pointer"
              title="Delete Reminder"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Reminder;
