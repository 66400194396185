import { ErrorMessage, useField, useFormikContext } from 'formik';
import { getClass } from '@helpers/formik';
import React from 'react';
import CustomCurrencyInput, { handleValueChange } from './CustomCurrencyInput';
const CustomInput = ({ icon, currency = false, ...props }) => {
  const [field] = useField(props);
  const { errors } = useFormikContext();
  return (
    <>
      <div className="relative z-0 w-full max-w-lg mobile:mb-1 mb-2">
        <div className="relative z-0 group">
          <ErrorMessage
            name={field.name}
            component="div"
            className="mobile:hidden desktop:block tablet:block absolute -top-4 right-0 text-red font-normal text-errorMessageInput"
          />
          {icon && (
            <span className="z-10 h-full leading-8 font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 top-0 pr-3 py-3">
              <i className={`far ${icon}`} />
            </span>
          )}
          {!currency && (
            <input
              {...field}
              {...props}
              autoComplete="false"
              className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray ${getClass(
                errors,
                field.name
              )}`}
              title={props.title}
            />
          )}
          {currency && (
            <CustomCurrencyInput
              name={field.name}
              title={props.title}
              errors={errors}
            />
          )}
          <label
            htmlFor={field.name}
            className="absolute duration-300 top-3 -z-1 origin-0 text-black left-0">
            {props.placeholder}
          </label>
          <ErrorMessage
            name={field.name}
            component="div"
            className="mobile:block desktop:hidden tablet:hidden absolute top-14 left-0 text-red font-normal text-errorMessageInput"
          />
        </div>
      </div>
    </>
  );
};

export default CustomInput;
