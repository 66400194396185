/* eslint-disable no-console */
import React, { useState, useContext, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useSearchParams } from 'react-router-dom';

import { users as usersApi } from '@helpers/api';

import MainTemplate from '@components/MainTemplate';
import imagesLogin from '@components/CarouselSlider/Images/imagesLogin.jsx';
import SubmitButton from '@components/SubmitButton';
import { SessionContext } from '@contexts';
import ValidatedInput from '@components/ValidatedInput';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [oneTimePasscode, setOneTimePasscode] = useState('');
  const [promptOtp, setPromptOtp] = useState(false);
  const { setToken } = useContext(SessionContext);
  const searchParams = useSearchParams();
  const timedOut = searchParams[0].get('timedOut');

  useEffect(() => {
    sessionStorage.removeItem('lastTimeStamp');
  }, []);

  const toastConfig = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    usersApi
      .login({
        data: {
          user: {
            email,
            password,
            one_time_passcode: oneTimePasscode
          }
        }
      })
      .then((response) => {
        if (response.data.prompt_otp) {
          setPromptOtp(true);
        } else {
          toast.success('Login success!', toastConfig);
          setToken(response.headers.authorization);
        }
      })
      .catch((error) => {
        if (error.response) {
          const { errors } = error.response.data;
          if (error.response.data.prompt_otp) {
            setPromptOtp(true);
            toast.error('MFA Failed', toastConfig);
          } else {
            toast.error(errors ? errors[0] : error.response.data.error, toastConfig);
          }
        } else if (error.request) {
          toast.error("Can't Login", toastConfig);
          console.error(error.request);
        } else {
          toast.error("Can't Login", toastConfig);
          console.error('Error', error.message);
        }
      });
  };

  const LoginComponent = (
    <>
      <ToastContainer />
      <h1 className="mobile:text-titleFormResponsive text-titleForm font-bold text-black mb-5">
        Welcome
      </h1>
      <p
        className={`max-w-[398px] ${
          timedOut === 'true' ? 'mb-2' : 'desktop:mb-8 xl:mb-11'
        } text-center text-darkGray text-descriptionSlide`}>
        Are you ready for a brighter financial future?
      </p>
      {timedOut === 'true' && (
        <p className="desktop:mb-8 xl:mb-11 text-sm text-red w-[40%] text-center font-semibold">
          If you didn&apos;t sign out intentionally, we signed you out after 10 minutes of
          inactivity to keep your account safe. Thank you for banking with us.
        </p>
      )}
      <form onSubmit={handleSubmit} className="w-full desktop:w-4/5">
        <div className="flex flex-col justify-center items-center">
          {!promptOtp && (
            <>
              <ValidatedInput
                name="email"
                placeholder="Email"
                id="email"
                type="email"
                value={email}
                onChange={setEmail}
                icon="fa-at"
                withFormik={false}
              />
              <ValidatedInput
                name="password"
                placeholder="Password"
                id="password"
                type="password"
                value={password}
                onChange={setPassword}
                icon="fa-lock-keyhole"
                withFormik={false}
              />
            </>
          )}
          {promptOtp && (
            <ValidatedInput
              placeholder="One Time Password"
              id="oneTimePasscode"
              type="text"
              value={oneTimePasscode}
              onChange={setOneTimePasscode}
              icon="fa-lock-keyhole"
              withFormik={false}
            />
          )}
          <div className="w-full max-w-lg xl:mb-4">
            <div className="flex justify-end items-center">
              <Link to="/request-reset-password" className="text-teal text-link font-bold">
                Recover password
              </Link>
            </div>
          </div>
          <SubmitButton value="Login" />
        </div>
      </form>
    </>
  );

  return <MainTemplate body={LoginComponent} images={imagesLogin} />;
};

export default Login;
