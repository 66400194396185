import { SignUpImg } from '@constants/images';

const imagesSignUp = [
  {
    id: 1,
    src: SignUpImg,
    alt: '',
    title: 'Paying bills is not fun, but we can help make it easy',
    description:
      'With a YourSuccess Bank Account, Provided by Pathward, N.A., the Billpay feature allows you to organize the bills you owe and makes it easy to pay them.'
  }
];
export default imagesSignUp;
