import { users as usersApi } from '../api';

const SignOut = (timedOut = false) => {
  usersApi.deleteUser().then(() => {
    if (timedOut) {
      location = `${location.origin}?timedOut=true`;
    } else {
      location.reload();
    }
  });
};

export { SignOut };
