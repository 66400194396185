import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FieldArray } from 'formik';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Inputs/CustomSelect';
import { EXPENSE_TYPE } from '@utilities/constants';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, Button, Alert } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import { postCreateChildren } from '@helpers/api/tracker';
import CurrencyFormat from 'react-currency-format';
import { TrackSuccessContext } from '@contexts';

const validationSchema = Yup.object({
  expense_items: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string()
        .test(
          'maxDigitsAfterDecimal',
          'Monthly amount must be a valid number with a maximum of 2 decimal.',
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        )
        .required('This field is required'),
      category: Yup.string().required('This field is required')
    })
  )
});

const ModalChildrenExpenseItem = ({ props: { open, breakDownItem }, onClose }) => {
  const { month } = useContext(TrackSuccessContext);
  
  const [expenseItemsList, _setExpenseItemsList] = useState({
    expense_items: [
      {
        amount: 0,
        external: false,
        effective_date: breakDownItem?.effective_date,
        description: breakDownItem?.description,
        category: breakDownItem?.category,
        expense_item_id: breakDownItem.id
      },
      {
        amount: 0,
        category: "",
        external: false,
        effective_date: breakDownItem?.effective_date,
        description: breakDownItem?.description,
        expense_item_id: breakDownItem.id
      }
    ]
  });

  const removeFromList = async (i, values, setValues) => {
    const expense_items = [...values.expense_items];
    expense_items.splice(i, 1);
    setValues((prev) => ({ ...prev, expense_items }));
  };

  const updateForm = (values, setValues) => {
    const expense_items = [...values.expense_items];
    expense_items.push({
      amount: '',
      category: '',
      external: true,
      effective_date: breakDownItem?.effective_date,
      description: breakDownItem?.description,
      expense_item_id: breakDownItem.id
    });
    setValues((prev) => ({ ...prev, expense_items }));
  };

  const handleClose = () => {
    onClose();
  };

  const handleFormSubmit = async (values) => {
    try {
      const result = await postCreateChildren({
        data: {
          month: month,
          ...values
        }
      });
      onClose(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog onClose={() => handleClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
        Breakdown Transaction
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={expenseItemsList}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleFormSubmit}>
          {({ values, setValues, isSubmitting }) => (
            <Form>
              <FieldArray name="expense_items">
                {() => {
                  return values.expense_items.map((item, i) => (
                    <div key={i} className="flex justify-center items-center gap-8 w-full pt-4">
                      <CustomInput
                        name={`expense_items.${i}.amount`}
                        placeholder="Amount"
                        icon="fa-dollar-sign"
                        currency
                      />
                      <CustomSelect
                        name={`expense_items.${i}.category`}
                        placeholder="Expense Type"
                        options={EXPENSE_TYPE.filter(
                          (item) => item.value !== 'cushion' && item.value !== 'trustee_payment'
                        )}
                      />
                      <button
                        className="cursor-pointer mb-7"
                        type="button"
                        onClick={() => removeFromList(i, values, setValues)}>
                        <DeleteForever />
                      </button>
                    </div>
                  ));
                }}
              </FieldArray>
              <button
                className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal mb-8"
                type="button"
                onClick={(e) => updateForm(values, setValues)}>
                + Add expense item
              </button>
              <Alert
                variant="filled"
                severity={
                  values.expense_items.reduce(
                    (total, item) => total + parseFloat(item?.amount || 0),
                    0
                  ) < breakDownItem?.amount
                    ? 'info'
                    : values.expense_items.reduce(
                        (total, item) => total + parseFloat(item?.amount || 0),
                        0
                      ) > breakDownItem?.amount
                    ? 'error'
                    : 'success'
                }
                sx={{ m: 2, fontSize: '1rem' }}>
                <span>
                  * If you add multiple expense items, the total amount will be the sum of all the
                  items. The amount that can be broken down is:{' '}
                </span>
                <CurrencyFormat
                  value={breakDownItem?.amount}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                />
                <br />
                <span className="w-full text-center font-bold text-lg">
                  <span>Your total amount is: </span>
                  <CurrencyFormat
                    value={values.expense_items.reduce(
                      (total, item) => total + parseFloat(item?.amount || 0),
                      0
                    )}
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                  />
                </span>
                <br />
              </Alert>
              <div>
                <Button
                  disabled={
                    values.expense_items.length === 0 ||
                    values.expense_items.reduce(
                      (total, item) => total + parseFloat(item?.amount || 0),
                      0
                    ) < breakDownItem?.amount ||
                    values.expense_items.reduce(
                      (total, item) => total + parseFloat(item?.amount || 0),
                      0
                    ) > breakDownItem?.amount ||
                    isSubmitting
                  }
                  variant="contained"
                  type="submit"
                  sx={{ mt: 1, mr: 1 }}>
                  Breakout
                </Button>

                <Button onClick={handleClose} sx={{ mt: 1, mr: 1 }}>
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModalChildrenExpenseItem;
