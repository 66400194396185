import React, { useRef, useState, useEffect } from 'react';
import {
  Banner,
  BillTracking,
  DebitExplain,
  Features,
  CashBack,
  SuccessTracker,
  TopBar
} from '@components/LandingPage';
import Footer from '@components/Footer';

const LandingPage = () => {
  const [isTopBarVisible, setIsTopBarVisible] = useState(false);
  const bannerRef = useRef(null);
  const cashBackRef = useRef(null);
  const billTrackingRef = useRef(null);
  const debitFeaturesRef = useRef(null);
  const debitExplainRef = useRef(null);
  const successTrackerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isMobile = window.innerWidth <= 450;
        if (!isMobile) {
          setIsTopBarVisible(!entry.isIntersecting);
        }
      },
      { root: null, threshold: 0.1 }
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="w-screen min-h-full m-auto flex flex-col">
        <div className="w-full m-auto">
          <div
            className={`fixed top-0 left-0 w-full z-50 bg-white shadow-md transition-transform duration-300 ${
              isTopBarVisible ? 'translate-y-0' : '-translate-y-full'
            }`}
          >
            <TopBar />
          </div>

          <section ref={bannerRef} id="banner">
            <Banner />
          </section>
          
          <section ref={debitExplainRef} id="debit_explain">
            <DebitExplain
              cashBackRef={cashBackRef}
              billTrackingRef={billTrackingRef}
              debitFeaturesRef={debitFeaturesRef}
            />
          </section>

          <section ref={cashBackRef} id="cash_back">
            <CashBack />
          </section>

          <section ref={billTrackingRef} id="bill_tracking">
            <BillTracking />
          </section>

          <section ref={successTrackerRef} id="success_tracker">
            <SuccessTracker />
          </section>

          <section ref={debitFeaturesRef} id="debit_features">
            <Features />
          </section>

          <div className="w-full mb-4 desktop:mb-0 h-full desktop:mr-4 px-6 desktop:px-12 xl:px-44 py-10 desktop:py-8">
            <div className="flex flex-col justify-around items-center xl:gap-8 h-full">
              <div className="desktop:flex h-1/6 m-auto">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
