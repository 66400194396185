import React, { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import OptionsFunding from '@components/OptionsFunding';
import SignOutButton from '@components/SignOutButton';
import FormTitle from '@components/FormTitle';
import { accounts as accountApi } from '@helpers/api';
import { EnrollmentContext } from '@contexts';
import { SessionContext } from '@contexts';

const FundingNav = ({ title, description }) => {
  const { enrollment } = useContext(EnrollmentContext);
  const { user, setUser } = useContext(SessionContext);
  const navigate = useNavigate();
  const swalPopUp = withReactContent(Swal);

  const reactivateOnClick = useCallback((event) => {
    const pathname = event.currentTarget.pathname;
    event.preventDefault();
    if (user.has_closed_account && user.closed_account_reason === 'lack_of_funding') {
      accountApi
        .reactivate()
        .then(() => {
          swalPopUp
            .fire({
              position: 'center',
              icon: 'success',
              title: 'Your account has been reactivated',
              showConfirmButton: true,
              timer: 1500
            })
            .then(() => {
              setUser({
                ...user,
                has_closed_account: false
              });
              navigate(pathname);
            });
        })
        .catch(() => {
          swalPopUp.fire({
            position: 'center',
            icon: 'failure',
            title: 'There was an error reactivating your account',
            showConfirmButton: true,
            timer: 1500
          });
        });
    } else {
      navigate(pathname);
    }
  });

  const body = (
    <>
      <h4 className="font-bold" style={{ marginBottom: '0.7rem' }}>
        Choose funding method
      </h4>
      <ul className="flex flex-col justify-center items-center gap-4 mobile:px-5">
        <OptionsFunding
          description="Direct Deposit"
          link="payroll"
          title="Salary / Payroll"
          id="salary-payroll"
          icon="fa-regular fa-wallet"
          onClick={reactivateOnClick}
        />
        {user.features.plaid && (
          <OptionsFunding
            description="Pull funds from your external bank account"
            link="ach/create-transaction"
            title="Link Your Bank Account"
            id="bank-account"
            icon="fa-regular fa-building-columns"
            onClick={reactivateOnClick}
          />
        )}
        <OptionsFunding
          description="Push funds from your external bank account"
          link="bank-account"
          title="Transfer From Bank Account"
          id="bank-account"
          icon="fa-regular fa-building-columns"
          onClick={reactivateOnClick}
        />
      </ul>
    </>
  );

  const whyFund = (
    <div className="text-md sm:w-full">
      <h2 className="font-bold mb-2 text-center">Why Fund?</h2>
      <ul className="md:text-left list-none">
        <li className="flex items-start mb-2">
          <span className="text-green-500 mr-2">✔</span>
          <span>Stay up to date with your payments with Success Tracker</span>
        </li>
        <li className="flex items-start mb-2">
          <span className="text-green-500 mr-2">✔</span>
          <span>Receive cash back with YourSuccess Rewards</span>
        </li>
        <li className="flex items-start">
          <span className="text-green-500 mr-2">✔</span>
          <span>Pay the trustee up to 60% faster</span>
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <FormTitle>{title}</FormTitle>
      {description ? (
        <p className="text-gray-600">{description}</p>
      ) : (
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <p className="text-center text-lg">
            <span className="font-bold">You're almost there!</span>
            <br />
            The next step is to make a deposit of at least{' '}
            <span className="font-bold text-xl">$5</span>.<br />
            Once complete, we will ship you a physical YourSuccess card.
          </p>
        </div>
      )}
      {enrollment ? (
        <div className="flex flex-col md:flex-row justify-between items-start lg:space-y-4 md:space-y-0 lg:max-w-7xl lg:mx-auto">
          <div className="w-full mb-8">{body}</div>
          <div className="w-full lg:w-1/3 bg-white shadow-md rounded-lg p-4 lg:ml-12 lg:-mr-80 lg:self-start">
            {whyFund}
          </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row justify-between items-start lg:space-y-4 md:space-y-0 lg:max-w-7xl lg:mx-auto">
          <div className="w-full mb-8">{body}</div>
        </div>
      )}
      {enrollment && <SignOutButton isFunding />}
    </>
  );
};

export default FundingNav;
