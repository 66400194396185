import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { ROUTING_NUMBER } from '@utilities/constants';

const Footer = ({ accountNumber }) => {
  const Br = () => '\n';
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      flexGrow: 1,
      padding: '5px 10px',
      marginTop: '10px'
    },
    information: {
      width: '50%',
      textAlign: 'left'
    },
    information2: {
      width: '50%',
      textAlign: 'right'
    },
    fontFor: {
      fontFamily: 'Montserrat',
      fontWeight: 'light',
      fontSize: '12px'
    },
    line: {
      fontFamily: 'Montserrat',
      fontWeight: 'light',
      fontSize: '12px'
    },
    numbers: {
      fontFamily: 'Montserrat',
      fontWeight: 'medium',
      fontSize: '22px'
    }
  });
  return (
    <View style={styles.container}>
      <View style={styles.information}>
        <Text style={styles.fontFor}>FOR</Text>
        <Text style={styles.line}>____________________________________</Text>
        <Text style={styles.numbers}>{ROUTING_NUMBER}</Text>
      </View>
      <View style={styles.information2}>
        <Text style={styles.fontFor}>
          <Br />
        </Text>
        <Text style={styles.line}>____________________________________</Text>
        <Text style={styles.numbers}>{accountNumber}</Text>
      </View>
    </View>
  );
};

export default Footer;
