import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { eventAuthDenials as eventAuthDenialsApi } from '@helpers/api';
import Loader from '@components/Loader';
import VerifyTransactionForm from '@components/VerifyTransactionForm';

const FraudAlerts = () => {
  const [eventAuthDenial, setEventAuthDenial] = useState(null);
  const [verificationResponse, setVerificationResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const { authDenialId } = useParams();

  useEffect(() => {
    eventAuthDenialsApi
      .getById(authDenialId)
      .then((response) => {
        setEventAuthDenial(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error({ error });
      })
      .finally(() => setLoading(false));
  }, [authDenialId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {verificationResponse === 'confirmed' && (
        <div id="tryTransactionAgainMessage">
          Your card has been unblocked. Please try the transaction again.
        </div>
      )}
      {verificationResponse === 'denied' && (
        <div id="deniedTransactionMessage">
          Your card has been deactivated. Your new card will arrive soon!
        </div>
      )}
      {!verificationResponse && (
        <VerifyTransactionForm
          eventAuthDenial={eventAuthDenial}
          setVerificationResponse={setVerificationResponse}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default FraudAlerts;
