import React from 'react';

const ErrorsTemplate = (props) => {
  const { errors } = props;

  const errorsList = Object.keys(errors).map((error) => (
    <li key={error} className="font-bold text-errorMessageModal text-white">
      <div className="flex justify-center items-center gap-4">
        <div className="w-[29px] h-[29px] bg-red flex items-center justify-around p-3 rounded-full box-border">
          <i className="fa-solid fa-xmark" />
        </div>
        <span className="text-left">
          {error.split('_').join(' ').charAt(0).toUpperCase() + error.split('_').join(' ').slice(1)}
          : {errors[error]}
        </span>
      </div>
    </li>
  ));

  return (
    <div className="bg-greyblue rounded-xl">
      <div className="px-5 py-6 flex flex-col justify-center items-start gap-4">
        <ul className="flex justify-center flex-col items-center gap-4">{errorsList}</ul>
        <span className="text-errorDisclaimerModal font-normal text-white">
          Review the information and try again.
        </span>
      </div>
    </div>
  );
};

export default ErrorsTemplate;
