import React, { useState } from 'react';
import { Box, Chip, Paper, Typography } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';
import ModalVideo from 'react-modal-video';

const VideoModal = ({ copy, videoId = null }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Paper elevation={3} style={{ marginLeft: 20, padding: 20 }}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="subtitle1" textAlign="center">
            {copy}
          </Typography>
          <Box width={150} textAlign="center">
            <Chip icon={<PlayArrow />} label="Watch Demo" onClick={() => setOpen(true)} />
          </Box>
        </Box>
      </Paper>
      <ModalVideo
        channel="youtube"
        youtube={{ autoplay: 0 }}
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default VideoModal;
