import React, { useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ROUTING_NUMBER } from '@utilities/constants';
import { AccountContext } from '../../contexts';
import { cards as cardsApi } from '../../helpers/api';
import { useCardImage } from '../../hooks';
import { RequestSupportBtn } from '../RequestSupportBtn';

const ResetPinButton = ({ onClick, loading }) => {
  return (
    <button
      type="button"
      disabled={loading}
      onClick={onClick}
      className="px-4 py-5 bg-seafoam hover:bg-seafoam/80 rounded-full h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-1">
      <span className="text-white text-link">Reset Pin</span>
    </button>
  );
};

const FreezeButton = ({ onClick, loading }) => {
  return (
    <button
      type="button"
      disabled={loading}
      onClick={onClick}
      className="px-4 py-5 bg-redDarker hover:bg-red rounded-full h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-1">
      <i className="fas fa-lock-keyhole text-white" />
      <span className="text-white text-link font-bold">Freeze Card</span>
    </button>
  );
};

const UnfreezeButton = ({ onClick, loading }) => {
  return (
    <button
      type="button"
      disabled={loading}
      onClick={onClick}
      className="px-4 py-5 bg-seafoam hover:bg-seafoam/80 rounded-full h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-1">
      <i className="fas fa-lock-keyhole-open text-white" />
      <span className="text-white text-link">Unfreeze Card</span>
    </button>
  );
};

const useFreezeCard = () => {
  const [loading, setLoading] = useState(false);
  const swalPopUp = withReactContent(Swal);

  const freezeCard = useCallback(() => {
    return cardsApi
      .freeze()
      .then(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'success',
          title: 'Your card has been frozen',
          showConfirmButton: false,
          timer: 1500
        });
      })
      .catch(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'error',
          title: 'There was an error freezing your card',
          showConfirmButton: false,
          timer: 1500
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const unfreezeCard = useCallback(() => {
    return cardsApi
      .unfreeze()
      .then(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'success',
          title: 'Your card has been unfrozen',
          showConfirmButton: false,
          timer: 1500
        });
      })
      .catch(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'error',
          title: 'There was an error unfreezing your card',
          showConfirmButton: false,
          timer: 1500
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    loading,
    freezeCard,
    unfreezeCard
  };
};

const CreditCard = () => {
  const { account } = useContext(AccountContext);
  const navigate = useNavigate();
  const [frozenCard, setFrozenCard] = useState(account.current_card?.frozen);
  const { loading, freezeCard, unfreezeCard } = useFreezeCard();
  const { url: cardImgUrl } = account.show_virtual_card
    ? useCardImage()
    : { url: { cardImgUrl: '' } };

  const [hideAccNumber, setHideAccNumber] = useState(true);
  const onFreeze = () => freezeCard().then(() => setFrozenCard(true));
  const onUnFreeze = () => unfreezeCard().then(() => setFrozenCard(false));

  const currentCard = account.current_card || {};
  const hasRequestedNewCard = currentCard.has_requested_new_card;
  const pinPreviouslySet = currentCard.pin_set_at;
  const swalPopUp = withReactContent(Swal);

  const onResetPinClick = useCallback(
    () => {
      swalPopUp
        .fire({
          title: 'Reset PIN',
          html: `
            <div class="flex flex-col justify-start items-start gap-4">
              <p>Are you sure you would like to reset your PIN?</p>
            </div>
            `,
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes'
        })
        .then((result) => {
          if (result.isConfirmed) {
            navigate('/dashboard/set-pin');
          }
        });
    },
    [navigate]
  );

  return (
    <div className="tfs-card">
      <div className="bg-white rounded-3xl p-5">
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h2 className="text-bannerTitle font-bold text-black">YourSuccess Card</h2>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            {hasRequestedNewCard ? (
              <div className="bg-yellow border-yellow-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <i className="fa-solid fa-triangle-exclamation" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      Card is cancelled and a replacement has been ordered
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <Link
                to="/dashboard/settings/request-new-card"
                className="align-baseline px-4 bg-greyblue hover:bg-greyblueHover rounded-full h-10 desktop:text-descriptionSlide tablet:text-descriptionSlide mobile:text-link text-white font-bold cursor-pointer flex justify-center items-center">
                Request New Card
              </Link>
            )}
          </div>
        </div>
        <div className="flex mobile:flex-col justify-start items-center mt-4 desktop:gap-14 tablet:gap-8">
          {account.show_virtual_card && cardImgUrl && (
            <img
              src={cardImgUrl}
              alt="credit card"
              className="desktop:w-[391px] tablet:w-[310px]"
            />
          )}
          <div className="flex flex-col gap-4">
            <div className="flex flex-col justify-start items-start">
              <div className="flex justify-center items-center gap-2 bg-seafoam/30 rounded-full w-[93px] h-[30px] text-seafoam mb-1">
                <i className="fas fa-circle fa-2xs" />
                <span className="text-link font-bold">Active</span>
              </div>
              <div className="mb-2 text-cardNames font-bold text-black">
                {account.first_name} {account.last_name}
              </div>
              <div className="text-darkGray text-link">
                <label htmlFor="reference_number" aria-label="Account Number">
                  Account Number
                </label>
                <span className="ml-4">
                  <input
                    type={hideAccNumber ? 'password' : 'text'}
                    className="p-1 w-36"
                    value={account.galileo_payment_reference_number}
                    disabled
                    id="reference_number"
                  />
                </span>
                <button
                  type="button"
                  onClick={() => setHideAccNumber(!hideAccNumber)}
                  className="w-14 inline-flex text-center px-2.5 py-1.5 text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 ml-4">
                  {hideAccNumber ? 'Show' : 'Hide'}
                </button>
              </div>
              <div className="text-darkGray text-link">
                Routing Number: <span className="ml-4">{ROUTING_NUMBER}</span>
              </div>
            </div>
            <div className="flex tablet:flex-col justify-center desktop:items-center tablet:items-start gap-1">
              {pinPreviouslySet && (<ResetPinButton onClick={() => onResetPinClick()} loading={loading} />)}
              {frozenCard ? (
                <UnfreezeButton onClick={onUnFreeze} loading={loading} />
              ) : (
                <FreezeButton onClick={onFreeze} loading={loading} />
              )}
              <RequestSupportBtn />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
