import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import RegionSelect from '@components/RegionSelect';
import * as Yup from 'yup';
import { billers as billersApi } from '@helpers/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ValidatedInput from '../ValidatedInput/ValidatedInput';
import CheckPreview from './CheckPreview';

const requiredMessage = 'Required';
const maxErrorMessage = 'Too long';
const minErrorMessage = 'Needs more characters';
const matchesErrorMessage = 'Invalid';

const alphaRegex = /^[A-Za-z\s\-.]*$/;
const alphaWithExtrasRegex = /^[A-Za-z\s\-']*$/;
const alphaNumericRegex = /^[A-Za-z\s\d#\-.]*$/;
const postalCodeRegex = /(^\d{5}$|^\d{5}-\d{4}$)/;

const validationSchema = Yup.object({
  biller_address_1: Yup.string()
    .matches(alphaNumericRegex, matchesErrorMessage)
    .min(4, minErrorMessage)
    .max(80, maxErrorMessage)
    .nullable(),
  biller_address_2: Yup.string().matches(alphaNumericRegex, matchesErrorMessage).max(30).nullable(),
  biller_city: Yup.string()
    .matches(alphaRegex, matchesErrorMessage)
    .max(30, maxErrorMessage)
    .required(requiredMessage)
    .nullable(),
  biller_state: Yup.string().length(2).required(requiredMessage).nullable(),
  biller_zip: Yup.string()
    .matches(postalCodeRegex, matchesErrorMessage)
    .required(requiredMessage)
    .nullable(),
  biller_account_number: Yup.string().required(requiredMessage),
  biller_name: Yup.string()
    .max(50, maxErrorMessage)
    .matches(alphaWithExtrasRegex, matchesErrorMessage)
    .required(requiredMessage),
  biller_phone: Yup.string().length(10, 'Please enter a complete phone number').nullable()
});

const PaperBillerForm = () => {
  const swalPopUp = withReactContent(Swal);
  const navigate = useNavigate();

  const formValues = {
    biller_name: '',
    biller_city: '',
    biller_state: '',
    biller_zip: '',
    biller_account_number: '',
    biller_address_1: '',
    biller_address_2: '',
    biller_phone: ''
  };

  const handleBillerSubmit = (values) => {
    billersApi
      .addPaperBiller({ biller: values })
      .then(() => {
        navigate('/dashboard/paybills');
      })
      .catch((error) => {
        swalPopUp.fire({
          icon: 'error',
          title: 'Unable to Add a new bill.'
        });
      });
  };

  useEffect(() => {
    swalPopUp.fire({
      title: 'Billpay Paper Check',
      html: `
        <div class="flex flex-col gap-4">
        <p class="text-left">In order to ensure that the Paper Check you are creating reaches the intended recipient,
        please be sure to correctly enter the information requested in the following fields.</p>
        <p class="text-left">If you are sending a check to a payee or merchant that has issued an invoice,
        please use the billing information from that invoice to provide the requested information.</p>
        </div>`
    });
  }, []);

  return (
    <div className="flex flex-col justify-center gap-2">
      <div className="personal-information w-full">
        <div className="bg-white rounded-3xl p-5 flex flex-col gap-4">
          <h2 className="text-bannerTitle font-bold text-black mb-6">Add Paper Biller</h2>
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={handleBillerSubmit}>
            {({ errors, values, handleChange, isSubmitting }) => (
              <>
                <Form className="flex flex-col justify-center w-full gap-6 pt-4">
                  <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:gap-8 tablet:gap-8 mobile:gap-4 w-full justify-center items-center">
                    <ValidatedInput
                      type="text"
                      name="biller_name"
                      placeholder="Biller name"
                      errors={errors}
                      value={values.biller_name}
                      onChange={handleChange}
                    />
                    <ValidatedInput
                      type="text"
                      name="biller_account_number"
                      placeholder="Biller Account Number"
                      errors={errors}
                      value={values.biller_account_number}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:gap-8 tablet:gap-8 mobile:gap-4 w-full justify-center items-center">
                    <ValidatedInput
                      type="text"
                      name="biller_city"
                      placeholder="Biller city"
                      errors={errors}
                      value={values.biller_city}
                      onChange={handleChange}
                    />
                    <RegionSelect
                      name="biller_state"
                      value={values.biller_state}
                      label="Biller State"
                      onChange={handleChange}
                      errors={errors}
                    />
                    <ValidatedInput
                      type="text"
                      name="biller_zip"
                      placeholder="Biller Zip"
                      errors={errors}
                      value={values.biller_zip}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:gap-8 tablet:gap-8 mobile:gap-4 w-full justify-center items-center">
                    <ValidatedInput
                      type="text"
                      name="biller_address_1"
                      placeholder="Biller Address 1"
                      errors={errors}
                      value={values.biller_address_1}
                      onChange={handleChange}
                    />
                    <ValidatedInput
                      type="text"
                      name="biller_address_2"
                      placeholder="Biller Address 2"
                      errors={errors}
                      value={values.biller_address_2}
                      onChange={handleChange}
                    />
                    <ValidatedInput
                      type="text"
                      name="biller_phone"
                      placeholder="Biller Phone"
                      errors={errors}
                      value={values.biller_phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex mobile:flex-col justify-center items-center gap-4">
                    <button
                      type="submit"
                      className="tablet:w-[315px] desktop:w-[315px] bg-greyblue hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer"
                      disabled={isSubmitting || Object.keys(errors).length}>
                      Add Paper Biller
                    </button>
                    <Link
                      to="/dashboard/paybills/"
                      className="w-[315px] bg-seafoam hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-4">
                      Return to bills and payments
                    </Link>
                  </div>
                </Form>
                <CheckPreview />
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PaperBillerForm;
