import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ValidatedInput from '@components/ValidatedInput';
import CheckBoxForm from '@components/CheckBoxForm';
import RegionSelect from '@components/RegionSelect';
import ButtonsFormMiddleSteps from '@components/ButtonsFormMiddleSteps';
import FormTitle from './FormTitle';
import { POSTAL_BOX_REGEX, ZIP_CODE_REGEX } from '../../utilities/constants';

const ShippingAddressAttributes = ['address_1', 'address_2', 'city', 'state', 'postal_code'];

const primaryAddress = Yup.object().shape({
  address_1: Yup.string()
    .required('Required')
    .test(
      'PO BOX validation',
      'Address PO Box is not allowed',
      (value) => !POSTAL_BOX_REGEX.test(value)
    )
    .nullable(),
  address_2: Yup.string().nullable(),
  city: Yup.string().required('Required').nullable(),
  state: Yup.string().required('Required').nullable(),
  postal_code: Yup.string()
    .matches(ZIP_CODE_REGEX, 'Zip Code Invalid')
    .required('Required')
    .nullable()
});

const shippingAddress = Yup.object().shape({
  shipping_address_different: Yup.boolean(),
  address_1: Yup.string().when('shipping_address_different', {
    is: (val) => val == true,
    then: (schema) => schema.required('Required'),
    otherwise: (schema) => schema.notRequired()
  }),
  address_2: Yup.string().nullable(),
  city: Yup.string().when('shipping_address_different', {
    is: (val) => val == true,
    then: (schema) => schema.required('Required'),
    otherwise: (schema) => schema.notRequired()
  }),
  state: Yup.string().when('shipping_address_different', {
    is: (val) => val == true,
    then: (schema) => schema.required('Required'),
    otherwise: (schema) => schema.notRequired()
  }),
  postal_code: Yup.string().when('shipping_address_different', {
    is: (val) => val == true,
    then: (schema) => schema.matches(ZIP_CODE_REGEX, 'Zip Code Invalid').required('Required'),
    otherwise: (schema) => schema.notRequired()
  })
});

const validationSchema = Yup.object().shape({
  address_attributes: primaryAddress,
  shipping_address_attributes: shippingAddress
});

const AddressForm = ({ accountApplication, handleSubmit, setCurrentStep }) => {
  useEffect(() => {
    setCurrentStep('address');
  }, []);

  return (
    <>
      <FormTitle>Address</FormTitle>

      <Formik
        initialValues={accountApplication}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          handleSubmit(values, 'Identification');
        }}>
        {({ isSubmitting, errors, values, handleChange, setFieldValue }) => (
          <Form className="flex flex-col items-center justify-center w-full">
            <ValidatedInput
              type="text"
              name="address_attributes.address_1"
              placeholder="Address"
              errors={errors}
              value={values.address_attributes.address_1}
              onChange={handleChange}
            />
            <ValidatedInput
              type="text"
              name="address_attributes.address_2"
              placeholder="Address Line 2"
              errors={errors}
              value={values.address_attributes.address_2}
              onChange={handleChange}
            />
            <ValidatedInput
              type="text"
              name="address_attributes.city"
              placeholder="City"
              errors={errors}
              value={values.address_attributes.city}
              onChange={handleChange}
            />

            <RegionSelect
              name="address_attributes.state"
              value={values.address_attributes.state}
              label="Select State"
              onChange={handleChange}
              errors={errors}
            />

            <ValidatedInput
              type="text"
              name="address_attributes.postal_code"
              placeholder="Zip Code"
              errors={errors}
              value={values.address_attributes.postal_code}
              onChange={handleChange}
            />

            <CheckBoxForm
              name="shipping_address_attributes.shipping_address_different"
              label="Shipping address is different from primary address"
              errors={errors}
              value={values.shipping_address_different}
              onChange={(e) => {
                setFieldValue(
                  'shipping_address_attributes.shipping_address_different',
                  e.target.checked
                );

                ShippingAddressAttributes.forEach((attribute) =>
                  setFieldValue(`shipping_address_attributes.${attribute}`, null)
                );
              }}
            />

            {values.shipping_address_attributes.shipping_address_different === true && (
              <>
                <ValidatedInput
                  type="text"
                  name="shipping_address_attributes.address_1"
                  placeholder="Shipping Address"
                  errors={errors}
                  value={values.shipping_address_attributes.address_1}
                  onChange={handleChange}
                />
                <ValidatedInput
                  type="text"
                  name="shipping_address_attributes.address_2"
                  placeholder="Shipping Address Line 2"
                  errors={errors}
                  value={values.shipping_address_attributes.address_2}
                  onChange={handleChange}
                />
                <ValidatedInput
                  type="text"
                  name="shipping_address_attributes.city"
                  placeholder="Shipping Address City"
                  errors={errors}
                  value={values.shipping_address_attributes.city}
                  onChange={handleChange}
                />
                <RegionSelect
                  name="shipping_address_attributes.state"
                  value={values.shipping_address_attributes.state}
                  label="Select Shipping Address State"
                  onChange={handleChange}
                  errors={errors}
                />
                <ValidatedInput
                  type="text"
                  name="shipping_address_attributes.postal_code"
                  placeholder="Shipping Address Zip Code"
                  errors={errors}
                  value={values.shipping_address_attributes.postal_code}
                  onChange={handleChange}
                />
              </>
            )}

            <ButtonsFormMiddleSteps
              linkGoBack="/account-application/personal-information"
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddressForm;
