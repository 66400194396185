import { getIn } from 'formik';

const getClass = (errors, fieldName) => {
  if (getIn(errors, fieldName)) {
    return 'invalid';
  }
  return 'valid';
};

export default getClass;
