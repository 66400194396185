import { get, post } from './httpBase';

const url = '/api/account';

export const getShow = get(url);

export const cardReplacementFee = ({ reason }) => {
  const request = post('/api/account/card_replacement_fee');
  return request({ data: { reason } });
};

export const updatePersonalInfo = (data) => {
  const request = post('/api/account/update_personal_info');
  return request({ data });
};

export const disableFraudRule = post('/api/account/disable_fraud_rule');

export const enableEarlyAch = () => {
  const request = post('/api/account/enable_early_ach');
  return request();
};

export const disableEarlyAch = () => {
  const request = post('/api/account/disable_early_ach');
  return request();
};

export const reactivate = () => {
  const request = post('/api/account/reactivate');
  return request();
};
