import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AccountApplication from '@pages/AccountApplication/index.jsx';

import { SessionContext } from '@contexts';

const AccountApplicationRoute = ({ ...rest }) => {
  const { user } = useContext(SessionContext);

  if (user) {
    if (user.has_approved_account_application) {
      return (
        <Navigate
          to={{
            pathname: user.redirectPath || '/dashboard'
          }}
        />
      );
    }
    return <AccountApplication {...rest} />;
  }
  return (
    <Navigate
      to={{
        pathname: '/'
      }}
    />
  );
};
export default AccountApplicationRoute;
