import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { paymentTracking } from '@helpers/api/tracker';
import WelcomeComponent from '../GetStartedSteps/WelcomeComponent';
import TrusteePaymentComponent from '../GetStartedSteps/TrusteePaymentComponent';
import IncomeGoalsComponent from '../GetStartedSteps/IncomeGoalsComponent';
import ExpenseGoalsComponent from '../GetStartedSteps/ExpenseGoalsComponent';
import ReviewComponent from '../GetStartedSteps/ReviewComponent';
import ProgressBar from '../../ProgressBar/ProgressBar';
import steps from '@constants/trackerApplication';

const MonthlyGoalSettingFlow = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const month = location.state?.month;

  if (!location.state?.month) {
    navigation('/dashboard/tracker/track-success');
  }

  const [currentStep, setCurrentStep] = useState('Welcome');
  const [activeStep, setActiveStep] = useState(1);
  const [expenseGoals, setExpenseGoals] = useState([]);
  const [incomeGoals, setIncomeGoals] = useState([]);
  const [trusteePayment, setTrusteePayment] = useState({});
  const [getStarted, setGetStarted] = useState(true);

  const monthJsDate = month;

  const fetchDataMonthlyFlow = async () => {
    const result = await paymentTracking({
      params: {
        month: month,
        prefil: true
      }
    }).catch((err) => {
      console.error(err);
    });
    setIncomeGoals(result.data.income_goals);
    setTrusteePayment(result.data.trustee_payment);
    setExpenseGoals(result.data.expense_goals);
  };

  useEffect(() => {
    if (month) {
      fetchDataMonthlyFlow().catch((error) => {
        console.error(error);
      });
    }
  }, [month]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCurrentStep(steps.find(({ id }) => id === activeStep + 1)?.step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCurrentStep(steps.find(({ id }) => id === activeStep - 1)?.step);
  };
  useEffect(() => {
    if (!getStarted) {
      navigation('/dashboard/tracker/track-success');
    }
  }, [getStarted]);

  return (
    <div className="flex flex-col bg-white p-5 items-center overflow-auto innerContainer desktop:w-full mobile:w-full tablet:w-full">
      <ProgressBar currentStep={currentStep} steps={steps} />
      {activeStep === 1 && <WelcomeComponent handleNext={handleNext} isMonthly={true} />}
      {activeStep === 2 && (
        <TrusteePaymentComponent
          handleNext={handleNext}
          handleBack={handleBack}
          trusteePayment={trusteePayment}
          setTrusteePayment={setTrusteePayment}
          setExpenseGoals={setExpenseGoals}
          date={month}
        />
      )}
      {activeStep === 3 && (
        <IncomeGoalsComponent
          handleNext={handleNext}
          incomeGoals={incomeGoals}
          handleBack={handleBack}
          setIncomeGoals={setIncomeGoals}
          headingText="Enter any income you expect to receive consistently each month"
          trusteePayment={trusteePayment}
          date={monthJsDate}
        />
      )}
      {activeStep === 4 && (
        <ExpenseGoalsComponent
          expenseGoals={expenseGoals}
          handleNext={handleNext}
          handleBack={handleBack}
          setExpenseGoals={setExpenseGoals}
          headerText="Enter each expense you have this month, but may change in future months"
          trusteePayment={trusteePayment}
          incomeGoals={incomeGoals}
          date={monthJsDate}
        />
      )}
      {activeStep === 5 && (
        <ReviewComponent
          expenseGoals={expenseGoals}
          incomeGoals={incomeGoals}
          handleBack={handleBack}
          headingText={'Please review your information'}
          setGetStarted={setGetStarted}
          trusteePayment={trusteePayment}
          month={monthJsDate}
        />
      )}
    </div>
  );
};

export default MonthlyGoalSettingFlow;
