import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const Header = ({ account }) => {
  const Br = () => '\n';

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      flexGrow: 1,
      padding: '5px 10px'
    },
    information: {
      width: '50%',
      textAlign: 'left'
    },
    information2: {
      width: '50%',
      textAlign: 'right'
    },
    date: {
      textAlign: 'center',
      fontFamily: 'Montserrat',
      fontWeight: 'light',
      fontSize: '12px'
    },
    fontTitle: {
      fontFamily: 'Montserrat',
      fontWeight: 'medium',
      fontSize: '18px'
    },
    fontSubtitle: {
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      fontSize: '14px'
    },
    fontText: {
      fontFamily: 'Montserrat',
      fontWeight: 'normal',
      fontSize: '18px'
    }
  });

  return (
    <View style={styles.container}>
      <Text style={styles.information}>
        <Text style={styles.fontTitle}>
          {account.first_name} {account.last_name}
          <Br />
        </Text>
        <Text style={styles.fontSubtitle}>
          {account?.address?.address_1}
          <Br />
        </Text>
        <Text style={styles.fontSubtitle}>
          {account?.address?.city}, {account?.address?.state} {account?.address?.postal_code}
          <Br />
        </Text>
        <Text>
          <Br />
        </Text>
      </Text>
      <Text style={styles.information2}>
        <Text style={styles.fontText}>
          1936
          <Br />
        </Text>
        <Text>
          <Br />
        </Text>
        <Text style={styles.date}>
          __________________________________
          <Br />
        </Text>
        <Text style={styles.date}>Date</Text>
      </Text>
    </View>
  );
};

export default Header;
