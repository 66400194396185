import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { v4 as uuid } from 'uuid';

const CheckBoxForm = ({ id: domId, name, label, onChange }) => {
  const id = domId || `check-box-form-${uuid()}`;

  return (
    <>
      <div className="flex items-center mb-4">
        <Field
          id={id}
          type="checkbox"
          name={name}
          className="w-4 h-4 rounded accent-whiteLighter"
          onChange={onChange}
        />
        <label
          htmlFor={id}
          className="ml-7 my-4 text-sm font-link text-gray-900 dark:text-gray-300">
          {label}
        </label>
      </div>
      <ErrorMessage
        name={name}
        component="div"
        className="text-red font-normal text-descriptionSlide"
      />
    </>
  );
};

export default CheckBoxForm;
