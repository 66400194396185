import React from 'react';

const SubmitButton = ({ value, disabled = false }) => {
  return (
    <input
      disabled={disabled}
      id="submit"
      type="submit"
      value={value}
      className="tablet:w-[315px] desktop:w-[500px] bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
    />
  );
};

export default SubmitButton;
