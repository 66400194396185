import React from 'react';
import { DateTime } from 'luxon';
import CurrencyFormat from 'react-currency-format';
import { AUTHORIZED, POSTED, ACH_TRANSACTION } from '@utilities/constants';

const Transaction = ({ amount, timestamp, details, type, id }) => {
  const formatDate = (date) => {
    return DateTime.fromFormat(`${date} UTC`, 'yyyy-MM-dd H:mm:ss z').toLocaleString(
      DateTime.DATETIME_MED
    );
  };

  return (
    <div
      id={`transaction-${id}`}
      className="transaction flex justify-center items-center w-full border-b border-greige py-5">
      <div className="flex justify-center items-center w-full gap-4">
        <span className="fa-stack flex-shrink-0">
          <i className={`fas fa-circle fa-stack-2x ${amount < 0 ? 'text-red' : 'text-seafoam'} `} />
          <i
            className={`far ${
              amount < 0 ? 'fa-arrow-down-left' : 'fa-arrow-up-right'
            } fa-stack-1x fa-inverse text-whiteLighter`}
          />
        </span>
      </div>
      <div className="flex desktop:flex-row tablet:flex-col-reverse mobile:flex-col-reverse gap-4 justify-center items-center w-full">
        <div className="flex flex-col justify-start items-start w-full">
          <div className="date desktop:block tablet:block mobile:block w-full text-descriptionSlide font-normal text-center">
            {formatDate(timestamp)}
          </div>
        </div>
        <div className="description desktop:block tablet:blcok mobile:block w-full text-descriptionSlide font-normal text-center">
          {details}
        </div>
      </div>
      <div className="w-full flex justify-center items-center desktop:gap-4 tablet:gap-4 mobile:gap-2">
        <CurrencyFormat
          value={amount}
          displayType="text"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          prefix="$"
          className={`amount text-descriptionSlide`}
        />
        <div className="desktop:hidden tablet:hidden mobile:flex justify-center items-center">
          {type === ACH_TRANSACTION && <i className="fa-solid fa-circle-exclamation text-seafoam" />}
          {type === AUTHORIZED && <i className="fa-solid fa-circle-exclamation text-seafoam" />}
          {type === POSTED && <i className="fa-solid fa-circle-check text-greyblue" />}
        </div>
      </div>
      <div className="w-full desktop:flex tablet:flex mobile:hidden justify-center items-center">
       {type === ACH_TRANSACTION && (
          <div className="bg-greyblue flex justify-center items-center rounded-full py-2 px-3 gap-2">
            <i className="fa-solid fa-circle-exclamation text-white" />
            <span className="text-white text-link font-bold">ACH TRANSACTION</span>
          </div>
        )}
        {type === AUTHORIZED && (
          <div className="bg-greyblue flex justify-center items-center rounded-full py-2 px-3 gap-2">
            <i className="fa-solid fa-circle-exclamation text-white" />
            <span className="text-white text-link font-bold">AUTHORIZED</span>
          </div>
        )}
        {type === POSTED && (
          <div className="bg-greyblue flex justify-center items-center rounded-full py-2 px-3 gap-2">
            <i className="fa-solid fa-circle-check text-white" />
            <span className="text-white text-link font-bold">POSTED</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Transaction;
