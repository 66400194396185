import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Form, Formik, Field } from 'formik';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import SignOutButton from '@components/SignOutButton';
import ValidatedInput from '@components/ValidatedInput';
import { phoneVerification as phoneVerificationApi } from '@helpers/api';

const PhoneVerificationForm = ({
  accountApplication,
  setHasPhoneVerification,
  handleSuccess,
  backPath
}) => {
  const swalPopUp = withReactContent(Swal);
  const defaultSelectedPhone = accountApplication.phone_verification_attributes.phone1;
  const defaultVerificationType = 'sms';
  const [sentPhoneVerification, setSentPhoneVerification] = useState(accountApplication.sent_phone_verification);
  const phoneValues = {
    phone1: accountApplication.phone_verification_attributes.phone1,
    phone2: accountApplication.phone_verification_attributes.phone2,
    phone3: accountApplication.phone_verification_attributes.phone3
  };
  const [formValues, setFormValues] = useState({
    selected_phone: defaultSelectedPhone,
    verification_type: defaultVerificationType,
    ...phoneValues
  });

  const handleSubmit = (values, { setSubmitting }) => {
    phoneVerificationApi
      .sendVerification(values)
      .then(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'success',
          title: 'We have sent a verification code to your phone',
          showConfirmButton: true,
          allowOutsideClick: false,
          preConfirm: () => {
            setSentPhoneVerification(true);
          }
        });
      })
      .catch((error) => {
        const title = error.response.data ? error.response.data.errors : 'There was an error sending the verification code';
        swalPopUp.fire({
          position: 'center',
          icon: 'error',
          title: title,
          showConfirmButton: false,
          timer: 3000
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleVerificationSubmit = (values, { setSubmitting }) => {
    phoneVerificationApi
      .verifyPhone(values)
      .then(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'success',
          title: 'Phone number successfully verified!',
          showConfirmButton: true,
          allowOutsideClick: false,
          preConfirm: () => {
            setHasPhoneVerification(false);
          }
        });
      })
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        const title = error.response.data ? error.response.data.errors : 'An Error Occurred';
        swalPopUp.fire({
          position: 'center',
          icon: 'error',
          title: title,
          showConfirmButton: true,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleBackButton = () => {
    setSentPhoneVerification(false);
    setFormValues({
      selected_phone: defaultSelectedPhone,
      verification_type: defaultVerificationType,
      ...phoneValues
    });
  };

  const handleResendClick = (values) => {
    phoneVerificationApi
      .sendVerification(values)
      .then(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'success',
          title: 'We have sent a verification code to your phone',
          showConfirmButton: true,
          allowOutsideClick: false
        });
      })
      .catch((error) => {
        const title = error.response.data ? error.response.data.errors : 'There was an error sending the verification code';
        swalPopUp.fire({
          position: 'center',
          icon: 'error',
          title: title,
          showConfirmButton: false,
          timer: 3000
        });
      })
  };

  return (
    <>
      <div className="flex flex-col justify-center items-start gap-4 mt-6 bg-whiteLighter py-6 px-10 rounded-3xl">
        <div className="flex flex-col items-center justify-center w-full">
          <h2 className="text-lg font-bold tracking-wide">Verify and Submit</h2>
          <p className="text-center">
            One last step! We need to verify your phone number before you can submit your application.
          </p>
        </div>
        {sentPhoneVerification ? (
          <Formik initialValues={{ verification_code: '' }} onSubmit={handleVerificationSubmit}>
            {({ errors, values, handleChange, setFieldValue, isSubmitting }) => (
              <Form className="flex flex-col items-center justify-center w-full">
                <ValidatedInput
                  type="text"
                  name="verification_code"
                  placeholder="Verification Code"
                  errors={errors}
                  value={values.verification_code}
                  onChange={handleChange}
                />
                <button type="button" className="w-full m-auto" onClick={() => handleResendClick(formValues)}>
                  <div className="flex justify-center items-center gap-2 text-seafoam">
                    <span className="text-link font-bold">Resend Verification Code</span>
                  </div>
                </button>
                  <div className="flex mobile:flex-col justify-center items-center mobile:gap-2 tablet:gap-3 desktop:gap-3">
                    <button
                      type="button"
                      className="tablet:w-[315px] desktop:w-[244px] bg-seafoam hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7"
                      onClick={handleBackButton}>
                      {`<`} Select Another Phone
                    </button>
                    <button
                      type="submit"
                      className="tablet:w-[315px] desktop:w-[244px] bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7"
                      disabled={isSubmitting || !values.verification_code}>
                      Verify and Submit
                    </button>
                  </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik initialValues={formValues} onSubmit={handleSubmit}>
            {({ errors, values, handleChange, setFieldValue, isSubmitting }) => (
              <Form className="flex flex-col items-center justify-center w-full">
                <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
                  <label className="text-black font-semibold mb-2 text-lg">Select Primary Phone:</label>
                  <div className="relative z-0 group">
                    {Object.entries(formValues).map(([key, value]) => {
                      if (key.startsWith('phone') && value) {
                        return (
                          <label key={key} className="text-lg">
                            <Field type="radio" name="selected_phone" value={value} />
                            {value}
                            <br/>
                          </label>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
                <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
                  <label className="text-black font-semibold mb-2 text-lg">Send verification code via:</label>
                  <div className="relative z-0 group">
                    <label className="text-lg">
                      <Field type="radio" name="verification_type" value="sms" />
                      Text
                    </label>
                    <br/>
                    <label className="text-lg">
                      <Field type="radio" name="verification_type" value="call" />
                      Call
                    </label>
                  </div>
                </div>

                <button
                  type="submit"
                  className="tablet:w-[315px] desktop:w-[500px] bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7"
                  disabled={
                    isSubmitting ||
                    Object.keys(errors).length
                  }>
                  Send Verification Code
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>

        <Link to={backPath} className="mobile:hidden tablet:block desktop:block">
          <div className="flex justify-center items-center gap-2 text-seafoam">
            <span className="text-link font-bold">{`<`} Back</span>
          </div>
        </Link>
      <SignOutButton />
    </>
  );
};

export default PhoneVerificationForm;
