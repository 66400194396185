import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { plaid as plaidApi } from '@helpers/api';
import { usePlaidLink } from 'react-plaid-link';
import { EnrollmentContext } from '@contexts';

const PlaidLink = ({ selectAccount }) => {
  const [linkToken, setLinkToken] = useState(null);
  const { enrollment } = useContext(EnrollmentContext);
  const { achAccountId } = useParams();

  const fetchLinkToken = () => {
    plaidApi
      .createLinkToken({ data: { enrollment, ach_account_id: achAccountId } })
      .then((response) => {
        const linkTokenResponse = response.data.link_token;
        setLinkToken(linkTokenResponse);
        localStorage.setItem('linkToken', linkTokenResponse);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const config = {
    onSuccess: (plaidPublicToken, metadata) => {
      selectAccount(plaidPublicToken, metadata.account);
      localStorage.removeItem('linkToken');
    },
    onExit: (err) => {
      if (err?.error_code === 'INVALID_LINK_TOKEN') {
        localStorage.removeItem('linkToken');
      }
    },
    onEvent: () => {},
    token: linkToken
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    fetchLinkToken();
  }, []);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return <div />;
};

export default PlaidLink;
