import React from 'react';
import { useParams } from 'react-router-dom';
import ExpenseItems from './ExpenseItems';
import BreadCrumbComponent from '../Shared/BreadCrumbComponent';
import { MonthSelector } from '../Shared/MonthSelector';

const ExpenseItemsByCategory = () => {
  const { category } = useParams();

  return (
    <>
      <MonthSelector>
        <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center my-4">
          Expenses
        </h1>
      </MonthSelector>
      <BreadCrumbComponent />
      <ExpenseItems category={category} />
    </>
  );
};

export default ExpenseItemsByCategory;
