import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { TrackerLearnMoreImg } from '../../constants/images';

export default function SuccessTrackerLearnMore() {
  const navigate = useNavigate();

  return (
    <div className="tracker-page flex flex-col items-center px-4 desktop:px-14 gap-4 w-full">
      <div className="flex flex-col bg-teal rounded-md p-8 gap-8 w-full">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-8 text-left">
          Success Tracker - Your Path to Financial Success
        </h1>
        <div className="bg-white rounded-lg shadow-lg p-6 w-full flex flex-col desktop:flex-row items-start gap-8">
          <div className="w-full desktop:w-2/4">
            <h2 className="text-2xl font-semibold mb-4">What is Success Tracker?</h2>
            <p className="mb-4">
              Success Tracker is a comprehensive financial management tool designed to help you:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>
                Manage Chapter 13 Payments: Keep track of your trustee payments, ensuring you stay
                on track for a successful discharge.
              </li>
              <li>Monitor Spending: Track your spending on your YourSuccess Debit card.</li>
              <li>
                Include Activity on Other Accounts: Input transactions from other accounts to get a
                complete view of your income and expenses
              </li>
            </ul>
            <h2 className="text-2xl font-semibold mb-4">Key Features</h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-md font-medium">Trustee Payments:</h3>
                <ul className="list-disc list-inside ml-4">
                  <li>
                    Set and track your monthly Chapter 13 payments, ensuring you never miss a due
                    date.
                  </li>
                  <li>
                    Making your most important monthly payment a top priority is key to achieving a
                    successful discharge.
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-md font-medium">Spending Management</h3>
                <ul className="list-disc list-inside ml-4">
                  <li>
                    <em>Automatic Alerts</em>: Receive notifications if you are close to going over your
                    planned monthly expenditures.
                  </li>
                  <li>
                    <em>Card Transactions</em>: Monitor all your spending on the YourSuccess Bank Account
                    card in real-time.
                  </li>
                  <li>
                    <em>Budgeting Tools</em>: Set budgets for different categories and track your spending to
                    stay within limits.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full desktop:w-2/4 flex justify-center">
            <img className="my-8" src={TrackerLearnMoreImg} alt="Success Tracker Learn More" />
          </div>
        </div>
        <div className="flex justify-center mt-8 w-full">
          <Button
            variant="contained"
            color="primary"
            className="w-96"
            onClick={() => navigate('/dashboard/tracker/get-started')}>
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
}
