import React from 'react';
import CurrencyInput from 'react-currency-input-field';

const CustomCurrencyInput = ({ inputName, inputValue, onChange, className, placeholder }) => {
  return (
    <CurrencyInput
      name={inputName}
      placeholder={placeholder}
      decimalsLimit={2}
      allowNegativeValue={false}
      value={inputValue}
      className={className}
      onValueChange={(value, name, { value: realValue }) => {
        onChange(name, realValue);
      }}
      prefix="$ "
    />
  );
};

export default CustomCurrencyInput;
