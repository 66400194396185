export const routerTracker = [
  {
    path: '/dashboard/tracker',
    breadcrumb: 'Tracker Dashboard'
  },
  {
    path: '/dashboard/tracker/expenses',
    breadcrumb: 'Expenses Overview'
  },
  {
    path: '/dashboard/tracker/expense-goals',
    breadcrumb: 'Expense Goals',
  },
  {
    path: '/dashboard/tracker/expense-goals/expense-item-by-category/:category'
  },
  {
    path: '/dashboard/tracker/monthly-goals',
    breadcrumb: 'Monthly Goal Setting Flow'
  },
  {
    path: '/dashboard/tracker/trustee-payment',
    breadcrumb: 'Trustee Payment',
  },
  {
    path: '/dashboard/tracker/income-goals',
    breadcrumb: 'Income Goals',
  }
];
