import React from 'react';

const SuccessModalRequesCard = ({ reason }) => {
  return (
    <div className="flex flex-col justify-start items-start w-full mobile:w-full tablet:w-full gap-3 desktop:p-8 tablet:p-8 mobile:p-4 bg-white rounded-xl">
      <h2
        className={`${
          reason === 'lost' || reason === 'stolen' ? 'block' : 'hidden'
        } title-settings text-dashBoardTopName font-bold text-black text-center w-full`}>
        Lost/Stolen
      </h2>
      <h2
        className={`${
          reason === 'damaged' ? 'block' : 'hidden'
        } title-settings text-dashBoardTopName font-bold text-black text-center w-full`}>
        Damaged
      </h2>
      <p className="text-left">Expect to receive your new card by mail in 7 to 10 business days</p>
      <p className={`${reason === 'lost' || reason === 'stolen' ? 'block' : 'hidden'} text-left`}>
        Remember that you will be receiving a new debit card number, so be sure to adjust your
        information on any recurring payments you may have set up with your previous debit card
        number.
      </p>
      <p className={`${reason === 'damaged' ? 'block' : 'hidden'} text-left`}>
        For your own security, be sure to properly dispose of your damaged card by cutting it up or
        shredding it before throwing it away.
      </p>
    </div>
  );
};

export default SuccessModalRequesCard;
