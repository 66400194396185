import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { TrackerAvatarImg } from '@constants/images';
import UncategorizedExpenses from './UncategorizedExpenses';

const ExpensesText = () => {
  return (
    <Paper elevation={1} sx={{ p: 3 }}>
      <Typography variant="body1" textAlign="left" sx={{mb: 1}}>
        Expense Goals
      </Typography>
      <Box display="flex" flexDirection="row" mb={1}>
        <img
          src={TrackerAvatarImg}
          alt="Budgeting Buddy"
          className="rounded-full"
          style={{ width: 50, height: 50 }}
        />
        <Typography variant="body2" textAlign="justify" sx={{marginLeft: 1}}>
        Dollar Bill: "I need to know what expenses you plan on incurring this month. 
        While some expenses, like mortgage or rent and food occur every month, 
        others like a birthday party expense or car repair do not. To get the most 
        accurate results customize your expenses month-to-month."
        </Typography>
      </Box>
    </Paper>
  );
};

const EditExpenses = () => {
  return (
    <div className="flex justify-center items-center gap-4">
      <div className="flex flex-col gap-4 p-8 w-4/5">
        <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center">
          Payment Tracking
        </h1>
        <ExpensesText />
        <UncategorizedExpenses />
      </div>
    </div>
  );
};

export default EditExpenses;
