import React from 'react';

const AchAccountVerificationStatus = ({ achAccount, isCurrentAchAccount }) => {
  return (
    <>
      <i
        className={`fa-solid fa-circle-exclamation fa-2x ${
          isCurrentAchAccount ? 'text-white' : 'text-yellow'
        }`}
      />
      <div>{achAccount.humanized_verification_status}</div>
    </>
  );
};

export default AchAccountVerificationStatus;
