import React from 'react';
import AchToggle from '@components/AchToggle';

const EarlyAchDeposits = () => {
  return (
    <div className="h-full m-4 early-ach">
      <AchToggle />
    </div>
  );
};

export default EarlyAchDeposits;
