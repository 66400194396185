import { post as _post } from './httpBase';

const verifyUrl = '/api/phone_verifications/verify';
const sendVerificationUrl = '/api/phone_verifications/send_verification';

export const verifyPhone = ( params ) => {
  const post = _post(verifyUrl);
  return post({ data: { ...params } });
};

export const sendVerification = (params) => {
  const post = _post(sendVerificationUrl);
  return post({ data: { ...params } });
};
