import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SignOut } from '@helpers/SignOut';
import { swalSignOut } from '@constants/sweetAlert';
import { Footer, ModalSignOut } from '@components/Dashboard';
import { SessionContext } from '@contexts';

const ReactivateAccountButton = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="px-4 py-5 bg-seafoam hover:bg-seafoam/80 rounded-full h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-1">
      <span className="text-white text-link">Fund Account</span>
    </button>
  );
};

const SignOutButton = ({ onClick, loading }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="px-4 py-5 bg-redDarker hover:bg-red rounded-full h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-1">
      <span className="text-white text-link font-bold">Sign Out</span>
    </button>
  );
};

const Closed = () => {
  const navigate = useNavigate();
  const swalPopUp = withReactContent(Swal);
  const { user } = useContext(SessionContext);

  const modalSignOut = () => {
    swalPopUp
      .fire({
        html: <ModalSignOut />,
        ...swalSignOut
      })
      .then((event) => {
        if (event.isConfirmed) SignOut();
      });
  };

  const reactivateAccount = useCallback(() => {
    navigate('/dashboard/funding', { replace: true });
  }, []);

  return (
    <div>
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center gap-4">
        {user.closed_account_reason === 'lack_of_funding' && (
          <div className="flex flex-col justify-center items-center gap-4">
            <p>
              Your account was closed due to lack of funding. If you would like to reopen the
              account you must initiate funding of the account.
            </p>
            <ReactivateAccountButton onClick={reactivateAccount} />
          </div>
        )}
        <div className="flex flex-col justify-center items-center gap-4">
          {user.closed_account_reason !== 'lack_of_funding' && <p>Your account was closed</p>}
        </div>
        <SignOutButton onClick={modalSignOut} />
      </div>
      <Footer />
    </div>
  );
};

export default Closed;