import React, { useState, useEffect } from 'react';
import { DeleteForever, Info } from '@mui/icons-material';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Inputs/CustomSelect';
import Button from '@mui/material/Button';
import { EXPENSE_TYPE, EXPENSE_GOALS_INITIAL_VALUES } from '../../../utilities/constants';
import { postCreateUpdateExpenseGoals, deleteExpenseGoal } from '@helpers/api/tracker';
import CurrencyFormat from 'react-currency-format';
import { DateTime } from 'luxon';
import BudgetingBuddyComponent from '../../shared/BudgetingBuddy';

const validationSchema = Yup.object({
  expense_goals: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string()
        .test(
          'maxDigitsAfterDecimal',
          'Monthly amount must be a valid number with a maximum of 2 decimal.',
          (number) => /^\d+(\.\d{1,2})?$/.test(number)
        )
        .required('This field is required'),
      category: Yup.string().required('This field is required')
    })
  )
});

const isNextMonth = (date) => {
  const currentDate = DateTime.now();
  const dateParam = DateTime.fromJSDate(date);
  const nextMonthDate = currentDate.plus({ months: 1 }).startOf('month');
  return dateParam >= nextMonthDate && dateParam < nextMonthDate.plus({ months: 1 });
};

const ExpenseGoalsComponent = ({
  expenseGoals,
  handleNext,
  handleBack,
  setExpenseGoals,
  headerText,
  incomeGoals,
  trusteePayment,
  date = null
}) => {
  const [expenseGoalsList, setExpenseGoalsList] = useState({
    expense_goals: EXPENSE_GOALS_INITIAL_VALUES.map((item) => ({ ...item, effective_date: date }))
  });
  const [alert, setAlert] = useState(false);
  const [valueEnough, setValueEnough] = useState(0);

  useEffect(() => {
    if (expenseGoals.filter((item) => item.category !== 'trustee_payment').length > 0) {
      const resultExpense = expenseGoals.filter((item) => item.category !== 'trustee_payment');
      setExpenseGoalsList({ expense_goals: resultExpense });
    }
  }, [expenseGoals]);

  const removeFromList = async (i, values, setValues) => {
    const expense_goals = [...values.expense_goals];
    let result;
    if (expense_goals[i].id) {
      if (isNextMonth(date)) {
        const dateJs = date ? DateTime.fromJSDate(date).toFormat('yyyy-LL-dd') : null;
        result = await deleteExpenseGoal(expense_goals[i].id)({params: { month: dateJs }}).catch((e) =>
          console.error(e)
        );
      } else {
        result = await deleteExpenseGoal(expense_goals[i].id)().catch((e) => console.error(e));
      }
      setExpenseGoals(result.data.expense_goals);
    }
    expense_goals.splice(i, 1);
    handleEnoughIncome(expense_goals);
    setValues((prev) => ({ ...prev, expense_goals }));
  };

  const updateForm = (values, setValues) => {
    const expense_goals = [...values.expense_goals];
    expense_goals.push({
      amount: '',
      category: '',
      effective_date: date
    });
    setValues((prev) => ({ ...prev, expense_goals }));
  };

  const handleSubmit = async (values) => {
    if (handleEnoughIncome(values)) {
      return false;
    }
    const expense_goals = [...values.expense_goals];
    if (values.expense_goals?.length > 0) {
      try {
        const dateJs = date ? DateTime.fromJSDate(date).toFormat('yyyy-LL-dd') : null;
        const response = await postCreateUpdateExpenseGoals({
          data: {
            month: dateJs,
            expense_goal: {
              items: expense_goals
            }
          }
        });
        if (response.status === 200) {
          setExpenseGoals(response.data.expense_goals);
          handleNext();
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      handleNext();
    }
  };

  const getIncome = () => {
    return incomeGoals.reduce((acc, cur) => Number(acc) + Number(cur.amount), 0);
  };

  const handleEnoughIncome = (values) => {
    const expensesAmount = values.expense_goals?.reduce(
      (acc, cur) => Number(acc) + Number(cur.amount),
      0
    );

    const expensesWithTrusteePayment = expensesAmount + Number(trusteePayment.amount);

    const incomeAmount = incomeGoals.reduce((acc, cur) => Number(acc) + Number(cur.amount), 0);
    setValueEnough(expensesWithTrusteePayment - incomeAmount);

    setAlert(expensesWithTrusteePayment > incomeAmount);
    return expensesWithTrusteePayment > incomeAmount;
  };

  return (
    <>
      <BudgetingBuddyComponent videoId={'SXrsAfFlSFg'} />
      <h3 className="mb-6 text-xl font-semibold">{headerText}</h3>
      {alert && (
        <div className="mb-6 text-sm bg-redDarker font-bold text-white p-4 rounded-md">
          <p>
            You have entered too many expenses for the month to be able to make your trustee
            payment. Please review your expenses and adjust where necessary to be able to cover your
            trustee payment for the month.
          </p>
          <p className="mt-4 font-bold text-center text-lg">
            Income:{' '}
            <CurrencyFormat
              value={getIncome()}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
            />
          </p>
          <p className="mt-4 font-bold text-center text-lg">
            Excess in expenses:{' '}
            <CurrencyFormat
              value={valueEnough}
              displayType="text"
              thousandSeparator
              fixedDecimalScale
              decimalScale={2}
              prefix="$"
            />
          </p>
        </div>
      )}
      <p className="mb-6 text-sm text-gray-600">
        <Info className="inline-block mr-2" />
        Don't worry if the expenses are different from the month before. These <br />
        are goals. Dollar Bill will warn you if you plan on spending too much.
      </p>
      <p className="mb-6 text-sm text-gray-600">
        <Info className="inline-block mr-2" />
        Tip: Your possible expenses are listed on Schedule J of your bankruptcy.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Formik
          initialValues={expenseGoalsList}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}>
          {({ values, setValues, isSubmitting }) => (
            <Form style={{ width: '100%', maxWidth: '500px' }}>
              <FieldArray name="expense_goals">
                {() => {
                  return values.expense_goals.map((item, i) => (
                    <div
                      key={i}
                      className="flex flex-col desktop:flex-row justify-center items-center desktop:gap-8 w-full">
                      <CustomInput
                        name={`expense_goals.${i}.amount`}
                        placeholder="Amount"
                        icon="fa-dollar-sign"
                        currency
                      />
                      <CustomSelect
                        name={`expense_goals.${i}.category`}
                        placeholder="Expense Type"
                        options={EXPENSE_TYPE.filter(
                          (item) => item.value !== 'cushion' && item.value !== 'trustee_payment'
                        )}
                      />
                      <button
                        className="cursor-pointer mb-7"
                        type="button"
                        onClick={() => removeFromList(i, values, setValues)}>
                        <DeleteForever />
                      </button>
                    </div>
                  ));
                }}
              </FieldArray>
              <button
                className="bg-seafoam text-white px-4 py-2 rounded hover:bg-teal"
                type="button"
                onClick={(e) => updateForm(values, setValues)}>
                + Add additional expense goal
              </button>
              <div>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  type="submit"
                  sx={{ mt: 1, mr: 1 }}>
                  Continue
                </Button>

                <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ExpenseGoalsComponent;
