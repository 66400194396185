import React, { useState } from 'react';
import Modal from 'react-overlays/Modal';

const ModalComponent = ({
  showModal,
  setShowModal,
  handleComplete,
  title,
  children,
  showFooter = true
}) => {
  const renderBackdrop = (props) => <div className="backdrop" {...props} />;

  var handleClose = () => setShowModal(false);

  var handleSuccess = () => {
    handleClose();
    handleComplete();
  };

  return (
    <Modal
      className="modal-component"
      show={showModal}
      onHide={handleClose}
      renderBackdrop={renderBackdrop}
      backdrop="static">
      <div className="h-full">
        <div className="modal-header">
          <div className="modal-title text-black font-bold text-2xl">{title}</div>
          <div>
            <span className="close-button" onClick={handleClose}>
              x
            </span>
          </div>
        </div>
        <div className="modal-desc">{children}</div>
        {showFooter && (
          <div className="modal-footer">
            <button className="secondary-button" onClick={handleClose}>
              Close
            </button>
            <button className="primary-button" onClick={handleSuccess}>
              Save Changes
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalComponent;
