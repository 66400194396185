import React from 'react';
import { Box } from '@mui/material';
import { TrackerAvatarImg } from '@constants/images';
import VideoModal from './VideoModal';

const BudgetingBuddyComponent = ({ copy = 'Stuck on a step?', videoId = null, imageWidth = 125 }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
      <img
        src={TrackerAvatarImg}
        alt="Budgeting Buddy"
        className="rounded-full"
        style={{ width: imageWidth, height: 'auto' }}
      />
      {videoId && <VideoModal copy={copy} videoId={videoId} />}
    </Box>
  );
};

export default BudgetingBuddyComponent;
