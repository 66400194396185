/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import ButtonsFormFinalStep from '@components/ButtonsFormFinalStep';
import CheckBox from '@components/CheckBox';
import ErrorsTemplate from '@components/ErrorsTemplate';
import { SessionContext } from '@contexts';
import { useOccupations } from '@hooks';
import Loader from '@components/Loader';
import {
  DDAAgreementLink,
  PathwardPrivacyPolicy,
  TFSBankAccountESignDisclosure
} from '@constants/pdfs';
import { swalConfig } from '@constants/sweetAlert';
import { accountApplications as applicationApi } from '@helpers/api';

import PhoneVerificationForm from './PhoneVerificationForm';
import FormTitle from './FormTitle';

const ReviewForm = ({ 
  accountApplication,
  setCurrentStep,
  hasPhoneVerification,
  setHasPhoneVerification
}) => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(SessionContext);
  const { occupations } = useOccupations();

  const primaryAddress = accountApplication.address_attributes;
  const shippingAddress = accountApplication.shipping_address_attributes;
  const {
    first_name,
    middle_name,
    last_name,
    primary_phone,
    date_of_birth,
    income_source,
    occupation,
    shipping_address_different,
    id_number,
    id_type
  } = accountApplication;

  const [loading, setLoading] = useState(false);
  const [electronicCommunicationsAgreement, setElectronicCommunicationsAgreement] = useState(false);
  const [depositAccountAgreement, setDepositAccountAgreement] = useState(false);
  const [privacyPolice, setPrivacyPolice] = useState(false);
  const allAgreementsChecked = electronicCommunicationsAgreement && depositAccountAgreement && privacyPolice;

  const handleCheckBox = (e, id) => {
    e.preventDefault();
    if (id === 'electronic_communications_agreement') {
      setElectronicCommunicationsAgreement(true);
    }
    if (id === 'deposit_account_agreement') {
      setDepositAccountAgreement(true);
    }
    if (id === 'privacy_policy') {
      setPrivacyPolice(true);
    }
    window.open(e.target.getAttribute('href'), '_blank', 'noopener,noreferrer');
  };

  const swalError = withReactContent(Swal);

  useEffect(() => {
    setCurrentStep('review');
  }, []);

  const DepositAmountOptions = [
    {
      value: '1',
      description: '2,000 or less'
    },
    { value: '2', description: 'More than $2,000 and less than $10,000' },
    { value: '3', description: '$10,001 or more but less than $20,000' },
    { value: '4', description: '$20,000 or more' }
  ];

  const handleSubmit = () => {
    setLoading(true);
    const identification = {
      account_application: {
        id_type: id_type,
        id_number: id_number
      }
    };
    applicationApi
      .submit({ data: identification })
      .then(() => {
        setLoading(false);
        setUser({
          ...user,
          has_approved_account_application: true,
          redirectPath: '/enrollment-funding'
        });
      })
      .catch((error) => {
        setLoading(false);
        const { errors, details } = error.response.data;
        if (errors === 'Invalid parameter') {
          const errorHtml = <ErrorsTemplate errors={details} />;
          swalError
            .fire({
              html: errorHtml,
              ...swalConfig
            })
            .then(() => {
              setLoading(false);
            });
        } else if (
          errors[0] === 'Account submission rejected. An account can not be created for this ID'
        ) {
          navigate('/account-application/application-denied');
        } else if (
          errors[0] === 'Account submission referred to partner to gather more information'
        ) {
          navigate('/account-application/application-referred');
        } else if (
          errors[0] === 'Identification does not match records. Please confirm your information and try again.'
        ) {
          const errorHtml = <ErrorsTemplate errors={errors} />;
          swalError
            .fire({
              html: errorHtml,
              ...swalConfig
            })
            .then(() => {
              setLoading(false);
              location.reload();
            });
        } else {
          const errorHtml = <ErrorsTemplate errors={errors} />;
          swalError
            .fire({
              html: errorHtml,
              ...swalConfig
            })
            .then(() => {
              setLoading(false);
            });
        }
      });
  };

  if (!accountApplication.id_number) {
    return <Navigate replace to="/account-application/identification" />;
  }

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <FormTitle>Review your application</FormTitle>
      <div className="flex flex-col items-center justify-center w-full">
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            First Name
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {first_name}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            Middle Name
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {middle_name}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            Last Name
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {last_name}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            Phone Number
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {primary_phone}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            Date of Birth
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {date_of_birth}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            Expected Monthly Deposit Amount
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {income_source ? DepositAmountOptions.find((amount) => amount.value === income_source)?.description : 'No income source selected'}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            Occupation
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {occupations.find(({ code }) => code === Number(occupation))?.definition}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            Address
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {primaryAddress.address_1}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            Address 2
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {primaryAddress.address_2}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            City
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {primaryAddress.city}
          </span>
        </div>
        <div className="flex items-center justify-center w-full gap-6">
          <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
            Zip Code
          </div>
          <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
            {primaryAddress.postal_code}
          </span>
        </div>

        {shipping_address_different && (
          <>
            <div className="flex items-center justify-center w-full gap-6">
              <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
                Shipping Address
              </div>
              <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
                {shippingAddress.address_1}
              </span>
            </div>
            <div className="flex items-center justify-center w-full gap-6">
              <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
                Shipping Address 2
              </div>
              <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
                {shippingAddress.address_2}
              </span>
            </div>
            <div className="flex items-center justify-center w-full gap-6">
              <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
                Shipping Address City
              </div>
              <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
                {shippingAddress.city}
              </span>
            </div>
            <div className="flex items-center justify-center w-full gap-6">
              <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
                Shipping Address State
              </div>
              <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
                {shippingAddress.state}
              </span>
            </div>
            <div className="flex items-center justify-center w-full gap-6">
              <div className="w-full flex justify-end items-start text-greyblue text-link font-medium">
                Shipping Address Zip Code
              </div>
              <span className="w-full flex justify-start items-start text-darkGray font-dashBoardName font-medium">
                {shippingAddress.postal_code}
              </span>
            </div>
          </>
        )}

        <div className="flex flex-col justify-center items-start gap-4 mt-6 bg-whiteLighter py-6 px-10 rounded-3xl">
          <h3 className="text-descriptionSlide font-bold text-black text-center">
            Please click and open each agreement separately to activate the submit button
          </h3>
          <div className="flex items-center">
            {electronicCommunicationsAgreement && (
              <i className="fa-solid fa-circle-check text-seafoam mr-4" />
            )}
            {!electronicCommunicationsAgreement && (
              <i className="fa-solid fa-circle-xmark text-red mr-4" />
            )}
            <a
              href={TFSBankAccountESignDisclosure}
              target="_blank"
              rel="noreferrer"
              id="electronicCommunicationsAgreement"
              className="underline text-seafoam hover:text-teal"
              onClick={(e) => handleCheckBox(e, 'electronic_communications_agreement')}>
              Electronic Communications Agreement
            </a>
          </div>
          <div className="flex items-center">
            {depositAccountAgreement && (
              <i className="fa-solid fa-circle-check text-seafoam mr-4" />
            )}
            {!depositAccountAgreement && <i className="fa-solid fa-circle-xmark text-red mr-4" />}
            <a
              href={DDAAgreementLink}
              target="_blank"
              rel="noreferrer"
              id="depositAccountAgreement"
              className="underline text-seafoam hover:text-teal"
              onClick={(e) => handleCheckBox(e, 'deposit_account_agreement')}>
              Deposit Account Agreement
            </a>
          </div>
          <div className="flex items-center">
            {privacyPolice && <i className="fa-solid fa-circle-check text-seafoam mr-4" />}
            {!privacyPolice && <i className="fa-solid fa-circle-xmark text-red mr-4" />}
            <a
              href={PathwardPrivacyPolicy}
              target="_blank"
              rel="noreferrer"
              id="privacyPolice"
              className="underline text-seafoam hover:text-teal"
              onClick={(e) => handleCheckBox(e, 'privacy_policy')}>
              Pathward Privacy Policy
            </a>
          </div>
        </div>
      </div>

      { hasPhoneVerification && allAgreementsChecked ? (
          <PhoneVerificationForm
            accountApplication={accountApplication}
            setHasPhoneVerification={setHasPhoneVerification}
            handleSuccess={handleSubmit}
            backPath="/account-application/identification"
          />
      ) : (
          <ButtonsFormFinalStep
            button="Submit"
            link="/account-application/identification"
            onClick={handleSubmit}
            isValid={allAgreementsChecked}
          />
      )}
    </>
  );
};

export default ReviewForm;
