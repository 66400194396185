import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { SessionContext } from '@contexts';

const PrivateRoute = ({ children }) => {
  const { user, setSessionLocation } = useContext(SessionContext);
  const { pathname, search } = useLocation();

  useEffect(() => {
    setSessionLocation(pathname + search);
  }, [pathname, search]);

  if (user) {
    if (user.has_approved_account_application) {
      if (user.has_closed_account && pathname !== '/closed' && pathname !== '/dashboard/funding') {
        return <Navigate to="/closed" />;
      }

      return children;
    }
    return <Navigate to="/account-application" />;
  }
  return <Navigate to="/" />;
};

export default PrivateRoute;
