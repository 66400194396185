import React from 'react';
import { useField, useFormikContext } from 'formik';
import { NumericFormat } from 'react-number-format';
import { getClass } from '@helpers/formik';

const CustomCurrencyInput = (props) => {
  const { name, errors } = props;
  const [field] = useField(name);
  const {setFieldValue} = useFormikContext();

  return (
    <NumericFormat
      value={field.value}
      onValueChange={(values) => {
        const {value} = values;
        setFieldValue(name, value);
      }}
      name={name}
      decimalScale={2}
      thousandSeparator
      decimalSeparator="."
      fixedDecimalScale
      allowNegative={false}
      className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray ${getClass(
        errors,
        name
      )}`}
    />
  );
};

export default CustomCurrencyInput