import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import RegionSelect from '@components/RegionSelect';
import * as Yup from 'yup';
import { accounts as accountsApi, users as usersApi } from '@helpers/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CheckBoxForm from '@components/CheckBoxForm';
import { AccountContext, SessionContext } from '@contexts';
import ValidatedInput from '../ValidatedInput/ValidatedInput';

const requiredMessage = 'Required';
const maxErrorMessage = 'Too long';
const minErrorMessage = 'Needs more characters';
const matchesErrorMessage = 'Invalid';

const alphaRegex = /^[A-Za-z\s\-.]*$/;
const alphaWithExtrasRegex = /^[A-Za-z\s\-']*$/;
const alphaNumericRegex = /^[A-Za-z\s\d#\-.]*$/;
const postalCodeRegex = /(^\d{5}$|^\d{5}-\d{4}$)/;

const addressSchema = {
  address_1: Yup.string()
    .matches(alphaNumericRegex, matchesErrorMessage)
    .min(4, minErrorMessage)
    .max(40, maxErrorMessage)
    .required(requiredMessage)
    .nullable(),
  address_2: Yup.string().matches(alphaNumericRegex, matchesErrorMessage).max(30).nullable(),
  city: Yup.string()
    .matches(alphaRegex, matchesErrorMessage)
    .max(30, maxErrorMessage)
    .required(requiredMessage)
    .nullable(),
  state: Yup.string().length(2).required(requiredMessage).nullable(),
  postal_code: Yup.string()
    .matches(postalCodeRegex, matchesErrorMessage)
    .required(requiredMessage)
    .nullable()
};

const validationSchema = Yup.object({
  first_name: Yup.string()
    .max(40, maxErrorMessage)
    .matches(alphaWithExtrasRegex, matchesErrorMessage)
    .required(requiredMessage),
  last_name: Yup.string()
    .max(40, maxErrorMessage)
    .matches(alphaWithExtrasRegex, matchesErrorMessage)
    .required(requiredMessage),
  primary_phone: Yup.string()
    .required(requiredMessage)
    .length(10, 'Please enter a complete phone number'),
  email: Yup.string().required(requiredMessage).email('is not an email address'),
  shipping_address_different: Yup.boolean(),
  address_attributes: Yup.object(addressSchema),
  shipping_address_attributes: Yup.object().when('shipping_address_different', (value) => {
    if (value[0]) return Yup.object(addressSchema);
    return Yup.object().nullable();
  })
});

const PersonalInformationForm = () => {
  const { account, setAccount } = useContext(AccountContext);
  const { user, setUser } = useContext(SessionContext);
  const swalPopUp = withReactContent(Swal);

  const clearShippingAddressAttributes = (setFieldValue) => {
    ['address_1', 'address_2', 'city', 'state', 'postal_code'].forEach((attr) => {
      setFieldValue(`shipping_address_attributes.${attr}`, null);
    });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    accountsApi
      .updatePersonalInfo(values)
      .then(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'success',
          title: 'Your personal info has been updated.',
          html: `${
            values.email !== account.email
              ? 'Changing your email requires you to reset Multi-Factor Authentication. It has been disabled on your account and you will need to re-enable it to use it when you login.'
              : ''
          }`,
          showConfirmButton: true
        });
      })
      .catch(() => {
        swalPopUp.fire({
          position: 'center',
          icon: 'error',
          title: 'There was an error updating your personal info',
          showConfirmButton: false,
          timer: 1500
        });
      })
      .finally(() => {
        accountsApi.getShow().then((response) => {
          setAccount(response.data);
        });
        setSubmitting(false);
        if (values.email !== account.email && user.mfa_active) {
          usersApi
            .disableMFA()
            .then(() => {
              setUser({
                ...user,
                mfa_active: false
              });
            })
            .catch(() => {
              swalPopUp.fire({
                position: 'center',
                icon: 'error',
                title: 'There was an error disabling MFA.',
                showConfirmButton: false,
                timer: 1500
              });
            });
        }
      });
  };

  const formValues = {
    first_name: account.first_name,
    last_name: account.last_name,
    primary_phone: account.primary_phone,
    address_attributes: {
      address_1: account.address.address_1,
      address_2: account.address.address_2,
      city: account.address.city,
      postal_code: account.address.postal_code,
      state: account.address.state
    },
    shipping_address_different:
      account.shipping_address.hasOwnProperty('address_1') &&
      !!account.shipping_address.address_1.length,
    shipping_address_attributes: {
      address_1: account.shipping_address.address_1,
      address_2: account.shipping_address.address_2,
      city: account.shipping_address.city,
      postal_code: account.shipping_address.postal_code,
      state: account.shipping_address.state
    },
    email: account.email
  };

  return (
    <div className="personal-information">
      <div className="bg-white rounded-3xl p-5">
        <h2 className="text-bannerTitle font-bold text-black mb-6">Personal information</h2>
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ errors, values, handleChange, setFieldValue, isSubmitting }) => (
            <Form className="flex flex-col items-center justify-center w-full gap-6 pt-4">
              <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:gap-8 tablet:gap-8 mobile:gap-4 w-full justify-center items-center">
                <ValidatedInput
                  type="text"
                  name="first_name"
                  placeholder="First name"
                  errors={errors}
                  value={values.first_name}
                  onChange={handleChange}
                />
                <ValidatedInput
                  type="text"
                  name="last_name"
                  placeholder="Last name"
                  errors={errors}
                  value={values.last_name}
                  onChange={handleChange}
                />
              </div>
              <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:gap-8 tablet:gap-8 mobile:gap-4 w-full justify-center items-center">
                <ValidatedInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  errors={errors}
                  value={values.email}
                  onChange={handleChange}
                />
                <ValidatedInput
                  type="text"
                  name="primary_phone"
                  placeholder="Phone Number"
                  errors={errors}
                  value={values.primary_phone}
                  onChange={handleChange}
                />
              </div>
              <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:gap-8 tablet:gap-8 mobile:gap-4 w-full justify-center items-center">
                <ValidatedInput
                  type="text"
                  name="address_attributes.address_1"
                  placeholder="Address 1"
                  errors={errors}
                  value={values.address_attributes.address_1}
                  onChange={handleChange}
                />
                <ValidatedInput
                  type="text"
                  name="address_attributes.address_2"
                  placeholder="Address 2"
                  errors={errors}
                  value={values.address_attributes.address_2}
                  onChange={handleChange}
                />
              </div>
              <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:gap-8 tablet:gap-8 mobile:gap-4 w-full justify-center items-center">
                <ValidatedInput
                  type="text"
                  name="address_attributes.city"
                  placeholder="City"
                  errors={errors}
                  value={values.address_attributes.city}
                  onChange={handleChange}
                />
                <RegionSelect
                  name="address_attributes.state"
                  value={values.address_attributes.state}
                  label="Select State"
                  onChange={handleChange}
                  errors={errors}
                />
              </div>
              <ValidatedInput
                type="text"
                name="address_attributes.postal_code"
                placeholder="Postal Code"
                errors={errors}
                value={values.address_attributes.postal_code}
                onChange={handleChange}
              />

              <CheckBoxForm
                name="shipping_address_different"
                label="Shipping address is different from primary address"
                errors={errors}
                value={values.shipping_address_different}
                onChange={(event) => {
                  setFieldValue('shipping_address_different', event.target.checked);

                  if (!event.target.checked) clearShippingAddressAttributes(setFieldValue);
                }}
              />

              {values.shipping_address_different === true && (
                <>
                  <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:gap-8 tablet:gap-8 mobile:gap-4 w-full justify-center items-center">
                    <ValidatedInput
                      type="text"
                      name="shipping_address_attributes.address_1"
                      placeholder="Shipping Address"
                      errors={errors}
                      value={values.shipping_address_attributes.address_1}
                      onChange={handleChange}
                    />
                    <ValidatedInput
                      type="text"
                      name="shipping_address_attributes.address_2"
                      placeholder="Shipping Address Line 2"
                      errors={errors}
                      value={values.shipping_address_attributes.address_2}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:gap-8 tablet:gap-8 mobile:gap-4 w-full justify-center items-center">
                    <ValidatedInput
                      type="text"
                      name="shipping_address_attributes.city"
                      placeholder="Shipping Address City"
                      errors={errors}
                      value={values.shipping_address_attributes.city}
                      onChange={handleChange}
                    />
                    <RegionSelect
                      name="shipping_address_attributes.state"
                      value={values.shipping_address_attributes.state}
                      label="Select Shipping Address State"
                      onChange={handleChange}
                      errors={errors}
                    />
                  </div>
                  <ValidatedInput
                    type="text"
                    name="shipping_address_attributes.postal_code"
                    placeholder="Shipping Address Zip Code"
                    errors={errors}
                    value={values.shipping_address_attributes.postal_code}
                    onChange={handleChange}
                  />
                </>
              )}

              <button
                type="submit"
                className="tablet:w-[315px] desktop:w-[500px] bg-teal hover:bg-seafoam rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer"
                disabled={isSubmitting || Object.keys(errors).length}>
                Save Changes
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalInformationForm;
