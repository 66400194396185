import React from 'react';
import EquationComponent from './EquationComponent';
import BreadCrumbComponent from '../Shared/BreadCrumbComponent';
import { MonthSelector } from '../Shared/MonthSelector';

const Dashboard = () => {
  return (
    <>
      <MonthSelector>
        <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center my-4">
          Track Your Success
        </h1>
      </MonthSelector>
      <BreadCrumbComponent />
      <EquationComponent />
    </>
  );
};

export default Dashboard;
