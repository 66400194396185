import React, { useEffect, useState } from 'react';
import { TrackSuccessContext } from '@contexts';
import { paymentTracking, getTotalExpenseGoal } from '@helpers/api/tracker';
import TrackSuccessRoutes from '../TrackSuccessRoutes';
import { DateTime } from 'luxon';

const TrackSuccess = () => {
  const [incomeGoals, setIncomeGoals] = useState([]);
  const [expenseGoals, setExpenseGoals] = useState([]);
  const [expenseItems, setExpenseItems] = useState([]);
  const [trusteePayment, setTrusteePayment] = useState({});
  const [expenseGoal, setExpenseGoal] = useState(0);
  const [months, setMonths] = useState([]);
  const [month, setMonth] = useState();
  const [reloadPaymentTracking, setReloadPaymentTracking] = useState(false);

  const fetchData = async () => {
    const paymentTrackingData = await paymentTracking({
      params: {
        month: month
      }
    });
    setIncomeGoals(paymentTrackingData.data.income_goals);
    setExpenseGoals(paymentTrackingData.data.expense_goals);
    setExpenseItems(paymentTrackingData.data.expense_items);
    setTrusteePayment(paymentTrackingData.data.trustee_payment);
    setExpenseGoal(paymentTrackingData.data.expense_goal);
    setMonths(paymentTrackingData.data.months_in_payment_tracking);
  };

  const fetchTotalExpenseGoal = async () => {
    const totalExpenseGoal = await getTotalExpenseGoal();
    setExpenseGoal(totalExpenseGoal.data.expense_goal);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (month) {
      const monthJS = DateTime.fromISO(month).startOf('month');
      if(monthJS === DateTime.now().startOf('month')) {
        setMonth(null);
      }
      fetchData();
    }
  }, [month]);

  useEffect(() => {
    if (reloadPaymentTracking) {
      fetchData();
      setReloadPaymentTracking(false);
    }
  }, [reloadPaymentTracking]);

  useEffect(() => {
    fetchTotalExpenseGoal();
  }, [expenseItems]);

  return (
    <TrackSuccessContext.Provider
      value={{
        incomeGoals,
        expenseGoals,
        expenseItems,
        trusteePayment,
        expenseGoal,
        setIncomeGoals,
        setExpenseGoals,
        setExpenseItems,
        setTrusteePayment,
        setExpenseGoal,
        setReloadPaymentTracking,
        setMonth,
        month,
        months
      }}>
      <div className="flex justify-center items-center gap-4 w-full">
        <div className="flex flex-col gap-4 desktop:p-8 mobile:p-0 desktop:w-full mobile:w-full tablet:w-full">
          <TrackSuccessRoutes />
        </div>
      </div>
    </TrackSuccessContext.Provider>
  );
};

export default TrackSuccess;
