import React, { useState, useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { DateTime } from 'luxon';
import jwt_decode from 'jwt-decode';
import { users as usersApi } from '@helpers/api';
import { SignOut } from '@helpers/SignOut';
import SessionModal from './SessionModal';

const SessionTimeout = ({ token, setToken }) => {
  if (!token) return null;

  const [showPrompt, setShowPrompt] = useState(false);
  const lastActionAt = useRef(DateTime.now());

  const promptTimeout = 60000; // Show the prompt 1 minute before the session timeout

  const timeoutMilliseconds = (tokenMilliseconds) => {
    const decodedJwt = jwt_decode(tokenMilliseconds.split(' ')[1]);
    let seconds = decodedJwt.exp;
    if(decodedJwt.exp >= 2147483647){
      seconds = 2147483647;
    }
    const expirationDate = DateTime.fromSeconds(seconds);
    const timeout = expirationDate?.minus(promptTimeout, 'milliseconds').diffNow('milliseconds').values
      .milliseconds;

    if (timeout > 60000) {
      return timeout;
    } else {
      return 60000;
    }
  };

  const handleOnIdle = () => {
    SignOut({ timedOut: true });
  };

  const handleOnPrompt = () => {
    if (timeoutMilliseconds(token) <= promptTimeout) {
      setShowPrompt(true);
    } else {
      reset();
    }
  };

  const getRefreshToken = () => {
    usersApi.refreshToken({ data: { last_active: lastActionAt.current } }).then(({ data }) => {
      const formattedToken = `Bearer ${data.token}`;
      setToken(formattedToken);
    });
  };

  const autoRefreshToken = () => {
    if (showPrompt) {
      return;
    }
    getRefreshToken();
  };

  const handleReset = () => {
    getRefreshToken();
  };

  const onAction = () => {
    lastActionAt.current = DateTime.now();
  };

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: timeoutMilliseconds(token),
    promptTimeout: promptTimeout,
    onPrompt: handleOnPrompt,
    onIdle: handleOnIdle,
    onAction
  });

  useEffect(() => {
    const timeoutValue = timeoutMilliseconds(token);
    reset();
    setShowPrompt(false);

    if (timeoutValue > 10000) {
      setTimeout(() => {
        autoRefreshToken();
      }, timeoutValue - 5000);
    }
  }, [token]);

  if (!showPrompt) {
    return null;
  }

  return <SessionModal handleClose={handleReset} getRemainingTime={getRemainingTime} />;
};

export default SessionTimeout;