import React from 'react';
import { Link } from 'react-router-dom';

const OptionsFunding = ({ icon = '', text = '', title, description, link, id, onClick }) => {
  return (
    <li className="w-full">
      <Link to={link} id={id} className="w-full" onClick={(event) => onClick(event)}>
        <div className="flex justify-around items-center w-full rounded-xl bg-white desktop:h-[89px] tablet:h-[89px] mobile:h-36 shadow-fundingShadow">
          <div className="flex justify-start items-center gap-3 mx-4 w-full">
            <div className="w-[57px] h-[57px] bg-greyblue text-white font-bold text-link flex items-center justify-center p-7 rounded-full box-border">
              {icon === '' && <span>{text}</span>}
              {icon !== '' && <i className={`${icon} fa-xl`} />}
            </div>
            <div className="">
              <h2 className="font-bold text-achSubTitle text-black">{title}</h2>
              <span className="text-link font-normal text-darkGray">{description}</span>
            </div>
          </div>
          <div className="w-7 bg-seafoam rounded-r-xl flex justify-center items-center h-full">
            <i className="fa-solid fa-chevron-right text-white" />
          </div>
        </div>
      </Link>
    </li>
  );
};

export default OptionsFunding;
