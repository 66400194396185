import React from 'react';
import DataTable from 'react-data-table-component';

const StatementTable = ({ statements, openStatement }) => {
  const columns = [
    {
      id: 'name',
      title: 'Name',
      name: 'Name',
      className: 'name',
      align: 'center',
      sortable: true,
      cell: (record) => {
        return (
          <a
            id={`open_${record.id}`}
            href="#"
            onClick={() => openStatement(record)}
            className="flex justify-center items-center gap-4 hover:underline hover:text-seafoam">
            <i className="fas fa-file" />
            <span>{record.name}</span>
          </a>
        );
      }
    },
    {
      id: 'end_date',
      title: 'End Date',
      name: 'End Date',
      className: 'end_date',
      sortable: true,
      align: 'center',
      selector: row => row.end_date
    },
    {
      id: 'download',
      title: 'Download',
      name: 'Download',
      className: 'download',
      align: 'center',
      sortable: false,
      cell: (record) => {
        return (
          <button
            type="button"
            id={`${record.downloadId}`}
            className="rounded-full border border-solid border-black py-3 px-4 h-10 text-sm font-bold text-black flex justify-center items-center gap-2 hover:bg-seafoam hover:text-white hover:border-white transition-all"
            onClick={() => openStatement(record, true)}>
            <span className="hidden sm:block">Download</span> {/* Only show "Download" on screens sm and up */}
            <i className="fa-light fa-download font-bold text-lg" /> {/* Adjusted icon size for mobile */}
          </button>
        );
      }      
    }
  ];

  const customStyle = {
    headCells: {
      style: {
        fontSize: window.innerWidth <= 450 ? '14px' : '18px', // 14px for mobile, 18px for larger screens
        fontWeight: 'bold',
        textAlign: 'center'
      }
    }
  };  

  return (
    <div id='statements-table'>
      {statements.length > 0 && (
        <DataTable columns={columns} data={statements} className="table-statement" customStyles={customStyle} />
      )}
    </div>
  );
};

export default StatementTable;
