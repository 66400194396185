import React from 'react';

const ProgressBar = ({ steps, currentStep }) => {
  const lengthSteps = steps.length;
  const stepFind = steps.find(({ step }) => step === currentStep)?.id;

  const stepsList = steps.map(({ title, id }) => {
    return (
      <li className={`step ${id <= stepFind ? 'active' : ''}`} key={id}>
        <div className="content">
          <div className={`group ${id === 1 && 'first'} ${id === lengthSteps && 'last'}`}>
            <div className="circle">{id}</div>
          </div>
          <h3>{title}</h3>
        </div>
      </li>
    );
  });

  return (
    <ul id="progress" className="progressbar">
      {stepsList}
    </ul>
  );
};

export default ProgressBar;
