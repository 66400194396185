import React, { useContext } from 'react';
import { TrackSuccessContext } from '@contexts';
import { Box, Paper, Alert } from '@mui/material';
import TableExpenses from '../Shared/TableExpenses';
import { deleteExpenseGoal } from '@helpers/api/tracker';
import BreadCrumbComponent from '../Shared/BreadCrumbComponent';

const headCells = [
  {
    id: 'category',
    label: 'Category'
  },
  {
    id: 'amount',
    label: 'Amount'
  },
  {
    id: '',
    label: 'Actions'
  }
];

const ExpenseGoals = () => {
  const { expenseGoals } = useContext(TrackSuccessContext);

  return (
    <>
      <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center my-4">
        Expense Goals
      </h1>
      <BreadCrumbComponent />
      <Box sx={{ width: '100%' }}>
        <Alert severity="info">
          Make sure goals are up to date for what you plan to spend this month.
        </Alert>
        <Paper sx={{ width: '100%', my: 2, p: 1 }}>
          <TableExpenses
            headCells={headCells}
            rows={expenseGoals}
            title={'This Month Goals'}
            actions={true}
            deleteApi={deleteExpenseGoal}
            back
          />
        </Paper>
      </Box>
    </>
  );
};

export default ExpenseGoals;
