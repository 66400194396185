import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RppsBillerForm from '@components/RppsBillerForm';
import PaperBillerForm from '@components/PaperBillerForm';
import PrivateRoute from '../../routes/PrivateRoute';
import BillerList from './BillerList';

const BillerRoutes = ({ userFeatures }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <BillerList userFeatures={userFeatures} />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-biller"
        element={
          <PrivateRoute>
            <RppsBillerForm
              userFeatures={userFeatures}
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-paper-biller"
        element={
          <PrivateRoute>
            <PaperBillerForm />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default BillerRoutes;
