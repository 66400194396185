import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import Loader from '@components/Loader';
import { notifications as notificationsApi } from '@helpers/api';

const Notifications = ({ setIsOpen }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(true);
    notificationsApi
      .getIndex()
      .then((response) => {
        setNotifications(response.data.notifications);
      })
      .catch((serverErrors) => {
        setErrors(serverErrors);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const notficationsGroup = _.chain(notifications)
    .groupBy('timestamp')
    .map((value, key) => ({
      timestamp: key,
      date: DateTime.fromISO(key),
      notificationGroup: value
    }))
    .value();

  const dateShow = (date) => {
    if (date.toFormat('yyyy-MM-dd') === DateTime.now().toFormat('yyyy-MM-dd')) return 'Today';
    if (date.toFormat('yyyy-MM-dd') === DateTime.now().plus({ day: -1 }).toFormat('yyyy-MM-dd'))
      return 'Yesterday';
    return date.toFormat('yyyy-MM-dd');
  };

  const noActionNotification = (notification) => (
    notification.read && !notification.url
  )

  const handleClick = (notification) => {
    if (noActionNotification(notification)) { return }

    notificationsApi
      .putUpdate(notification.id)
      .then(() => {
        const newState = notifications.map((edit) => {
          if (edit.id === notification.id && !edit.read) {
            return { ...edit, read: true };
          }
          return edit;
        });
        setNotifications(newState);
      })
      .catch((serverErrors) => {
        setErrors(serverErrors);
      })
      .finally(() => {
        if (notification.url) {
          window.open(notification.url, '_blank')
        }
      });
  };

  return (
    <div className="absolute right-0 z-10 p-10 w-96 h-auto min-h-full bg-white">
      <div className="flex flex-col gap-8 justify-start items-start w-full">
        <div className="flex justify-between items-center w-full text-greyblue">
          <h2 className="font-bold text-notificationTitle">Notifications</h2>
          <i
            className="fas fa-xmark fa-2x text-greyblue cursor-pointer"
            onClick={() => handleClose()}
          />
        </div>

        {loading && <Loader />}
        {errors.length > 0 && <div>An error occurred</div>}
        {notifications.length === 0 && <div>You have no notifications</div>}

        {notficationsGroup.map((group) => (
          <div key={group.timestamp} className="w-full flex flex-col gap-4">
            <div className="text-dashBoardFooterTablet font-semibold text-black">
              {dateShow(group.date)}
            </div>

            {group.notificationGroup.map((notification) => (
              <div
                className={`flex flex-col gap-2 justify-start items-start ${
                  noActionNotification(notification) ? null : 'cursor-pointer'
                }`}
                key={notification.id}
                id={`notification-${notification.id}`}
                onClick={() => handleClick(notification)}>
                <span
                  className={`flex justify-start items-center gap-2 text-footerElement ${
                    !notification.read ? 'text-teal' : 'text-black'
                  }`}>
                  {!notification.read && <i className="fas fa-circle" />} {notification.title}
                </span>
                <span className="text-dashBoardFooterTablet font-medium text-black">
                  {DateTime.fromISO(notification.timestamp).toLocaleString(DateTime.DATETIME_MED)}
                </span>
                <hr className="dashed" />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
