/* eslint-disable no-console */
import React, { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import ValidatedInput from '@components/ValidatedInput';
import RegionSelect from '@components/RegionSelect';
import ScaleLoader from '@components/ScaleLoader';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { searchBillers as searchBillersApi, billers as billersApi } from '@helpers/api';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { debounce } from 'lodash';

const NO_RESULTS_STRING = 'No results found';

const RppsBillerForm = ({ userFeatures }) => {
  const [searchParam, setSearchParam] = useState('');
  const [searchState, setSearchState] = useState('');
  const [loading, setLoading] = useState(false);
  const [billers, setBillers] = useState([]);
  const [billerAccountPattern, setBillerAccountPattern] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [customCategory, setCustomCategory] = useState('');
  const [noBillerFound, setNoBillerFound] = useState(false);

  const navigate = useNavigate();
  const swalPopUp = withReactContent(Swal);

  const fetchData = debounce((query) => {
    if (!query || query === '') {
      setBillers([]);
      setSearchParam('');
      return;
    }

    setLoading(true);

    searchBillersApi
      .post({ data: { search_params: { state: searchState, search_text: query } } })
      .then(({ data }) => {
        if (
          data.response_data?.table?.billers[0].biller_name === NO_RESULTS_STRING &&
          userFeatures.paperBillPay
        ) {
          setNoBillerFound(true);
        } else {
          setNoBillerFound(false);
          setBillers(data.response_data?.table?.billers || []);
        }
      })
      .catch(async (error) => {
        await swalPopUp.fire({ icon: 'error', title: 'Unable to load list of billers' });
      });
  }, 500);

  const handleBillerSubmit = (values) => {
    if (selectedCategory === 'other') {
      values.biller_category = customCategory;
    } else {
      values.biller_category = selectedCategory;
    }

    setLoading(true);
    billersApi
      .addRppsBiller({ biller: values })
      .then(() => {
        navigate('/dashboard/paybills');
      })
      .catch(async () => {
        const accountNoPatterns = billerAccountPattern.join(' or ');
        await swalPopUp.fire({
          icon: 'error',
          title: 'Unable to Add a new bill.',
          html: `Make sure the account number entered follows the following format <br /><strong>${accountNoPatterns}</strong>`
        });
      })
      .finally(() => setLoading(false));
  };

  const searchResultClickHandler = (event, billerResult, setFieldValue) => {
    event.preventDefault();

    if (billerResult.biller_name === NO_RESULTS_STRING) {
      return;
    }

    setSearchParam(billerResult.biller_name);
    setBillerAccountPattern(billerResult.biller_account_no_patterns);
    setBillers([]);
    setFieldValue('rpps_biller_id', billerResult.rpps_biller_id);
  };

  const billerLabel = (biller) => {
    if (biller.biller_address1) {
      return `${biller.biller_name} ${biller.biller_address1}, ${biller.biller_city}`;
    } else {
      return biller.biller_name;
    }
  };

  const handleCategoryOptionChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);

    if (selectedValue !== 'other') {
      setCustomCategory('');
    }
  };

  const handleCustomCategoryChange = (e) => {
    setCustomCategory(e.target.value);
  };

  const categoryOptions = [
    { label: 'Utilities', value: 'Utilities' },
    { label: 'Housing', value: 'Housing' },
    { label: 'Communication', value: 'Communication' },
    { label: 'Transportation', value: 'Transportation' },
    { label: 'Personal finance', value: 'Personal finance' },
    { label: 'Insurance', value: 'Insurance' },
    { label: 'Subscriptions', value: 'Subscriptions' },
    { label: 'Education', value: 'Education' },
    { label: 'Health and wellness', value: 'Health and wellness' },
    { label: 'Miscellaneous', value: 'Miscellaneous' },
    { label: 'Other', value: 'other' }
  ];

  const onInputChange = (event, value, reason) => {
    if (value) {
      fetchData(value);
    } else {
      setBillers([]);
    }
  };

  return (
    <>
      <h2 className="text-bannerTitle font-bold">Add a new bill</h2>
      <div className="flex flex-col justify-center items-center">
        <span className="ml-4">
          <ScaleLoader loading={loading} height={20} color="green" />
        </span>
        <div className="flex justify-center items-center w-full">
          <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
            <div className="relative z-0 group">
              <Formik
                onSubmit={(values) => {
                  fetchData(values);
                }}
                initialValues={{ state: '', search_text: searchParam }}
                enableReinitialize={true}>
                {({ errors }) => (
                  <Form className="flex flex-col items-center justify-center">
                    <RegionSelect
                      name="state"
                      value={''}
                      label="Select State"
                      onChange={(event) => setSearchState(event.target.value)}
                      errors={errors}
                    />
                  </Form>
                )}
              </Formik>

              <Formik
                initialValues={{ biller_account_number: '' }}
                onSubmit={(values, { setSubmitting }) => {
                  handleBillerSubmit(values);
                  setSubmitting(false);
                }}>
                {({ errors, values, handleChange, setFieldValue }) => (
                  <div className="flex flex-col justify-center items-center relative">
                    <Form className="flex flex-col items-center justify-center">
                      <Autocomplete
                        disablePortal
                        id="searchParam"
                        options={billers}
                        className=""
                        filterOptions={(options) => options}
                        getOptionLabel={(option) => billerLabel(option)}
                        onInputChange={onInputChange}
                        onChange={(event, value) =>
                          searchResultClickHandler(event, value, setFieldValue)
                        }
                        sx={{
                          width: '80%',
                          marginBottom: '1.5rem',
                          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            border: '0'
                          },
                          '& .MuiAutocomplete-inputRoot': {
                            backgroundColor: '#F5F7F9',
                            borderRadius: '0.375rem',
                            fontFamily: 'Montserrat'
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Merchants"
                            variant="outlined"
                            required
                          />
                        )}
                        renderOption={(props, option, state, ownerState) => (
                          <Box component="li" {...props}>
                            {ownerState.getOptionLabel(option)}
                          </Box>
                        )}
                      />

                      <ValidatedInput
                        type="text"
                        name="biller_account_number"
                        placeholder="Biller Account Number"
                        errors={errors}
                        value={values.biller_account_number}
                        onChange={handleChange}
                      />
                      <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
                        <div className="relative z-0 group">
                          <select
                            required={true}
                            value={selectedCategory}
                            name="category"
                            onChange={handleCategoryOptionChange}
                            className="border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray">
                            <option value="" disabled>
                              Select Category
                            </option>
                            {categoryOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {selectedCategory === 'other' && (
                        <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
                          <div className="relative z-0 group">
                            <input
                              type="text"
                              id="customCategory"
                              name="category"
                              className="border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray valid"
                              value={customCategory}
                              onChange={handleCustomCategoryChange}
                              placeholder="Category"
                            />
                          </div>
                        </div>
                      )}
                      {noBillerFound && (
                        <div className="bg-seafoam p-5 mb-5 flex flex-col justify-center items-center gap-4">
                          <div className="text-white text-center">
                            No biller was found with that search criteria. You can add a biller
                            manually for paper check payments.
                          </div>
                          <Link
                            to="/dashboard/paybills/add-paper-biller/"
                            className="w-[315px] bg-greyblue hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-4">
                            Add Paper Biller
                          </Link>
                        </div>
                      )}
                      <div className="flex mobile:flex-col justify-center items-center gap-4">
                        <input
                          id="submit"
                          type="submit"
                          value="Add bill"
                          className="w-[315px] bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer"
                        />
                        <Link
                          to="/dashboard/paybills/"
                          className="w-[315px] bg-seafoam hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-4">
                          Return to bills and payments
                        </Link>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RppsBillerForm;
