import React, { useEffect, useState } from 'react';
import GetStarted from './Cards/GetStarted';
import Loader from '../Loader';
import {
  getIndexExpenseGoals,
  getIndexIncomeGoals,
} from '@helpers/api/tracker';
import { useNavigate } from 'react-router-dom';

const GetStartedComponent = () => {
  const [getStarted, setGetStarted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [expenseGoals, setExpenseGoals] = useState();
  const [incomeGoals, setIncomeGoals] = useState();
  const [trusteePayment, setTrusteePayment] = useState();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const resultExpenseGoals = await getIndexExpenseGoals();
      const dataExpenseGoals = resultExpenseGoals.data.expense_goals;
      const dataTrusteePayment = resultExpenseGoals.data.trustee_payment;
      setExpenseGoals(dataExpenseGoals);
      setTrusteePayment(dataTrusteePayment);

      const resultIncomeGoals = await getIndexIncomeGoals();
      const dataIncomeGoals = resultIncomeGoals.data;
      setIncomeGoals(dataIncomeGoals.income_goals);
      if (
        dataExpenseGoals.length === 0 ||
        dataTrusteePayment ||
        dataIncomeGoals.income_goals.length === 0
      ) {
        setGetStarted(true);
      } else {
        setGetStarted(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (!getStarted) {
      navigate('/dashboard/tracker/track-success');
    }
  }, [getStarted]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <GetStarted
      setGetStarted={setGetStarted}
      expenseGoals={expenseGoals}
      incomeGoals={incomeGoals}
      trusteePayment={trusteePayment}
      setTrusteePayment={setTrusteePayment}
      setExpenseGoals={setExpenseGoals}
      setIncomeGoals={setIncomeGoals}
    />
  );
};

export default GetStartedComponent;
