import React, { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AchAccountVerificationStatus from './AchAccountVerificationStatus';
import { achAccounts as achAccountsApi } from '@helpers/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SessionContext } from '@contexts';

const AchAccountItem = ({ handleClickListAccounts, achAccount, currentAchAccount }) => {
  const { pathname } = useLocation();
  const isCurrentAchAccount = achAccount.id === currentAchAccount?.id;
  const swalPopUp = withReactContent(Swal);
  const { user } = useContext(SessionContext);

  const onDeleteAchAccount = useCallback((achAccountId) => {
    swalPopUp
      .fire({
        title: 'Are you sure you want to remove this account?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      })
      .then((result) => {
        if (result.isConfirmed) {
          achAccountsApi
            .deleteById(achAccountId)
            .then(() => {
              window.location.reload();
            });
        }
      })
  });

  const DeleteButton = ({ onClick }) => {
    if (isCurrentAchAccount) return null;

    return (
      <button type="button" onClick={onClick} className="">
        <i className="fa-solid fa-x text-red" />
      </button>
    );
  };

  return (
    <div
      className={`flex flex-row items_account desktop:w-[412px] tablet:w-[412px] mobile:w-[315px] ${
        isCurrentAchAccount
          ? 'bg-seafoam border-teal'
          : 'bg-whiteLighter border-whiteGray hover:bg-whiteGray'
      } border border-solid rounded-2xl justify-start items-center cursor-pointer`}>
      <div
        id={`ach_account_${achAccount.id}`}
        onClick={() => handleClickListAccounts(achAccount)}>
        <div
          className={`flex justify-start items-center text-center gap-6 w-96 p-7`}>
          <i
            className={`fa-solid fa-file-invoice-dollar fa-2x ${
              isCurrentAchAccount ? 'text-white' : 'text-seafoam'
            }`}
          />
          <div
            className={`${
              achAccount.id === currentAchAccount?.id ? 'text-white' : 'text-black'
            } font-descriptionSlide font-bold`}>
            <span className="font-normal">{achAccount.name}</span>
          </div>
          {!achAccount.verified &&
            AchAccountVerificationStatus({ achAccount, isCurrentAchAccount })}
        </div>
      </div>
      {user.features.delete_ach_accounts && <DeleteButton onClick={ () => onDeleteAchAccount(achAccount.id) } />}
    </div>
  );
};

export default AchAccountItem;
