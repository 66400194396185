import { get, remove, post, put } from './httpBase';

const path = '/api/user';
const pathSignOut = '/users/sign_out';
const passwordResetPath = 'users/password';

/**
 * This is where we define the external facing interface. This call to `get`
 * from httpBase will return a function that accepts the keyword arguments
 * `data` and `options`, and returns a promise.
 *
 * Ideally, any function that you export from this point forward should conform
 * to that contract for the ease of reasoning about the code.
 */
export const getShow = get(path);
export const deleteUser = remove(pathSignOut);
export const enableMFA = post(path + '/enable_mfa');
export const disableMFA = post(path + '/disable_mfa');
export const generateBackupCodes = post(path + '/generate_backup_codes');
export const passwordReset = post(passwordResetPath);
export const updatePassword = put(passwordResetPath);
export const refreshToken = post('/api/refresh_token');
export const login = post('/users/sign_in');
