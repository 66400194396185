import React, { useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { billPayments as billPaymentsApi } from '@helpers/api';
import ModalBillerList from './ModalBillerList';

const EditReportButton = ({ selectedDateRange, billers }) => {
  const selectedBillersRef = useRef([]);
  const swalPopUp = withReactContent(Swal);

  const config = {
    show_filter: false,
    show_length_menu: false,
    pagination: false,
    show_info: false,
    key_column: 'id'
  };

  const showPopup = () => {
    swalPopUp.fire({
      html: (
        <ModalBillerList
          config={config}
          billers={billers}
          selectedBillersRef={selectedBillersRef}
        />
      ),
      title: 'Select Billers For Report',
      showConfirmButton: true,
      showCancelButton: true,
      background: '#fff',
      position: 'center',
      customClass: {
        htmlContainer: 'container_modal',
        confirmButton: 'btn_submit',
        cancelButton: 'btn_danger',
        popup: 'popUp_modal',
        closeButton: 'close_button'
      },
      confirmButtonText: 'Download Report',
      buttonsStyling: false,
      allowOutsideClick: false,
      preConfirm: () => {
        handleConfirm();
      }
    });
  };

  const handleConfirm = () => {
    const startDate = selectedDateRange[0].startDate;
    const endDate = selectedDateRange[0].endDate;

    billPaymentsApi
      .getDownloadReport(startDate, endDate, selectedBillersRef.current)
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        const downloadLink = document.createElement('a');
        downloadLink.href = fileUrl;
        downloadLink.download = `Bill-Payment-Report-${startDate}-${endDate}.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!'
        });
      });
  };

  return (
    <div className="flex flex-col justify-start items-start gap-8">
      <button
        onClick={showPopup}
        type="button"
        className="w-auto p-3 bg-teal hover:bg-seafoam rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7 flex justify-center items-center gap-4">
        <i className="fas fa-circle-plus" />
        <span className="text-white text-link">Create Billpay Report</span>
      </button>
    </div>
  );
};

export default EditReportButton;
