import React from 'react';

export const BillerInformation = ({ biller }) => {
  return (
    <div className="flex justify-evenly items-start mobile:flex-col w-full">
      <div>
        <p>
          <strong>Account Number:</strong> {biller.biller_account_number}
        </p>
        <p>
          <strong>Name:</strong> {biller.biller_name}
        </p>
        <p>
          <strong>Address 1:</strong> {biller.biller_address_1}
        </p>
        <p>
          <strong>Address 2:</strong> {biller.biller_address_2}
        </p>
        <p>
          <strong>City:</strong> {biller.biller_city}
        </p>
      </div>
      <div>
        <p>
          <strong>Zip:</strong> {biller.biller_zip}
        </p>
        <p>
          <strong>Phone:</strong> {biller.biller_phone}
        </p>
        <p>
          <strong>Category:</strong> {biller.biller_category}
        </p>
      </div>
    </div>
  );
};
