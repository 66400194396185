// This file is not being used until we have the data to populate these fields

import React from 'react';

const TransactionDropdown = ({ timestamp, formatDate, amount }) => {
  return (
    <div className="flex desktop:flex-row tablet:flex-col mobile:flex-col p-5 justify-between items-center rounded-xl bg-whiteLighter w-full h-full desktop:gap-20 tablet:gap-8 mobile:gap-4">
      <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col desktop:justify-around tablet:justify-around mobile:justify-start desktop:items-center tablet:items-center mobile:items-start w-full mobile:gap-4 desktop:gap-0 tablet:gap-0">
        <div className="flex justify-start items-center gap-4">
          <i className="fa-solid fa-store text-greyblue" />
          <span className="text-darkGray text-footerElement font-normal">Merchant name</span>
        </div>
        <div className="flex justify-start items-center gap-4">
          <i className="fa-solid fa-globe text-greyblue" />
          <span className="text-darkGray text-footerElement font-normal">Category</span>
        </div>
      </div>
      <div className="flex desktop:flex-row tablet:flex-row mobile:flex-col justify-around w-full mobile:gap-4 desktop:gap-0 tablet:gap-0">
        <div className="flex desktop:justify-center tablet:justify-start mobile:justify-start items-center gap-4 desktop:w-full tablet:w-1/2 mobile:w-full">
          <i className="fa-light fa-money-bill text-greyblue" />
          <span className="text-darkGray text-footerElement font-normal">ATM Withdrawal</span>
        </div>
        <div className="flex justify-around items-center w-full">
          <div className="flex desktop:justify-center tablet:justify-center mobile:justify-start items-center gap-4 w-full">
            <span className="fa-stack flex-shrink-0">
              <i
                className={`fas fa-circle fa-stack-2x ${amount < 0 ? 'text-red' : 'text-seafoam'} `}
              />
              <i
                className={`far ${
                  amount < 0 ? 'fa-arrow-down-left' : 'fa-arrow-up-right'
                } fa-stack-1x fa-inverse text-whiteLighter`}
              />
            </span>
            <span className="text-darkGray text-footerElement font-normal">Debit</span>
          </div>

          <div className="desktop:hidden tablet:flex mobile:flex justify-start items-center gap-4 w-full">
            <i className="fa-light fa-calendar" />
            <span className="date text-dashBoardFooterTablet font-normal">
              {formatDate(timestamp)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDropdown;
