import React from 'react';
import { useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const path = pathname.replace('/dashboard', '');

  const breadcrumbs = path.split('/').map((crumb, index, row) => {
    const crumbName = crumb.replace(/-/g, ' ');
    if (crumbName !== '' && row.length > 2) {
      return (
        <li
          key={index}
          className={`capitalize flex justify-center items-center gap-2 text-titleSlide font-bold ${
            index + 1 === row.length ? 'text-teal' : 'text-black'
          }`}>
          <span>{crumbName}</span>
          <i className={`${index + 1 === row.length ? 'hidden' : ''} fas fa-chevron-right`} />
        </li>
      );
    }
    return '';
  });

  return (
    <div>
      <ul className="flex justify-center items-center gap-4">{breadcrumbs}</ul>
    </div>
  );
};

export default Breadcrumbs;
