import React, { useContext } from 'react';
import { AccountContext } from '../../contexts';
import { useFormikContext } from 'formik';

import { DateTime } from 'luxon';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

const CheckPreview = () => {
  const { account } = useContext(AccountContext);
  const { values } = useFormikContext();

  return (
    <div className="flex flex-col justify-start w-full p-5 mobile:hidden">
      <h2 className="text-bannerTitle font-bold text-black mb-6 text-center">Check Preview</h2>
      <div className="container-check flex justify-center items-center">
        <div className="desktop:w-3/5 tablet:w-full h-auto rounded-lg border-2 bg-whiteGray p-4 flex flex-col justify-start shadow-sm">
          <div className="w-full flex justify-between px-4 pb-2">
            <div>
              <strong>Account:</strong> {values.biller_account_number}
            </div>
            <div>001234567</div>
          </div>
          <hr className="border-1 border-black" />
          <div className="my-8">
            <div className="w-full flex justify-between">
              <div className="flex flex-col text-sm px-8">
                &nbsp;
              </div>
              <div className="px-8">
                <span className="border-black border-b w-full px-10 py-2">
                  {DateTime.now().toLocaleString(DateTime.DATE_FULL)}
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-between p-4 items-center">
            <span className="border-b border-black py-2">
              XXXXXXXXXXXXXXXXXXXXXXXXXX AND XX/100
            </span>
            <span>
              <AttachMoneyIcon sx={{ fontWeight: 'bold' }} /> ******XXXX.XX
            </span>
          </div>
          <div className="flex justify-between p-4">
            <div className="flex justify-start items-start gap-4 w-2/5">
              <span className="w-1/4 text-center font-bold">PAY TO THE ORDER OF</span>
              <div className="w-3/4 flex flex-col justify-center items-start">
                <span>{values.biller_name.toUpperCase()}</span>
                <span>
                  {values.biller_address_1.toUpperCase()}{' '}
                  {values.biller_address_2 ? `, ${values.biller_address_2.toUpperCase()}` : ''}
                </span>
                {values.biller_city && (
                  <span>
                    {values.biller_city.toUpperCase()}, {values.biller_state.toUpperCase()}{' '}
                    {values.biller_zip}
                  </span>
                )}
              </div>
            </div>
            <div className="w-2/5 flex flex-col justify-center items-center border-b border-solid border-black">
              <HistoryEduIcon fontSize="large" />
							<span>{account.first_name.toUpperCase()} {account.last_name.toUpperCase()}</span>
            </div>
          </div>
          <div className="flex justify-between px-4 pt-4 border-t border-black mt-4">
            <span>{account.galileo_payment_reference_number || 1231321231321}</span>
            <span>273070278</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckPreview;
