import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import EditReportButton from './EditReportButton';

const formatDate = (date) => {
  return date.toLocaleDateString();
};

const currentDate = new Date();
const startDate = new Date();
startDate.setMonth(currentDate.getMonth() - 6);
const endDate = new Date();

const ReportDateRangeForm = ({ billers }) => {
  const datePickerRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [disableDownload, setDisableDownload] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: 'selection'
    }
  ]);

  const [inputValue, setInputValue] = useState(
    `${formatDate(startDate)} - ${formatDate(endDate)}`
  );

  const handleDocumentClick = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  const handleDateRangeChange = (ranges) => {
    setDisableDownload(false);
    setSelectedDateRange([ranges.selection]);
    setInputValue(
      `${formatDate(ranges.selection.startDate)} - ${formatDate(ranges.selection.endDate)}`
    );
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const [start, end] = inputValue.split(' - ');
    setSelectedDateRange([
      {
        startDate: new Date(start),
        endDate: new Date(end),
        key: 'selection'
      }
    ]);
    setShowCalendar(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-1">
      <div
        ref={datePickerRef}
        className="w-auto p-3 bg-teal hover:bg-seafoam rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer mt-7 flex justify-center items-center gap-4">
        <i className="far fa-calendar text-white fa-lg" />
        <div className="flex justify-start items-center text-white gap-3 text-headerBalance font-bold">
          <span className="bg-white p-1 desktop:w-[216px] tablet:w-auto h-[35px] text-teal text-titleSlide font-normal">
            <div className="date-range-form">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  onFocus={toggleCalendar}
                  readOnly={!showCalendar}
                  placeholder="Select Dates"
                />
                {showCalendar && (
                  <div className="calendar-wrapper">
                    <DateRangePicker
                      ranges={selectedDateRange}
                      onChange={handleDateRangeChange}
                      onBlur={handleSubmit}
                      showMonthAndYearPickers={true}
                      initialFocusedRange={[0, 1]}
                    />
                  </div>
                )}
              </form>
            </div>
          </span>
        </div>
      </div>
      <EditReportButton billers={billers} selectedDateRange={selectedDateRange} />
    </div>
  );
};

export default ReportDateRangeForm;