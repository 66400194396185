import React, { useContext } from 'react';
import { Box, Paper } from '@mui/material';
import { TrackSuccessContext } from '@contexts';
import { MonthSelector } from '../Shared/MonthSelector';
import BreadCrumbComponent from '../Shared/BreadCrumbComponent';
import IncomeGoalsComponent from '../GetStartedSteps/IncomeGoalsComponent';

const IncomeGoals = () => {
  const { incomeGoals, setIncomeGoals, trusteePayment, month } = useContext(TrackSuccessContext);
  return (
    <Box sx={{ width: '100%' }}>
      <MonthSelector>
        <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center my-4">
          Income Goals
        </h1>
      </MonthSelector>
      <BreadCrumbComponent />
      <Paper elevation={1} sx={{ p: 3, mt: 1 }}>
        <IncomeGoalsComponent
          incomeGoals={incomeGoals}
          setIncomeGoals={setIncomeGoals}
          headingText="Add or update any income you expect to receive this month"
          trusteePayment={trusteePayment}
          date={month}
        />
      </Paper>
    </Box>
  );
};

export default IncomeGoals;
