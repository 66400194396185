import React, { useState, useEffect, useRef } from 'react';
import { DateRange } from 'react-date-range';
import { DateTime } from 'luxon';

const DateRangeInput = ({ setDateRange, dateRange, minDate }) => {
  const ref = useRef(null);
  const [onToggle, setOnToggle] = useState(false);
  const [selection, setSelection] = useState([
    {
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      key: 'selection'
    }
  ]);
  const maxDate = DateTime.local().toJSDate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOnToggle(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleSearch = () => {
    setDateRange({ startDate: selection[0].startDate, endDate: selection[0].endDate });
    setOnToggle(false);
  };

  const handleClear = () => {
    setDateRange({ startDate: null, endDate: null });
  };

  return (
    <div className="flex flex-col gap-2">
      <input
        type="button"
        value="Filter by date range"
        className={`border px-5 py-3 border-solid ${
          onToggle
            ? 'bg-greyblue border-greyBlueDisabled text-white'
            : 'bg-whiteLighter border-whiteGray'
        } rounded-md block mt-0 focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray selectOptions`}
        onClick={() => setOnToggle(true)}
      />

      {onToggle && (
        <div
          className="absolute flex flex-col gap-2 desktop:right-32 tablet:right-32 mobile:right-0 p-4 bg-whiteLighter border border-solid border-whiteLighter rounded-3xl z-10"
          ref={ref}>
          <DateRange
            editableDateInputs={false}
            onChange={(item) => setSelection([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={selection}
            className="border"
            minDate={minDate.toJSDate()}
            maxDate={maxDate}
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
          />
          <div className="flex gap-4 justify-center items-center">
            <input
              type="button"
              value="Search..."
              onClick={() => handleSearch()}
              className="bg-seafoam hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer w-full"
            />
            <input
              type="button"
              value="Clear"
              onClick={() => handleClear()}
              className="bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer w-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeInput;
