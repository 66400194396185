/* eslint-disable no-console */
import React, { useState, useContext, useCallback } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { accounts as accountsApi } from '@helpers/api';
import { AccountContext } from '../../contexts';

const useEarlyAch = (setAccountEnrollment) => {
  const [loading, setLoading] = useState(false);

  const { setAccount } = setAccountEnrollment
    ? { setAccount: setAccountEnrollment }
    : useContext(AccountContext);

  const swalPopUp = withReactContent(Swal);

  const enable = useCallback(() => {
    swalPopUp
      .fire({
        title: 'Enable early access ACH',
        html: 'Please confirm you would like to enroll in early access to ACH.',
        footer:
          '<small class="text-center">Early access is not available for deposits funded by ACH debit of an external bank account.</small>',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      })
      .then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          accountsApi
            .enableEarlyAch()
            .then((response) => {
              setAccount(response.data.account);
            })
            .catch(() => {
              swalPopUp.fire({
                position: 'center',
                icon: 'error',
                title: 'There was an error enabling early ACH.',
                showConfirmButton: false,
                timer: 2000
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
  });

  const disable = useCallback(() => {
    swalPopUp
      .fire({
        title: 'Disable early access ACH',
        html: 'You will no longer receive direct deposit funds up to two days early. Are you sure you would like to disable early ACH?',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      })
      .then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          accountsApi
            .disableEarlyAch()
            .then((response) => {
              setAccount(response.data.account);
            })
            .catch(() => {
              swalPopUp.fire({
                position: 'center',
                icon: 'error',
                title: 'There was an error disabling early ACH.',
                showConfirmButton: false,
                timer: 1500
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
  });

  return {
    loading,
    enable,
    disable
  };
};

const AchToggle = ({ accountEnrollment, setAccount }) => {
  const { account } = accountEnrollment
    ? { account: accountEnrollment }
    : useContext(AccountContext);
  const { disable, enable, loading } = useEarlyAch(setAccount);

  return (
    <div className="flex justify-center items-center gap-6 border border-solid border-whiteGray p-4 rounded-lg w-full">
      <label
        htmlFor="earlyAch"
        name="earlyAchLabel"
        id="earlyAchLabel"
        className="w-full flex justify-between items-center">
        <span className="w-full">Want early access to your funds?</span>
        <div className="w-full flex justify-end items-center">
          <span className="switch">
            <input
              id="earlyAch"
              name="earlyAch"
              type="checkbox"
              onChange={account.early_access_ach ? disable : enable}
              disabled={loading}
              checked={account.early_access_ach}
            />
            <span className="slider round" />
          </span>
        </div>
      </label>
    </div>
  );
};

export default AchToggle;
