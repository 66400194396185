import React from 'react';

const ModalSignOut = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-2 h-48">
      <h2 className="text-seafoam desktop:text-titleForm mobile:text-titleForm font-semibold">
        Are you sure you want to sign out?
      </h2>
      <span className="text-darkGray font-normal text-descriptionSlide">
        You can login again any time
      </span>
    </div>
  );
};

export default ModalSignOut;
