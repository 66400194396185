import React from 'react';
import { SuccessTrackerBgImg, SuccessTrackerInfoImg } from '@constants/images';

const SuccessTracker = () => {
  return (
    <div
      className="bg-cover bg-center py-8 px-4 lg:px-16"
      style={{
        backgroundImage: `url(${SuccessTrackerBgImg})`
      }}>
      <div className="max-w-6xl mx-auto bg-white bg-opacity-80 rounded-lg shadow-lg overflow-hidden md:flex">
        <div className="md:w-1/2 p-8 flex justify-center items-center">
          <img
            src={SuccessTrackerInfoImg}
            alt="Success Tracker page on laptop"
            className="rounded-lg shadow-md h-auto"
          />
        </div>
        <div className="md:w-1/2 p-8 flex flex-col justify-center">
          <h1 className="text-3xl font-bold mb-4 text-center">
            Take Control of Your Financial Journey with Success Tracker
          </h1>
          <p className="mb-8">
            The <strong>Success Tracker</strong> is an innovative financial tool designed to help
            you navigate the complexities of Chapter 13 bankruptcy with confidence. It provides a
            clear overview of your spending habits, helping you avoid missed payments and stay
            within your budget. This all-in-one tool empowers you to take control of your financial
            future, offering peace of mind and a clearer path toward discharge.
          </p>
          <ul className="mb-6 space-y-4">
            <li className="flex items-start">
              <span className="text-green-500 mr-2">✔</span>
              <p>
                <strong>Goal Settings</strong>: Allows users to set monthly financial goals,
                encouraging discipline and regular progress monitoring.
              </p>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 mr-2">✔</span>
              <p>
                <strong>Real-time Tracking</strong>: Monitors both plan payments and everyday
                expenses, offering a holistic view of a user’s financial health.
              </p>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 mr-2">✔</span>
              <p>
                <strong>Recognition</strong>: Rewards users for achieving their set goals, providing
                motivation to stay on track.
              </p>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 mr-2">✔</span>
              <p>
                <strong>Financial Progress Insights</strong>: Provides regular updates and reports
                on how users are progressing in managing their financial obligations.
              </p>
            </li>
            <li className="flex items-start">
              <span className="text-green-500 mr-2">✔</span>
              <p>
                <strong>Stay On Track</strong>: The Success Tracker helps ensure you're staying
                compliant with your Chapter 13 repayment plan while managing your daily living
                expenses.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SuccessTracker;
