import React from 'react';
import { NumericFormat as NumberFormat } from 'react-number-format';

const AmountInput = ({ field, label, name, id, value, ...props }) => {
  return (
    <NumberFormat id={id} {...field} {...props} thousandSeparator value={value} />
  );
};

export default AmountInput;
