import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { users as usersApi } from '@helpers/api';
import { SessionContext } from '@contexts';
import ValidatedInput from '@components/ValidatedInput';

const LoginForm = ({topBarShown =  false}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [oneTimePasscode, setOneTimePasscode] = useState('');
  const [promptOtp, setPromptOtp] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { setToken } = useContext(SessionContext);

  const toastConfig = {
    position: 'top-center',
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisableSubmit(true);

    usersApi
      .login({
        data: {
          user: {
            email,
            password,
            one_time_passcode: oneTimePasscode
          }
        }
      })
      .then((response) => {
        if (response.data.prompt_otp) {
          setPromptOtp(true);
        } else {
          toast.success('Login success!', toastConfig);
          setToken(response.headers.authorization);
        }
        setDisableSubmit(false);
      })
      .catch((error) => {
        if (error.response) {
          const { errors } = error.response.data;
          if (error.response.data.prompt_otp) {
            setPromptOtp(true);
            toast.error('MFA Failed', toastConfig);
          } else {
            toast.error(errors ? errors[0] : error.response.data.error, toastConfig);
          }
        } else if (error.request) {
          toast.error("Can't Login", toastConfig);
          console.error(error.request);
        } else {
          toast.error("Can't Login", toastConfig);
          console.error('Error', error.message);
        }
        setDisableSubmit(false);
      });
  };

  useEffect(() => {
    sessionStorage.removeItem('lastTimeStamp');
  }, []);

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col lg:flex-row lg:items-center space-y-4 lg:space-y-0 lg:space-x-4">
          {!promptOtp && (
            <>
              <span className="w-full lg:w-auto h-12">
                <ValidatedInput
                  name="email"
                  placeholder="Email"
                  id={topBarShown ? 'email-topbar' : 'email'}
                  type="email"
                  value={email}
                  onChange={setEmail}
                  icon="fa-at"
                  withFormik={false}
                />
              </span>
              <span className="w-full lg:w-auto h-12">
                <ValidatedInput
                  name="password"
                  placeholder="Password"
                  id={topBarShown ? 'password-topbar' : 'password'}
                  type="password"
                  value={password}
                  onChange={setPassword}
                  icon="fa-lock-keyhole"
                  withFormik={false}
                  className="w-full lg:w-auto h-12"
                />
              </span>
            </>
          )}
          {promptOtp && (
            <span className="w-full lg:w-auto h-12">
              <ValidatedInput
                placeholder="One Time Password"
                id={topBarShown ? 'oneTimePasscode-topbar' : 'oneTimePasscode'}
                type="text"
                value={oneTimePasscode}
                onChange={setOneTimePasscode}
                icon="fa-lock-keyhole"
                withFormik={false}
                className="w-full lg:w-auto h-12"
              />
            </span>
          )}
          <button
            disabled={disableSubmit}
            type="submit"
            style={{
              fontSize: '0.9rem',
            }}
            className="bg-greyblue hover:bg-greyblueHover rounded-md h-12 text-white font-bold px-4 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 w-full lg:w-auto lg:align-middle">
            Login
          </button>
          <a href="https://tfsbillpay.com/tfs_banking/signup" target="_blank" rel="noreferrer noopener">
            <button
              type="button"
              style={{
                fontSize: '0.9rem',
              }}
              className="bg-teal hover:bg-teal rounded-md h-12 text-white font-bold px-4 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 w-full">
              Register Now
            </button>
          </a>
        </div>
        <Link to="/request-reset-password" className="text-xs float-right mt-2 underline">
          Forgot password?
        </Link>
      </form>
    </>
  );
};

export default LoginForm;
