import React from 'react';
import BillerRoutes from '../components/RppsBillerForm/BillerRoutes';

const PayBillsPage = ({userFeatures}) => {
  return (
    <div className="billers-page flex flex-col desktop:px-14 gap-4 mobile:w-screen desktop:w-full tablet:w-full">
      <h1 className="text-dashBoardTopName font-bold text-black">Pay Bills</h1>
      <div className="bg-white rounded-xl py-5 desktop:px-4 tablet:px-4 mobile:px-2">
        <BillerRoutes userFeatures={userFeatures} />
      </div>
    </div>
  );
};

export default PayBillsPage;
