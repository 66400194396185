import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { pinSet as pinSetApi } from '@helpers/api';

const ActivateCard = () => {
  const [pinSetUrl, setPinSetUrl] = useState(null);
  const navigate = useNavigate();
  const swalPopUp = withReactContent(Swal);

  const fireErrorAlert = () => {
    swalPopUp.fire({
      position: 'center',
      icon: 'error',
      title: 'Oh no! Something went wrong. Please try again.',
      showConfirmButton: true
    });
  };

  const fetchPinSetUrl = () => {
    pinSetApi
      .getPinSetUrl()
      .then((response) => {
        setPinSetUrl(response.data.url);
      })
      .catch(() => {
        fireErrorAlert();
      });
  };

  const commitPinSet = () => {
    pinSetApi
      .postPinSetCommit()
      .then(() => {
        swalPopUp
          .fire({
            position: 'center',
            icon: 'success',
            title: 'Your pin has been set and your card is ready for use!',
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            showCancelButton: false
          })
          .then(() => {
            navigate('/dashboard');
          });
      })
      .catch(() => {
        fireErrorAlert();
      });
  };

  useEffect(() => {
    fetchPinSetUrl();
  }, []);

  useEffect(() => {
    const eventListener = (event) => {
      if (event.origin === new URL(pinSetUrl).origin) {
        const statusCode = event.data;
        if (statusCode === 0) {
          commitPinSet();
        }
      }
    };

    if (pinSetUrl !== null) {
      if (window.addEventListener) {
        window.addEventListener('message', eventListener, false);
      }
    }

    return () => window.removeEventListener('message', eventListener, false);
  }, [pinSetUrl]);

  return (
    <div className="flex flex-col desktop:px-14 gap-4 justify-center items-center">
      <p className="text-black text-bannerTitle font-bold">Pin must be 4 digit number</p>
      <iframe src={pinSetUrl} title="Pin Set" style={{ height: '60vh', width: '100%' }} />
    </div>
  );
};

export default ActivateCard;
