import React from 'react';

const FormTitle = ({ children }) => {
  return (
    <h1 className="text-formTitle font-bold mb-5 text-black mobile:text-formTitleResponsive text-center">
      {children}
    </h1>
  );
};

export default FormTitle;
