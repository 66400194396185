import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { TFSRewardsLearnMoreImg } from '../../constants/images';

export default function TFSRewardsLearnMore() {
  const navigate = useNavigate();
  return (
    <div className="tracker-page flex flex-col items-center px-4 desktop:px-14 gap-4 w-full">
      <div className="flex flex-col bg-teal rounded-md p-8 gap-8 w-full">
        <h1 className="title-settings text-dashBoardTopName font-bold text-white mb-8">
          Get Rewarded for Everyday Online Shopping
          <br />
          and Get Cash Back to YourSuccess Account.
          <br />
          Use that to Help Make Your Trustee Payment.
        </h1>
        <div className="flex flex-col desktop:flex-row items-center gap-x-4 w-full">
          <div className="flex flex-col gap-4 text-white w-full desktop:w-2/3">
            <p>
              As a YourSuccess account holder, you have access to an exclusive benefit that helps
              you achieve your Chapter 13 goals. Purchases at thousands of online merchants will
              bring you one step closer to completing your plan payments by depositing cash back
              into YourSuccess Bank Account.
            </p>
            <p>
              You made the responsible financial decision to file Chapter 13. Now decide that every
              dollar counts. Start spending smart and reaping the benefits of this exclusive cash
              reward.
            </p>
          </div>
          <div className="flex justify-center w-full desktop:w-1/3">
            <img src={TFSRewardsLearnMoreImg} alt="Cash Reward" className="rounded-md" />
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <Button
            variant="contained"
            color="primary"
            className="w-96"
            onClick={() => navigate('/dashboard/rewards')}>
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
}
