import React, { useContext, useState, useEffect, useMemo } from 'react';
import CurrencyFormat from 'react-currency-format';

import { achTransactions as achTransactionsApi } from '@helpers/api';
import DataTable from 'react-data-table-component';

import { EnrollmentContext } from '@contexts';
import ErrorsTemplate from '@components/ErrorsTemplate';
import LinkButton from '@components/LinkButton';
import FormTitle from '../FormTitle/FormTitle';

const customStyle = {
  headCells: {
    style: {
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'center'
    }
  }
};

const AchTransactionsList = () => {
  const [achTransactions, setAchTransactions] = useState([]);
  const [errorsState, setErrors] = useState(null);
  const { enrollment } = useContext(EnrollmentContext);

  const basePath = () => {
    if (enrollment) {
      return '/enrollment-funding';
    }
    return '/dashboard/funding';
  };

  const columns = useMemo(() => [
    {
      id: 'amount',
      name: 'Amount',
      className: 'amount',
      align: 'center',
      sortable: true,
      cell: (record) => {
        return (
          <CurrencyFormat
            value={record.amount}
            displayType="text"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
            className="amount text-descriptionSlide"
          />
        );
      }
    },
    {
      id: 'date',
      name: 'Date',
      className: 'date',
      align: 'center',
      sortable: true,
      selector: (row) => row.date
    },
    {
      id: 'status',
      name: 'Status',
      className: 'status',
      align: 'center',
      sortable: true,
      cell: (record) => {
        switch (record) {
          case 'completed':
            return (
              <div className="flex gap-3 justify-center items-center">
                <i className="fas fa-check-double text-teal" />
                <span className="uppercase">{record.status}</span>
              </div>
            );
          case 'pending':
            return (
              <div className="flex gap-3 justify-center items-center">
                <i className="fas fa-exclamation-circle text-yellow" />
                <span className="uppercase">{record.status}</span>
              </div>
            );
          case 'failed':
            return (
              <div className="flex gap-3 justify-center items-center">
                <i className="fas fa-times text-red" />
                <span className="uppercase">{record.status}</span>
              </div>
            );
          default:
            return (
              <div className="flex gap-3 justify-center items-center">
                <i className="fas fa-check-double text-green-500" />
                <span className="uppercase">{record.status}</span>
              </div>
            );
        }
      }
    }
  ]);

  useEffect(() => {
    achTransactionsApi
      .getIndex()
      .then((response) => {
        if (response.data.length > 0) {
          setAchTransactions(response.data);
        } else {
          setAchTransactions([]);
        }
      })
      .catch((errorApi) => {
        setAchTransactions([]);
        setErrors(errorApi.response);
      });
  }, []);

  return (
    <>
      {!!errorsState && <ErrorsTemplate errors={errorsState} />}
      <div className="flex desktop:flex-row tablet:flex-col desktop:w-1/2 tablet:w-full mobile:w-full gap-14 justify-center items-center">
        <div className="w-full shadow-achShadow rounded-3xl desktop:px-8 tablet:px-6 mobile:px-3 pb-8 pt-10 bg-white">
          <FormTitle>ACH Transactions</FormTitle>
          <LinkButton path={`${basePath()}/ach/create-transaction`}>
            <i className="fa-solid fa-arrow-left-long" />
            <span>Linked accounts</span>
          </LinkButton>
          <div className="min-h-[38vh]">
            {achTransactions.length === 0 && <div className="text-center">No records found</div>}
            {achTransactions.length > 0 && (
              <DataTable
                columns={columns}
                data={achTransactions}
                className="w-full table-statement"
                pagination
                customStyles={customStyle}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AchTransactionsList;
