import { useEffect, useState } from 'react';
import { cards as cardsApi } from '../helpers/api';

const useCardImage = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    cardsApi
      .getCardImageUrl()
      .then((response) => {
        setUrl(response.data.url);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    loading,
    url
  };
};

export default useCardImage;
