import { get, post as _post } from './httpBase';

const url = '/api/bill_payments';

export const createBillPayment = (billPayment) => {
  const post = _post(url);
  return post({ data: { ...billPayment } });
};

export const getDownloadReport = (startDate, endDate, selectedBillerIds) => {
  return get(
    `${url}/download_report?start_date=${startDate}&end_date=${endDate}&selected_biller_ids=${JSON.stringify(selectedBillerIds)}`
  )({ options: { responseType: 'blob' } });
};

export const getIndex = get(url);
