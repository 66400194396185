import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const RequestSupportBtn = () => {
  const swalPopUp = withReactContent(Swal);

  const showPopup = () => {
    swalPopUp.fire({
      position: 'center',
      title: 'Customer Support: 866-357-6095',
      confirmButtonText: 'OK'
    });
  }
  return (
    <button
      onClick={showPopup}
      type="button"
      className="px-4 py-5 bg-teal rounded-full h-10 text-descriptionSlide text-white font-bold cursor-pointer flex justify-center items-center gap-1">
      <i className="far fa-circle-question text-white" />
      <span className="text-white text-link">Request Support</span>
    </button>
  )
};

export default RequestSupportBtn;
