import React from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { users as usersApi } from '@helpers/api';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import imagesLogin from '@components/CarouselSlider/Images/imagesLogin.jsx';
import MainTemplate from '@components/MainTemplate';
import SubmitButton from '@components/SubmitButton';
import ValidatedInput from '@components/ValidatedInput';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { swalConfirmCloseConfig, swalErrorConfig } from '@constants/sweetAlert';

const PasswordReset = () => {
  const [searchParams] = useSearchParams();

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,128}$/;
  const matchesErrorMessage =
    'Password must contain uppercase letters, lowercase letters, and numbers.';

  const validationSchema = Yup.object({
    password: Yup.string()
      .required()
      .min(8, 'Password Too Short')
      .max(128, 'Password Too Long')
      .matches(passwordRegex, matchesErrorMessage),
    password_confirmation: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const navigate = useNavigate();
  const swalPopUp = withReactContent(Swal);

  const handleSubmit = (values) => {
    usersApi
      .updatePassword({
        data: {
          user: {
            reset_password_token: searchParams.get('reset_password_token'),
            password: values.password,
            password_confirmation: values.password_confirmation
          }
        }
      })
      .then(() => {
        swalPopUp
          .fire({
            ...swalConfirmCloseConfig,
            title:
              'Your password has been successfully updated. You will now be redirected to login'
          })
          .then(() => {
            navigate('/');
          });
      })
      .catch(() => {
        swalPopUp.fire({
          ...swalErrorConfig,
          title: 'There was an error while resetting your password. Please try again.'
        });
      });
  };

  const formValues = {
    password: '',
    password_confirmation: ''
  };

  const FormComponent = (
    <>
      <h1 className="mobile:text-titleFormResponsive text-titleForm font-bold text-black">
        Password Reset
      </h1>
      <p className="desktop:mb-8 xl:mb-11 text-center text-darkGray text-descriptionSlide">
        Please enter and confirm your new password. Remember, passwords need to be a minimum of 8
        characters, and have at least one lowercase letter, one uppercase letter, and one number.
      </p>
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ errors, values, handleChange, isSubmitting }) => (
          <Form className="w-full desktop:w-4/5">
            <div className="flex flex-col justify-center items-center">
              <ValidatedInput
                name="password"
                placeholder="Password"
                id="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                icon="fa-lock"
                errors={errors}
              />

              <ValidatedInput
                name="password_confirmation"
                placeholder="Password Confirmation"
                id="password_confirmation"
                type="password"
                value={values.password_confirmation}
                onChange={handleChange}
                icon="fa-lock"
                errors={errors}
              />

              <SubmitButton
                disabled={isSubmitting || Object.keys(errors).length || false}
                value="Save New Password"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );

  return <MainTemplate body={FormComponent} images={imagesLogin} />;
};

export default PasswordReset;
