import React from 'react';
import { Balance, Banner, TransactionList } from '@components/Dashboard';
import ToolsBannerComponent from '../shared/ToolsBannerComponent';

const DashboardMain = ({ balance, userFeatures }) => {
  return (
    <main className="main">
      <Balance balance={balance} />
      <Banner userFeatures={userFeatures} />
      {userFeatures.paymentTracker && <ToolsBannerComponent />}
      <TransactionList />
    </main>
  );
};

export default DashboardMain;
