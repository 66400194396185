import React from 'react';

const Tooltip = ({ description, className = '' }) => {
  return (
    <div className={`tooltip-wrapper position ${className}`}>
      <i className="fas fa-circle-question icon" />
      <div className="description">{description}</div>
    </div>
  );
};

export default Tooltip;
