import React, { useState, useContext, useCallback } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { users as usersApi } from '@helpers/api';
import { swalMFA } from '@constants/sweetAlert';
import { SessionContext } from '../../contexts';
import MFAModal from './MFAModal';

const useMFA = (setUser, user) => {
  const [loading, setLoading] = useState(false);
  const swalPopUp = withReactContent(Swal);

  const enable = useCallback(() => {
    swalPopUp
      .fire({
        ...swalMFA,
        confirmButtonText: 'Confirm',
        html: <MFAModal enableMfa />,
        showCancelButton: true,
        showCloseButton: false,
        showConfirmButton: true
      })
      .then((result) => {
        if (result.isConfirmed) {
          usersApi
            .enableMFA()
            .then((response) => {
              setUser({
                ...user,
                mfa_active: true,
                otp_url: response.data.otp_url
              });
              swalPopUp.fire({
                ...swalMFA,
                html: <MFAModal user={{ mfa_active: true, otp_url: response.data.otp_url }} />,
                showCancelButton: false,
                showCloseButton: false,
                showConfirmButton: true
              });
            })
            .catch(() => {
              swalPopUp.fire({
                position: 'center',
                icon: 'error',
                title: 'There was an error enabling MFA.',
                showConfirmButton: false,
                timer: 1500
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
  });

  const disable = useCallback(() => {
    swalPopUp
      .fire({
        position: 'center',
        title: 'Disable Multi Factor Authentication',
        html: 'You will no longer need multi factor authentication to access your account',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      })
      .then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          usersApi
            .disableMFA()
            .then(() => {
              setUser({
                ...user,
                mfa_active: false
              });
            })
            .catch(() => {
              swalPopUp.fire({
                position: 'center',
                icon: 'error',
                title: 'There was an error disabling MFA.',
                showConfirmButton: false,
                timer: 1500
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
  });

  return {
    loading,
    enable,
    disable
  };
};

const MFAToggle = () => {
  const { user, setUser } = useContext(SessionContext);
  const { disable, enable, loading } = useMFA(setUser, user);

  return (
    <div className="flex justify-center items-center gap-6 border border-solid border-whiteGray p-4 rounded-lg w-full">
      <label
        htmlFor="mfa"
        name="mfaLabel"
        id="mfaLabel"
        className="w-full flex justify-between items-center">
        <span className="w-full">Secure your account with multi factor authentication</span>

        <div className="w-full flex justify-end items-center">
          <span className="switch">
            <input
              id="mfa"
              name="mfa"
              type="checkbox"
              onChange={user.mfa_active ? disable : enable}
              disabled={loading}
              checked={user.mfa_active || false}
            />
            <span className="slider round" />
          </span>
        </div>
      </label>
    </div>
  );
};

export default MFAToggle;
