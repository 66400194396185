import React, { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';

const PlaidLinkOauth = ({ selectAccount }) => {
  const navigate = useNavigate();
  const baseLinkPath = 'plaid/link';
  const linkToken = localStorage.getItem('linkToken');

  const config = {
    onSuccess: (plaidPublicToken, metadata) => {
      selectAccount(plaidPublicToken, metadata.account);
      localStorage.removeItem('linkToken');
    },
    onExit: () => {
      navigate(baseLinkPath);
    },
    onEvent: () => {},
    token: linkToken,
    receivedRedirectUri: window.location.href
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (!linkToken) {
      navigate(baseLinkPath);
    }

    if (ready) {
      open();
    }
  }, [ready, open]);

  return <div />;
};

export default PlaidLinkOauth;
