import React, { useState, useContext } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { plaid as plaidApi } from '@helpers/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PlaidLink from '@components/PlaidLink';
import PlaidLinkOauth from '@components/PlaidLinkOauth';
import Loader from '@components/Loader';
import LinkButton from '@components/LinkButton';
import SignOutButton from '@components/SignOutButton';
import { EnrollmentContext } from '@contexts';

const Plaid = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enrollment } = useContext(EnrollmentContext);
  const swalPopUp = withReactContent(Swal);

  const basePath = () => {
    if (enrollment) {
      return '/enrollment-funding';
    }
    return '/dashboard/funding';
  };

  const redirectPath = (achAccount) => {
    if (achAccount.verified) {
      return `${basePath()}/ach/create-transaction/${achAccount.id}`;
    } else {
      return `${basePath()}/ach/create-transaction`
    }
  }

  const selectAccount = (publicToken, account) => {
    setLoading(true);
    plaidApi
      .upsertAchAccount({
        data: {
          public_token: publicToken,
          account_id: account.id,
          account_name: account.name,
          verification_status: account.verification_status || 'verified',
        }
      })
      .then((response) => {
        const achAccount = response.data;
        navigate(redirectPath(achAccount));
      })
      .catch((error) => {
        if (error.response.data.errors[0] == 'Name on bank account does not match name on account application.') {
          swalPopUp
            .fire({
              position: 'center',
              icon: 'error',
              title: 'The name on this account does not match the name on the bank account. Please connect a different account.',
              showConfirmButton: true
            })
            .then(() => {
              navigate(basePath());
            });
        } else {
          swalPopUp
            .fire({
              position: 'center',
              icon: 'error',
              title:
                'There was an issue while creating this account with the provider. Please try again.',
              showConfirmButton: true
            })
            .then(() => {
              navigate(basePath());
            });
        }
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Routes>
        <Route path="/link" exact element={<PlaidLink selectAccount={selectAccount} />} />
        <Route path="/link/:achAccountId" exact element={<PlaidLink selectAccount={selectAccount} />} />
        <Route
          path="/link-oauth"
          exact
          element={<PlaidLinkOauth selectAccount={selectAccount} />}
        />
      </Routes>
      <LinkButton path={basePath()}>
        <i className="fa-solid fa-arrow-left-long" />
        <span>Back to funding options</span>
      </LinkButton>
      <SignOutButton isFunding />
    </>
  );
};

export default Plaid;
