import React from 'react';

const NotFound = () => {
  return (
    <div className="flex justify-center items-center h-screen w-full">
      <span className="text-black font-bold text-3xl">404: Page not found</span>
    </div>
  );
};

export default NotFound;
