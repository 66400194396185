import React from 'react';

const ReferralPage = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-4">
      <h1 className="text-dashBoardTopName font-bold text-black text-center">
        Almost there! We need a little more information from you before we can get started.
      </h1>
      <div className="flex flex-col justify-start items-start p-8 gap-6">
        <p className="text-titleSlide text-black font-medium">
          Please provide the following to allow us to fully verify your account:
        </p>
        <ul className="px-6">
          <li className="flex justify-start items-center gap-2">
            <i className="far fa-copy" /> <span>Copy of Social Security Card</span>
          </li>
          <li className="flex justify-start items-center gap-2">
            <i className="far fa-copy" />{' '}
            <span>Copy of valid/unexpired drivers license or state ID</span>
          </li>
          <li className="flex justify-start items-center gap-2">
            <i className="far fa-copy" />{' '}
            <span>
              Copy of a utility bill within the last 60 days to verify the address on file
            </span>
          </li>
        </ul>
        <p className="text-titleSlide text-black font-medium">
          Please provide clear images of the complete documents, not just small sections.
        </p>
        <p className="text-titleSlide text-black font-medium">
          You may either Fax or Mail the documents to the following locations:
        </p>
        <ul className="px-6">
          <li className="flex justify-start items-center gap-2">
            {' '}
            <i className="far fa-fax" /> <span>Fax number: 801-298-6404</span>
          </li>
          <li className="flex justify-start items-center gap-2">
            <i className="far fa-envelope" />{' '}
            <span>
              Mailing address: PO Box 71402, Salt Lake City, UT 84171 ATTN: ID Verification (TFS)
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ReferralPage;
