import React from 'react';
import { Link } from 'react-router-dom';
import { YourSuccessLogoImg } from '@constants/images';
import LoginForm from './LoginForm';

const TopBar = () => {
  return (
    <header className="tablet:sticky desktop:sticky tablet:top-0 desktop:top-0 z-10 bg-white shadow-md py-3 sm:py-4">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center px-3 sm:px-6 lg:px-8">
        <Link
          to="/"
          className="text-lg sm:text-2xl font-bold text-gray-800 mb-3 sm:mb-0 flex justify-center w-full sm:w-auto">
          <img
            src={YourSuccessLogoImg}
            alt="YourSuccess Logo"
            className="w-[90px] h-[45px] sm:w-[200px] sm:h-[100px]"
          />
        </Link>
        <div className="w-full sm:w-auto flex justify-center sm:justify-end">
          <LoginForm className="w-full sm:w-auto text-sm sm:text-base" topBarShown={true} />
        </div>
      </div>
    </header>
  );
};

export default TopBar;
