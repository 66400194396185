import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { globalReplace } from '@utilities/string';

// inputMask generalizes the concept of the masked input  -> Formik integration
// allowing us to use a concise statement for a given mask over an input type.
//
// The stateTransform as a function that takes the input value (which is
// assumed to have mask characters) and transforms it into the expected value
// for the form submission. For example, with a phone number input, the mask
// may be:
//
// '(999) 999-999'
//
// Then a phone number of (522) 753-9586 would need to have the characters (,
// ), \s, and - stripped out prior to submission. We achieve this with the
// stateTransform function.
//
// The idea of the stateTransform is to use exclusion over some type as a
// preference. So in this case, we create a function that replaces all
// instances of non-digit characters with an empty string. This allows us to
// have the concept of a 'numericMask'.
//
// The next parameters are the interface that the developer interacts with directly:
//
// name: the name of the field, this should match the Field's name prop for Formik.
// placeholder: The placeholder string.
// mask: The masking pattern
//
// This allows us to keep the definition of the mask colocated to the
// point-of-use. I find that keeping that information in place makes it easier
// to reason about. It also is most likely to change in place with the
// surrounding implementation.
//
// Finally, this function returns the expected handler for the Formik controls.
// Taking the `form` object in, it handles dealing with state updates. This is
// probably the biggest shortcoming. The state is expected to be a primitive.
// So anything requiring an object state would probably not be great here.
const inputMask =
  (stateTransform) =>
  ({ name, placeholder, mask, classes }) =>
  ({ form }) => {
    const [inputState, setInputState] = useState(form.values[name]);
    return (
      <InputMask
        name={`${name}_input`}
        placeholder={placeholder}
        mask={mask}
        value={inputState}
        onChange={({ target: { value } }) => {
          setInputState(value);
          form.setFieldValue(name, stateTransform(value));
        }}>
        {(inputProps) => <input {...inputProps} className={classes} />}
      </InputMask>
    );
  };

const onlyNumbers = globalReplace('[^0-9]', '');

export const numeric = inputMask(onlyNumbers);
