/* eslint-disable no-console */
import React from 'react';
import AchToggle from '@components/AchToggle';
import MFAToggle from '@components/MFAToggle';
import BackupCodesButton from '@components/BackupCodesButton';

const AccountFeatures = () => {
  return (
    <div className="account-features">
      <div className="bg-white rounded-3xl p-5">
        <h2 className="text-bannerTitle font-bold text-black mb-6">Account Features</h2>
        <div className="w-full flex flex-col justify-center items-center">
          <div className="desktop:w-2/4 tablet:w-full mobile:w-full flex flex-col justify-center items-center gap-4">
            <AchToggle />
            <MFAToggle />
            <BackupCodesButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountFeatures;
