import React, { useState } from 'react';
import { Button, Box, Paper, Typography } from '@mui/material';
import BudgetingBuddyComponent from '../../shared/BudgetingBuddy';
import ModalVideo from 'react-modal-video';

const WelcomeComponent = ({ handleNext, isMonthly = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const WelcomeComponentMonthlyText = () => {
    return (
      <Box mb={4}>
        <Typography variant="h5" gutterBottom mt={2} textAlign="center">
          It's time to update your goals for next month
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          In order to help you stay on your track to success I will guide you through this
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          coming months payments to make sure you stay on track!
        </Typography>
      </Box>
    );
  };

  const WelcomeComponentText = () => {
    return (
      <Box mb={4}>
        <Typography variant="subtitle1" textAlign="center">
          Hello, I'm Dollar Bill and I'm going to help you track your
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          expenses so you never miss a Chapter 13 trustee payment.
        </Typography>
      </Box>
    );
  };

  return (
    <Paper elevation={0}>
      <BudgetingBuddyComponent />
      {isMonthly ? WelcomeComponentMonthlyText() : WelcomeComponentText()}
      <Box display="flex" justifyContent="center" gap="10px">
        <button className="bg-greyblue text-white" onClick={handleNext}>
          Get Started
        </button>
        <button className="bg-greige" onClick={() => setIsOpen(true)}>
          Learn More
        </button>
      </Box>
      <ModalVideo
        channel="youtube"
        youtube={{ autoplay: 0 }}
        isOpen={isOpen}
        videoId="rgeisi0JddI"
        onClose={() => setIsOpen(false)}
      />
    </Paper>
  );
};

export default WelcomeComponent;
