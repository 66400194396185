import React from 'react';
import { getClass } from '@helpers/formik';
import CustomCurrencyInput from './CustomCurrencyInput';

const CurrencyInput = ({ placeholder, name, value, handleChange, errors }) => {
  return (
    <div className="relative z-0 w-full max-w-lg mobile:mb-10 mb-7">
      <div className="relative z-0 group">
        <div className="mobile:hidden desktop:block tablet:block absolute -top-4 right-0 text-red font-normal text-errorMessageInput">
          {errors[name]}
        </div>
        <CustomCurrencyInput
          className={`border px-5 py-3 border-solid border-whiteGray bg-whiteLighter rounded-md block w-full mt-0 appearance-none focus:outline-none focus:ring-0 focus:border-black placeholder-darkGray ${getClass(
            errors,
            name
          )}`}
          inputName={name}
          inputValue={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <span className="z-10 h-full leading-8 font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 top-0 pr-3 py-3">
          <i className="far fa-dollar-sign" />
        </span>
        <label
          htmlFor={name}
          className="absolute duration-300 top-3 -z-1 origin-0 text-black left-0">
          {placeholder}
        </label>
        <div className="mobile:block desktop:hidden tablet:hidden absolute top-14 left-0 text-red font-normal text-errorMessageInput">
          {errors[name]}
        </div>
      </div>
    </div>
  );
};

export default CurrencyInput;
