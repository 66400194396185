import React, { useContext, useMemo } from 'react';
import Card from '../Shared/Card';
import { TrackSuccessContext } from '@contexts';
import { INCOME_TYPE } from '@utilities/constants';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { numericFormatter } from 'react-number-format';

const data = [];

const size = {
  height: 400
};

const formatNumber = (value) => {
  return numericFormatter(value + '', {
    prefix: '$',
    decimalScale: 2,
    fixedDecimalScale: true,
    allowNegative: false,
    isNumericString: true,
    thousandSeparator: ','
  });
};

const ExpectedIncomeByCategory = () => {
  const { incomeGoals } = useContext(TrackSuccessContext);

  const dataChart = useMemo(() => {
    incomeGoals?.map((item) => {
      data.push({
        label: INCOME_TYPE.find((cat) => cat.value === item.category).label,
        value: Number(item.amount)
      });
    });
    return data;
  }, [incomeGoals]);

  return (
    <Card title="Expected Income By Category">
      <div className="flex flex-col items-center justify-center w-full h-[500px]">
        {data && (
          <PieChart
            series={[
              {
                arcLabel: (item) => `${formatNumber(item.value)}`,
                arcLabelMinAngle: 45,
                data: dataChart,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' }
              }
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontWeight: 'bold'
              }
            }}
            slotProps={{
              legend: {
                position: {
                  horizontal: 'middle',
                  vertical: 'bottom'
                },
                direction: 'row'
              }
            }}
            margin={{ bottom: 80 }}
            {...size}
          />
        )}
      </div>
    </Card>
  );
};

export default ExpectedIncomeByCategory;
