export const swalConfig = {
  showCloseButton: true,
  showConfirmButton: false,
  background: '#3B6483',
  position: 'bottom'
};

export const swalConfirmCloseConfig = {
  showCloseButton: true,
  showConfirmButton: true,
  background: '#fff',
  position: 'center',
  customClass: {
    confirmButton: 'btn_submit',
    popup: 'popUp_modal',
    closeButton: 'close_button'
  },
  confirmButtonText: 'Confirm',
  buttonsStyling: false
};

export const swalErrorConfig = {
  showConfirmButton: true,
  icon: 'error',
  background: '#fff',
  position: 'center',
  customClass: {
    confirmButton: 'btn_submit',
    popup: 'popUp_modal',
    closeButton: 'close_button'
  },
  confirmButtonText: 'Ok',
  buttonsStyling: false
};

export const swalSignOut = {
  showCloseButton: true,
  showConfirmButton: true,
  background: '#fff',
  position: 'center',
  customClass: {
    htmlContainer: 'container_modal',
    confirmButton: 'btn_submit',
    popup: 'popUp_modal',
    closeButton: 'close_button'
  },
  confirmButtonText: 'Log Out',
  buttonsStyling: false
};

export const swalAddPaymentBiller = {
  showCloseButton: true,
  showConfirmButton: true,
  showCancelButton: true,
  background: '#fff',
  position: 'center',
  customClass: {
    htmlContainer: 'container_modal',
    confirmButton: 'btn_submit',
    cancelButton: 'btn_danger',
    popup: 'popUp_modal',
    closeButton: 'close_button'
  },
  confirmButtonText: 'Add',
  buttonsStyling: false,
  allowOutsideClick: false
};

export const swalRequestNewCard = {
  title: 'You have requested a new card',
  icon: 'success',
  showConfirmButton: true,
  showCancelButton: false,
  background: '#fff',
  position: 'center',
  customClass: {
    htmlContainer: 'container_modal',
    confirmButton: 'btn_submit',
    cancelButton: 'btn_danger',
    popup: 'popUp_modal',
    closeButton: 'close_button'
  },
  confirmButtonText: 'Ok',
  buttonsStyling: false,
  allowOutsideClick: false
};

export const swalMFA = {
  showConfirmButton: true,
  showCancelButton: false,
  background: '#fff',
  position: 'center',
  customClass: {
    htmlContainer: 'container_modal',
    confirmButton: 'btn_submit',
    cancelButton: 'btn_danger',
    popup: 'popUp_modal',
    closeButton: 'close_button'
  },
  confirmButtonText: 'Confirm',
  buttonsStyling: false,
  allowOutsideClick: false
};
