import React, { useEffect, useState } from 'react';
import { transactions as transactionsApi, authorizations as authorizationsApi, achTransactions as achTransactionsApi } from '@helpers/api';
import TransactionsTable from '@components/TransactionsTable';
import _ from 'lodash';

const defaultSelection = [
  {
    startDate: null,
    endDate: null,
    key: 'selection'
  }
];

const TransactionList = () => {
  const [account, setAccount] = useState({
    transactions: []
  });
  const [auths, setAuths] = useState([]);
  const [pendingAchTransactions, setPendingAchTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    start_date: '',
    end_date: ''
  });

  const [selection, setSelection] = useState(defaultSelection);

  const applyFilter = () => {
    const { startDate, endDate } = selection[0];
    if (_.isNil(startDate) || _.isNil(endDate)) {
      return;
    }
    setFilters({ ...filters, ...{ start_date: startDate, end_date: endDate } });
  };

  const clearFilter = () => {
    setFilters({ ...filters, ...{ start_date: null, end_date: null, key: 'selection' } });
    setSelection(defaultSelection);
  };

  useEffect(() => {
    setAccount({
      transactions: []
    });
    setPage(1);
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    transactionsApi
      .getIndex({
        options: {
          params: {
            page,
            filters
          }
        }
      })
      .then((response) => {
        setLastPage(response.data.last_page);
        authorizationsApi
          .getIndex()
          .then((authResponse) => {
            setAuths(authResponse.data.authorizations);
            achTransactionsApi
              .getPending()
              .then((pendingAchTransactionsResponse) => {
                setPendingAchTransactions(pendingAchTransactionsResponse.data);
              })
              .catch((errorsResponse) => {
                setErrors(errorsResponse);
              });
          })
          .catch((errorsResponse) => {
            setErrors(errorsResponse);
          });

        setAccount((prev) => {
          return {
            transactions: [...prev.transactions, ...response.data.transactions]
          };
        });
      })
      .catch((errorsTransaction) => {
        setErrors(errorsTransaction);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, filters]);

  const incrementPage = () => {
    setPage(page + 1);
  };

  const body = () => {
    return !_.isEmpty(auths) || !_.isEmpty(account.transactions) || !_.isEmpty(pendingAchTransactions) ? (
      <TransactionsTable
        authorizedTransactions={auths}
        settledTransactions={account.transactions}
        pendingAchTransactions={pendingAchTransactions}
        incrementPage={incrementPage}
        lastPage={lastPage}
        selection={selection}
        setSelection={setSelection}
        clearFilter={clearFilter}
        applyFilter={applyFilter}
      />
    ) : (
      <h2 className="w-full h-full flex justify-center items-center">No Transactions</h2>
    );
  };

  return (
    <div className="transactions">
      {!!errors && <h2>{errors.message}</h2>}
      {body()}
      {loading && (
        <div className="w-full h-full flex justify-center items-center">
          <i className="fa-solid fa-spinner fa-spin fa-4x text-greyblue" />
        </div>
      )}
    </div>
  );
};

export default TransactionList;
