import React, { useContext, useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { TrackSuccessContext } from '@contexts';
import { EXPENSE_TYPE } from '@utilities/constants';
import GaugeChart from './GaugeChart';
import BreadCrumbComponent from '../Shared/BreadCrumbComponent';
import UncategorizedExpenses from '../EditExpenseGoals/UncategorizedExpenses';
import ExternalExpenses from '../EditExpenseGoals/ExternalExpenses';
import { getUncategorizedExpenseItems } from '@helpers/api/tracker';
import { MonthSelector } from '../Shared/MonthSelector';
import { DateTime } from 'luxon';

const Expenses = () => {
  const { expenseItems, expenseGoals, setExpenseItems, incomeGoals, month } =
    useContext(TrackSuccessContext);
  const [uncategorizedItems, setUncategorizedItems] = useState([]);

  const uncategorizedExpensesFetch = async () => {
    const result = await getUncategorizedExpenseItems({
      params: {
        month: month ? DateTime.fromISO(month).toJSDate() : null
      }
    });
    const expenseItemsData = result.data.expense_items;
    setUncategorizedItems(expenseItemsData);
  };
  useEffect(() => {
    uncategorizedExpensesFetch();
  }, [expenseItems, expenseGoals]);

  return (
    <Box sx={{ width: '100%' }}>
      <MonthSelector>
        <h1 className="title-settings text-dashBoardTopName font-bold text-black text-center my-4">
          Expenses
        </h1>
      </MonthSelector>
      <BreadCrumbComponent />
      <Paper sx={{ width: '100%', my: 2 }}>
        <GaugeChart
          items={expenseItems}
          goals={expenseGoals}
          categories={EXPENSE_TYPE}
          uncategorizedItems={uncategorizedItems}
          incomeGoals={incomeGoals}
        />
      </Paper>
      {uncategorizedItems.length > 0 && (
        <Paper sx={{ width: '100%', my: 2 }}>
          <UncategorizedExpenses setExpenseItems={setExpenseItems} />
        </Paper>
      )}
      <Paper sx={{ mt: 2 }}>
        <ExternalExpenses setExpenseItems={setExpenseItems} month={month} />
      </Paper>
    </Box>
  );
};

export default Expenses;
