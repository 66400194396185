/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useEffect } from 'react';
import { useMobileOrientation, isMobileOnly } from 'react-device-detect';

import { YourSuccessLogoImg } from '@constants/images';

import { SessionContext, AccountContext } from '@contexts';
// eslint-disable-next-line import/named
import useLocalStorage from '@hooks/useLocalStorage';
import ApplicationRoutes from '../routes/Application';
import { users as usersApi } from '../helpers/api';
import SessionTimeout from './SessionTimeOut';

const App = () => {
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionLocation, setSessionLocation] = useState('/dashboard');
  const [token, setToken] = useLocalStorage('Authorization', null);
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);

  const { isLandscape, isPortrait } = useMobileOrientation();

  useEffect(() => {
    if (token) {
      usersApi
        .getShow()
        .then((response) => {
          setUser(response.data.user);
        })
        .catch(() => {
          setUser(null);
        });
    } else {
      setUser(null);
    }
  }, [token]);

  useEffect(() => {
    setLoading(false);
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <SessionContext.Provider
      value={{
        user,
        setUser,
        setToken,
        sessionLocation,
        setSessionLocation,
        isOpenNotifications,
        setIsOpenNotifications
      }}>
      <AccountContext.Provider value={{ account, setAccount }}>
        {isMobileOnly && isLandscape && (
          <div className="flex flex-col justify-center items-center w-full h-screen">
            <img
              src={YourSuccessLogoImg}
              alt="YourSuccess Logo"
              className="w-[227px] h-[77px] mt-6 mobile:mb-6 mb-6"
            />
            <p>For a better experience, please rotate your device</p>
          </div>
        )}
        {isMobileOnly && isPortrait && <ApplicationRoutes />}
        {!isMobileOnly && (isPortrait || isLandscape) && <ApplicationRoutes />}
      </AccountContext.Provider>
      {user && <SessionTimeout token={token} setToken={setToken} />}
    </SessionContext.Provider>
  );
};

export default App;
