import React, { useState } from 'react';
import WelcomeComponent from '../GetStartedSteps/WelcomeComponent';
import TrusteePaymentComponent from '../GetStartedSteps/TrusteePaymentComponent';
import IncomeGoalsComponent from '../GetStartedSteps/IncomeGoalsComponent';
import ExpenseGoalsComponent from '../GetStartedSteps/ExpenseGoalsComponent';
import ReviewComponent from '../GetStartedSteps/ReviewComponent';
import ProgressBar from '../../ProgressBar/ProgressBar';
import steps from '@constants/trackerApplication';

export default function StepperGetStarted({
  expenseGoals,
  incomeGoals,
  setExpenseGoals,
  setIncomeGoals,
  trusteePayment,
  setTrusteePayment,
  setGetStarted
}) {
  const [currentStep, setCurrentStep] = useState('Welcome');
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCurrentStep(steps.find(({ id }) => id === activeStep + 1)?.step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCurrentStep(steps.find(({ id }) => id === activeStep - 1)?.step);
  };

  return (
    <div className="flex flex-col items-center overflow-auto innerContainer desktop:w-full mobile:w-full tablet:w-full">
      <ProgressBar currentStep={currentStep} steps={steps} />
      {activeStep === 1 && <WelcomeComponent handleNext={handleNext} />}
      {activeStep === 2 && (
        <TrusteePaymentComponent
          handleNext={handleNext}
          handleBack={handleBack}
          trusteePayment={trusteePayment}
          setTrusteePayment={setTrusteePayment}
          setExpenseGoals={setExpenseGoals}
        />
      )}
      {activeStep === 3 && (
        <IncomeGoalsComponent
          handleNext={handleNext}
          incomeGoals={incomeGoals}
          handleBack={handleBack}
          setIncomeGoals={setIncomeGoals}
          headingText="Enter all the income sources you expect to receive this month"
          trusteePayment={trusteePayment}
        />
      )}
      {activeStep === 4 && (
        <ExpenseGoalsComponent
          expenseGoals={expenseGoals}
          handleNext={handleNext}
          handleBack={handleBack}
          setExpenseGoals={setExpenseGoals}
          trusteePayment={trusteePayment}
          headerText="Enter all the expenses or bills you expect to pay this month."
          incomeGoals={incomeGoals}
        />
      )}
      {activeStep === 5 && (
        <ReviewComponent
          expenseGoals={expenseGoals}
          incomeGoals={incomeGoals}
          handleBack={handleBack}
          headingText={'Please review your information'}
          setGetStarted={setGetStarted}
          trusteePayment={trusteePayment}
        />
      )}
    </div>
  );
}
