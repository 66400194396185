import React from 'react';
import _ from 'lodash';

import ProgressBar from '@components/ProgressBar';

const EnrollmentProgressBar = ({ currentStep, steps }) => {
  return <ProgressBar steps={steps} currentStep={_.startCase(_.toLower(currentStep))} />;
};

export default EnrollmentProgressBar;
