import React from 'react';
import { SuccessTrackerImg } from '@constants/images';
import { useNavigate } from 'react-router-dom';

const ToolsBannerComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="tools-banner flex flex-col md:flex-row justify-center items-center text-center md:text-center">
      <div className="flex flex-col md:flex-row items-center justify-center w-full">
        <div className="my-5 p-5 order-1 md:order-1 flex flex-col items-center md:items-center w-full md:w-1/3">
          <h2 className="text-2xl font-bold mb-3">
            Success Tracker <span className="text-md italic">|NEW|</span>
          </h2>
          <div className="order-3 md:order-1">
            <button
              type="button"
              onClick={() => navigate('/dashboard/tracker/track-success')}
              className="mobile:w-[300px] tablet:w-full desktop:w-[250px] bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer">
              Get Started
            </button>
          </div>
        </div>
        <div className="order-2 md:order-2 flex flex-col items-center md:items-center divider-custom my-5 p-5 md:my-0 md:px-5 w-full md:w-1/3">
          <ul className="space-y-3 text-md text-center list-none">
            <li>Set and track goals monthly</li>
            <li>Help guarantee you never miss a trustee payment</li>
            <li>Plan for a successful discharge</li>
          </ul>
        </div>
        <div className="w-full md:w-1/3 my-5 p-5 order-3 md:order-3 hidden md:block flex justify-center">
          <img src={SuccessTrackerImg} alt="Success Tracker" style={{ borderRadius: '10px' }} />
        </div>
      </div>
    </div>
  );
};

export default ToolsBannerComponent;
