import React, { useState, useEffect } from 'react';
import { YourSuccessLogoImg } from '@constants/images';
import Footer from '@components/Footer';
import CarouselSlider from '@components/CarouselSlider';

const MainTemplate = ({ body, images, textFooter, textLinkFooter, linkFooter, logo = true }) => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(window.innerWidth <= 450);
  }, [window.innerWidth]);

  return (
    <div className="w-screen min-h-full bg-teal flex justify-center items-center">
      <div className="w-full">
        <div className="flex flex-col lg:flex-row justify-center lg:justify-around items-center lg:items-stretch gap-4 lg:gap-8 min-h-full">
          {!mobile && <div className="w-full">
            <CarouselSlider images={images} />
          </div>}
          <div className="w-full h-full bg-white px-4 sm:px-6 lg:px-12 xl:px-44 py-8 lg:py-10 lg:rounded-tl-3xl lg:rounded-bl-3xl shadow-md">
            <div className="flex flex-col justify-center items-center gap-6 lg:gap-8 h-full">
              <div className="flex flex-col items-center gap-6 w-full">
                {logo && (
                  <img
                    src={YourSuccessLogoImg}
                    alt="YourSuccess Logo"
                    className="w-1/2 h-auto mt-4 lg:mt-6 mb-4 lg:mb-6"
                  />
                )}
                {body}
              </div>
              <div className="w-full lg:w-auto mt-4 lg:mt-0">
                <Footer />
              </div>
            </div>
          </div>
          {mobile && <CarouselSlider images={images} />}
        </div>
      </div>
    </div>
  );
};

export default MainTemplate;
