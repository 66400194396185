import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box, Paper } from '@mui/material';
import DataTable from 'react-data-table-component';
import { DateTime } from 'luxon';
import { TrackerAvatarImg } from '@constants/images';
import { EXPENSE_TYPE } from '../../../utilities/constants';
import { getUncategorizedExpenseItems, putExpenseItem } from '@helpers/api/tracker';
import { TrackSuccessContext } from '@contexts';

const UncategorizedExpenses = ({ setExpenseItems }) => {
  const [uncategorizedItems, setUncategorizedItems] = useState([]);
  const { month } = useContext(TrackSuccessContext);

  const formatDate = (date) => DateTime.fromISO(date).toFormat('yyyy-MM-dd');

  const fetchData = async () => {
    try {
      const result = await getUncategorizedExpenseItems({
        params: {
          month: month ? DateTime.fromISO(month).toJSDate() : null
        }
      });
      const initializedItems = result.data.expense_items.map((item) => ({
        ...item,
        category: item.category || ''
      }));
      setUncategorizedItems(initializedItems);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = async (id, value) => {
    const updatedItemIndex = uncategorizedItems.findIndex((expense) => expense.id === id);

    if (updatedItemIndex === -1) {
      console.error('Expense item not found.');
      return;
    }

    const updatedItems = [...uncategorizedItems];
    updatedItems[updatedItemIndex] = { ...updatedItems[updatedItemIndex], category: value };

    try {
      const result = await putExpenseItem(updatedItems[updatedItemIndex].id)({
        data: {
          month: month ? DateTime.fromISO(month).toJSDate() : null,
          expense_item: {
            amount: updatedItems[updatedItemIndex].amount,
            category: updatedItems[updatedItemIndex].category,
            effective_date: updatedItems[updatedItemIndex].effective_date,
            external: updatedItems[updatedItemIndex].external,
            description: updatedItems[updatedItemIndex].description
          }
        }
      });

      if (result.status === 200) {
        const filteredItems = updatedItems.filter((item, index) => index !== updatedItemIndex);
        setUncategorizedItems(filteredItems);
        setExpenseItems([...result.data]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      id: 'amount',
      title: 'Amount',
      name: 'Amount',
      className: 'amount',
      align: 'center',
      sortable: true,
      cell: (record) => '$' + record.amount
    },
    {
      id: 'date',
      title: 'Date',
      name: 'Date',
      className: 'date',
      align: 'center',
      sortable: true,
      cell: (record) => `${formatDate(record.created_at)}`
    },
    {
      id: 'category',
      title: 'Category',
      name: 'Category',
      className: 'category',
      align: 'center',
      sortable: true,
      cell: (record) => (
        <select
          key={record.id}
          value={record.category}
          onChange={(evt) => handleChange(record.id, evt.target.value)}>
          <option key={''} value={''}>
            Select a category
          </option>
          {EXPENSE_TYPE.filter(
            (item) => item.value !== 'cushion' && item.value !== 'trustee_payment'
          ).map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      )
    },
    {
      id: 'description',
      title: 'Description',
      name: 'Description',
      className: 'description',
      align: 'center',
      sortable: true,
      cell: (record) => record.description
    }
  ];

  const customStyle = {
    headCells: {
      style: {
        fontSize: '14px',
        textAlign: 'center'
      }
    }
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  return (
    <>
      <Typography
        variant="h6"
        color="text.primary"
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          padding: '1rem'
        }}>
        Uncategorized Spendings
      </Typography>
      <Paper elevation={1} sx={{ p: 3 }}>
        <Box display="flex" flexDirection="row" mb={1}>
          <img
            src={TrackerAvatarImg}
            alt="Budgeting Buddy"
            className="rounded-full"
            style={{ width: 50, height: 50 }}
          />
          <Typography variant="body2" textAlign="justify" sx={{ marginLeft: 1 }}>
            Dollar Bill: "We see some expending on your account that we couldn't categorized. You
            can select a category for each transaction here to help keep your account on track!"
          </Typography>
        </Box>
        <DataTable
          columns={columns}
          data={uncategorizedItems}
          className="w-full table-uncategorized-expenses"
          customStyles={customStyle}
          pagination
        />
      </Paper>
    </>
  );
};

export default UncategorizedExpenses;
