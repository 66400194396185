import React, { useContext } from 'react';
import { Info } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { numericFormatter } from 'react-number-format';
import { postSuccessTrackerInfo, updateSuccessTrackerInfo } from '@helpers/api/tracker';
import { DateTime } from 'luxon';
import { TrackSuccessContext } from '@contexts';
import BudgetingBuddyComponent from '../../shared/BudgetingBuddy';
import CurrencyFormat from 'react-currency-format';

const ReviewComponent = ({
  expenseGoals,
  incomeGoals,
  trusteePayment,
  headingText,
  handleBack,
  setGetStarted,
  month = null
}) => {
  const { setReloadPaymentTracking } = useContext(TrackSuccessContext);
  const formatNumber = (value) => {
    return numericFormatter(value + '', {
      prefix: '$',
      decimalScale: 2,
      fixedDecimalScale: true,
      isNumericString: true,
      thousandSeparator: ','
    });
  };
  const totalIncomeGoalsForMonth = incomeGoals.reduce(
    (acc, cur) => Number(acc) + Number(cur.amount),
    0
  );
  const totalExpenseItemsForMonth = expenseGoals.reduce((acc, cur) => {
    if (cur.category !== 'trustee_payment') {
      return Number(acc) + Number(cur.amount);
    }
    return acc;
  }, 0);
  const totalExpenses = totalExpenseItemsForMonth + Number(trusteePayment.amount);
  const remainingBalance = totalIncomeGoalsForMonth - totalExpenses;

  const handleSubmit = async () => {
    try {
      let response;
      if (!month) {
        response = await postSuccessTrackerInfo({
          data: {
            success_tracker_info: {
              get_started_completed_at: new Date().toISOString(),
              budget_last_approved_at: new Date().toISOString()
            }
          }
        });
      } else {
        response = await updateSuccessTrackerInfo({
          data: {
            success_tracker_info: {
              budget_last_approved_at: DateTime.fromJSDate(month).toFormat('yyyy-LL-dd')
            }
          }
        });
      }
      if (response.status === 201 || response.status === 200) {
        setGetStarted(false);
        setReloadPaymentTracking(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <BudgetingBuddyComponent />
      <h3 className="mb-6 text-xl font-semibold">{headingText}</h3>
      <div className="mb-6">
        <p className="mb-6 text-sm text-gray-600">
          <Info className="inline-block mr-2" />
          Review the budget information you have entered and make sure it lines up with what you
          plan on earning and spending this month.
        </p>
      </div>
      <div className="flex flex-col justify-center items-center gap-6">
        <div className="flex gap-5 items-center justify-around w-full">
          <span className="text-center">
            <i className="fa-duotone fa-circle-plus fa-2xl tablet:fa-2xl mobile:fa-xl text-purple-600" />
          </span>
          <span className="desktop:text-lg tablet:text-lg mobile:text-sm w-full">Total Income</span>
          <span
            id="totalIncome"
            className="desktop:text-xl tablet:text-xl mobile:text-lg font-bold">
            <CurrencyFormat
              value={totalIncomeGoalsForMonth}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
            />
          </span>
        </div>
        <div className="flex gap-5 items-center justify-around w-full border-t-2 border-gray-200 pt-4">
          <span className="text-center">
            <i className="fa-duotone fa-circle-minus fa-2xl tablet:fa-2xl mobile:fa-xl text-purple-600" />
          </span>
          <span className="desktop:text-lg tablet:text-lg mobile:text-sm w-full">
            Trustee Payment
          </span>
          <span
            id="trusteePayment"
            className="desktop:text-xl tablet:text-xl mobile:text-lg font-bold">
            <CurrencyFormat
              value={trusteePayment.amount || 0}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
            />
          </span>
        </div>
        <div className="flex gap-5 items-center justify-around w-full border-t-2 border-gray-200 pt-4">
          <span className="text-center">
            <i className="fa-duotone fa-circle-minus fa-2xl tablet:fa-2xl mobile:fa-xl text-purple-600" />
          </span>{' '}
          <span className="desktop:text-lg tablet:text-lg mobile:text-sm w-full">
            Expected Expenses
          </span>
          <span
            id="expectedExpenses"
            className="desktop:text-xl tablet:text-xl mobile:text-lg font-bold">
            <CurrencyFormat
              value={totalExpenseItemsForMonth || 0}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
            />
          </span>
        </div>
        <div className="flex gap-5 items-center justify-around w-full border-t-2 border-gray-200 pt-4 mb-4">
          <span className="text-center">
            <i className="fa-solid fa-equals fa-2xl tablet:fa-2xl mobile:fa-xl text-purple-600" />
          </span>
          <span className="desktop:text-lg tablet:text-lg mobile:text-sm w-full">Remaining</span>
          <span
            id="remainingBalance"
            className="desktop:text-xl tablet:text-xl mobile:text-lg font-bold"
            style={{ color: '#9095A1FF' }}>
            <CurrencyFormat
              value={remainingBalance || 0}
              displayType="text"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
            />
          </span>
        </div>
      </div>
      <div>
        <Button variant="contained" type="button" sx={{ mt: 1, mr: 1 }} onClick={handleSubmit}>
          Finish
        </Button>
        <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
          Back
        </Button>
      </div>
    </>
  );
};

export default ReviewComponent;
