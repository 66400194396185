import React from 'react';
import { Link } from 'react-router-dom';

const LinkButton = ({ path, color, children }) => {
  return (
    <Link to={path}>
      <div className={`flex justify-center items-center gap-2 text-${color || 'teal'} font-bold`}>
        {children}
      </div>
    </Link>
  );
};

export default LinkButton;
