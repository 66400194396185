import React, { useEffect, useState } from 'react';

const SessionModal = ({ handleClose, getRemainingTime }) => {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    setInterval(() => {
      setCountdown(getRemainingTime() / 1000);
    }, 1000);
  }, [])

  return (
    <>
      <div className="darkBG" onClick={() => handleClose()} />
      <div className="centered">
        <div className="modal">
          <div className="modalHeader">
            <h5 className="heading">Your session is about to be closed due to inactivity</h5>
          </div>
          <button type="button" className="closeBtn" onClick={() => handleClose()}>
            X
          </button>
          <div className="modalContent text-achSubTitle font-medium">
            <p className="mt-4 text-link font-medium">
              Time remaining to close the session: {countdown ? Math.trunc(countdown) : null}
            </p>
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
              <button type="button" className="deleteBtn" onClick={() => handleClose()}>
                Return to my session
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SessionModal;
