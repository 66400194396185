import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { DateRange } from 'react-date-range';
import { DateTime } from 'luxon';

const DateRangeFilterPopover = ({ selection, setSelection, applyFilter, clearFilter }) => {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement }
      }
    ]
  });

  const minDate = DateTime.local().minus({ years: 7 });
  const maxDate = DateTime.local().toJSDate();

  return (
    <Popover className="relative">
      <>
        <Popover.Button
          ref={setReferenceElement}
          className="border px-5 py-3 bg-whiteLighter border-whiteGray group inline-flex items-center rounded-md">
          <span>Filter by date range</span>
        </Popover.Button>
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-10">
          <div ref={setArrowElement} style={styles.arrow} />
          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-gray">
            <DateRange
              onChange={(item) => setSelection([item.selection])}
              editableDateInputs={false}
              moveRangeOnFirstSelection={false}
              ranges={selection}
              minDate={minDate.toJSDate()}
              maxDate={maxDate}
              startDatePlaceholder="Start Date"
              endDatePlaceholder="End Date"
            />
            <div className="flex gap-4 justify-center items-center">
              <input
                type="button"
                value="Search"
                onClick={() => applyFilter()}
                className="bg-seafoam hover:bg-teal rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer w-full"
              />
              <input
                type="button"
                value="Clear"
                onClick={() => clearFilter()}
                className="bg-greyblue hover:bg-greyblueHover rounded-xl h-14 text-descriptionSlide text-white font-bold cursor-pointer w-full"
              />
            </div>
          </div>
        </Popover.Panel>
      </>
    </Popover>
  );
};

export default DateRangeFilterPopover;
