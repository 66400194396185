import React from 'react';
import { Form, Formik } from 'formik';
import CustomInput from '../Inputs/CustomInput';
import CustomSelect from '../Inputs/CustomSelect';
import FieldDatePickerCustom from '../../DatePickerInput/FieldDatePickerCustom';
import { EXPENSE_TYPE } from '../../../utilities/constants';
import { DateTime } from 'luxon';

const ExpenseItemsForm = ({
  formValues,
  handleFormSubmit,
  validationSchema,
  actionButtons,
  categorySelection = false,
  month = DateTime.now().startOf('month')
}) => {
  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleFormSubmit}>
      {({ errors }) => (
        <Form className="flex flex-col justify-center w-full gap-6 pt-4">
          <div className="flex justify-center items-center gap-4 flex-col">
            <CustomInput name="amount" placeholder="Amount" icon="fa-dollar-sign" currency />
            <CustomInput name="description" placeholder="Description" icon="" />
            {categorySelection && (
              <CustomSelect
                name="category"
                placeholder="Category"
                options={EXPENSE_TYPE.filter(
                  (item) => item.value !== 'cushion' && item.value !== 'trustee_payment'
                )}
              />
            )}
            <FieldDatePickerCustom name="day" placeholder="Day" errors={errors} month={month} />
          </div>
          <div className="flex justify-center items-center gap-4">{actionButtons()}</div>
        </Form>
      )}
    </Formik>
  );
};

export default ExpenseItemsForm;
