import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Card, CardContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionComponent = ({ title, children, biller_id }) => {
  return (
    <Accordion id={`biller_${biller_id}`} className="biller">
      <AccordionSummary
        sx={{ backgroundColor: '#F5F7F9', color: '#000' }}
        expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}>
        {title}
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#F5F7F9' }}>
        <div className="flex justify-around">
          <Card variant="outlined" sx={{ width: '100%', boxShadow: '3px 3px 3px #CDCDC9' }}>
            {children}
          </Card>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
